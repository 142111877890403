<template>
	<div class="noPagewraper flex">
		<div class="noPagebox flex">
			<img src="https://zy.metaera.media/assets/images/404.png" />
			<p class="title">哎呀!被你找到ME宇宙的盡頭</p>
			<p class="content">你找的頁面不存在喔! 點擊下方，重新開始你的旅程吧!</p>
			<div class="returnHome pointer" @click="$router.push('/')">返回首頁</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "404",
	data() {
		return {};
	},
	methods: {},
};
</script>

<style scoped>
.noPagewraper {
	height: calc(100vh - 200px);
	align-items: center;
	justify-content: center;
}
.noPagebox {
	flex-direction: column;
	align-items: center;
}
.noPagebox img {
	display: block;
	width: clamp(400px, calc(100vw * 616 / 1440), 616px);
	height: clamp(100px, calc(100vw * 326 / 1440), 326px);
}
.noPagebox .title {
	margin-top: calc(100vw * 30 / 1440);
	font-size: clamp(24px, calc(100vw * 35 / 1440), 35px);
	line-height: 1;
	font-weight: 900;
	color: #fff;
}
.noPagebox .content {
	margin-top: calc(100vw * 20 / 1440);
	font-size: clamp(14px, calc(100vw * 28 / 1440), 28px);
	line-height: 1.4;
	color: #fff;
}
.noPagebox .returnHome {
	margin-top: calc(100vw * 60 / 1440);
	font-size: clamp(14px, calc(100vw * 24 / 1440), 24px);
	line-height: 1;
	font-weight: bold;
	color: #fff;
	padding: calc(100vw * 13 / 1440) calc(100vw * 40 / 1440);
	border-radius: calc(100vw * 60 / 1440);
	background: linear-gradient(
		81deg,
		#9945ff -5%,
		#8752f3 24%,
		#5497d5 51%,
		#43b4ca 65%,
		#28e0b9 81%,
		#19fb9b 115%
	);
}
</style>
