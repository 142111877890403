<template>
	<div>
		<div class="basicdata flex align_items_c direction_column">
			<div class="basicdata_2_item padding_bot_0 flex align_items_c">
				<div class="my_zl_right basicdata_1">
					<div>
						<img :src="userInfo.avatar_img" alt="" v-if="userInfo.avatar_img" />
						<div v-else>
							<img src="https://zy.metaera.media/user_header.png" alt="" />
						</div>
						<!-- 剪裁组件弹窗 -->
						<el-dialog
							title="裁切图片"
							:visible.sync="cropperModel"
							width="1300px"
							center
							:append-to-body="true"
						>
							<template v-if="cropperModel">
								<cropper-image-new
									:Name="cropperName"
									:type="scale"
									:showAvator="true"
									@uploadImgSuccess="handleUploadSuccess"
									ref="child"
								>
								</cropper-image-new
							></template>
						</el-dialog>
					</div>
					<div class="basicdata_1_item">
						<div
							class="changeHead changeHead_top flex align_items_c justify_content_c"
							@click="uploadPicture()"
						>
							上传头像
						</div>
						<div class="changeHead_tip flex align_items_c justify_content_c">
							圖片格式支持 GIF、PNG、JPEG，尺寸不小於 200 PX，小於 4 MB
						</div>
					</div>
				</div>
			</div>
			<div class="basicdata_2 accountInfo">
				<div class="basicdata_2_item padding_bot_0 align_items_c">
					<div class="my_zl_left">
						<span>昵称</span>
					</div>
					<div class="my_zl_right">
						<input
							type="text"
							class="modify-nickname-input"
							v-model="userInfo.nickname"
						/>
					</div>
				</div>
				<div class="basicdata_2_item flex align_items_c">
					<div class="my_zl_left">
						<span>UID </span>
						<span style="margin-left: 40px">{{ userInfo.id }}</span>
					</div>
					<div class="my_zl_right"></div>
				</div>
				<div class="basicdata_2_item flex align_items_c">
					<div class="my_zl_left">
						<span>性别</span>
					</div>
					<div class="my_zl_right"
						><span>
							<el-radio v-model="userInfo.sex" label="1">男</el-radio>
							<el-radio v-model="userInfo.sex" label="2">女</el-radio>
						</span></div
					>
				</div>
				<div
					class="basicdata_2_item margin_top_0 padding_bot_0 flex align_items_c"
				>
					<div class="my_zl_left">
						<span>个人简介</span>
					</div>
					<div class="my_zl_right">
						<input
							type="text"
							class="modify-nickname-input"
							v-model="userInfo.desc"
							placeholder="请介绍一下你自己"
						/>
					</div>
				</div>
				<div
					class="basicdata_2_item margin_top_0 padding_bot_0 flex align_items_c"
				>
					<div class="my_zl_left">
						<span>邮箱</span>
					</div>
					<div class="my_zl_right">
						<span>{{ userInfo.email ? userInfo.email : "暂未绑定邮箱" }}</span>
						<!-- <div class="changeHead changeHead_left">更换邮箱</div> -->
						<div class="changeHead changeHead_left" @click="editPassDis = true"
							>修改密码</div
						>
					</div>
				</div>
				<!-- 
				<div
					class="basicdata_2_item margin_top_0 padding_bot_0 flex align_items_c"
				>
					<div class="my_zl_left">
						<span>手机号</span>
					</div>
					<div class="my_zl_right">
						<span>{{
							userInfo.mobile ? userInfo.mobile : "暂未绑定手机"
						}}</span>
						<div
							class="changeHead changeHead_left"
							@click="
								editPhoneDis = true;
								showYz = '1';
							"
							>修改手机号</div
						>
					</div>
				</div> -->

				<div
					class="subBtn mainbackgroundcolor flex align_items_c justify_content_c pointer"
					@click="submit"
				>
					保存
				</div>
			</div>
		</div>

		<!-- 修改密码 -->
		<el-dialog :visible.sync="editPassDis">
			<div style="width: 420px">
				<forgetPass
					:version="'2'"
					:showType="'1'"
					@exit="editPassResult()"
				></forgetPass>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	getInfo,
	uploadImage,
	editUserInfo,
	verifySms,
	setPassword,
} from "@/api/user";

import forgetPass from "../../../components/login/forgetPass.vue";
import { sendSms } from "@/api/home";
import { getToken, getUserMobile } from "@/utils/auth";
import login__areaCode from "@/components/login/login__areaCode.vue";
import { newBus } from "@/components/pc/topHeader2.vue";

export default {
	name: "",
	components: { login__areaCode, forgetPass },
	data() {
		return {
			//裁切图片参数
			cropperModel: false,
			cropperName: "",
			errorShow: false,
			errorVal: "",
			active: 0,
			userInfo: {},
			editPhoneDis: false,
			editPassDis: false,
			// 验证码
			show: true,
			timer: null,
			count: "",
			code: "",
			new_password1: "",
			new_password2: "",
			showYz: "1",
			showtimezone: false,
			mobile: "",
			loginForm: {
				mobile: "",
				code: "",
				phone_code: "86",
			},
		};
	},
	created() {
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
		this.mobile = getUserMobile();
		this.getInfo();
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		editPassResult(type) {
			this.editPassDis = false;

			console.log(type);

			this.getInfo();
		},
		//封面设置
		uploadPicture(name) {
			this.cropperName = name;
			this.type = "1";
			this.cropperModel = true;
		},
		//图片上传成功后
		handleUploadSuccess(data) {
			console.log(data.url);
			this.cropperModel = false;
			this.userInfo.avatar_img = data.url;
		},

		getInfo() {
			getInfo({})
				.then((res) => {
					if (res.code == 200) {
						this.userInfo = res.data;
						this.userInfo.sex = this.userInfo.sex.toString();
					}
				})
				.catch(() => {});
		},
		submit() {
			editUserInfo({
				id: this.userInfo.id,
				nickname: this.userInfo.nickname,
				avatar_img: this.userInfo.avatar_img,
				sex: this.userInfo.sex,
				desc: this.userInfo.desc,
				official_account: this.userInfo.official_account,
			})
				.then((res) => {
					if (res.code == 200) {
						this.$message({
							message: "修改成功",
							type: "success",
							duration: 2 * 1000,
						});
						this.getInfo();
						location.reload();
					}
				})
				.catch(() => {});
		},
		tosendSms2() {
			sendSms({
				phone: this.loginForm.mobile,
				phone_code: this.loginForm.phone_code,
			})
				.then((res) => {
					if (res.code == 200) {
						this.show = false;
						const TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.show = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		tosendSms() {
			sendSms({
				phone: this.userInfo.mobile,
				phone_code: this.userInfo.phone_code ? this.userInfo.phone_code : "86",
			})
				.then((res) => {
					if (res.code == 200) {
						this.show = false;
						const TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.show = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		nextYz() {
			if (this.code == "") {
				alert("请输入验证码！");
			} else {
				verifySms({
					mobile: this.userInfo.mobile,
					code: this.code,
					phone_code: this.userInfo.phone_code
						? this.userInfo.phone_code
						: "86",
				})
					.then((res) => {
						if (res.code == 200) {
							this.showYz = "2";
						}
					})
					.catch((error) => {
						// this.$message.error(error.message);
					});
			}
		},
		getphone_code(val) {
			this.loginForm.phone_code = val;
		},
		confirm() {
			if (this.loginForm.mobile == "") {
				alert("请输入手机号！");
			} else {
				editUserInfo({
					phone: this.loginForm.mobile,
				})
					.then((res) => {
						if (res.code == 200) {
							this.$message({
								message: "修改成功",
								type: "success",
								duration: 2 * 1000,
							});
							this.editPhoneDis = false;
							this.getInfo();
						}
					})
					.catch(() => {});
			}
		},
		editPass() {
			let that = this;
			if (this.code == "") {
				this.errorVal = "验证码输入错误，请重新输入！";
				this.errorShow = true;
				var timer = setTimeout(function () {
					that.errorShow = false;
				}, 2000);
			} else if (this.new_password1 == "" || this.new_password2 == "") {
				this.errorVal = "请输入密码！";
				this.errorShow = true;
				var timer = setTimeout(function () {
					that.errorShow = false;
				}, 2000);
			} else if (this.new_password1 != this.new_password2) {
				this.errorVal = "两次密码输入不一致！";
				this.errorShow = true;
				var timer = setTimeout(function () {
					that.errorShow = false;
				}, 2000);
			} else {
				setPassword({
					mobile: this.userInfo.mobile,
					phone_code: this.userInfo.phone_code
						? this.userInfo.phone_code
						: "86",
					code: this.code,
					new_password1: this.new_password1,
					new_password2: this.new_password2,
				})
					.then((res) => {
						if (res.code == 200) {
							this.$message({
								message: "修改成功",
								type: "success",
								duration: 2 * 1000,
							});
							this.editPassDis = false;
							this.getInfo();
						}
					})
					.catch(() => {});
			}
		},
	},
};
</script>

<style scoped>
.indivdualtab div {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #888888;
	margin-top: 30px;
}

.active {
	color: #272525;
}

.indivdualtab div:nth-child(2) {
	margin-left: 36px;
}

.indivdualtab_line {
	width: 100%;
	height: 1px;
	background: #e1e1e1;
	margin-top: 9px;
}
.ztbox {
	background: #ffffff;
	padding-left: 25px;
	padding-right: 25px;
	margin-top: 20px;
	padding-top: 5px;
	border-radius: 20px;
	opacity: 1;

	background: #ffffff;

	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
}

.ztbox_1 {
	width: 100%;
	height: 40px;
	background: #ebf4fe;
	border-radius: 0px 0px 0px 0px;
	opacity: 1;
	font-size: 13px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.ztbox_2 {
	margin-top: 57px;
	border-radius: 20px;
	opacity: 1;
}

.ztbox_2_item {
	padding-bottom: 25px;
	margin-top: 27px;
}

.ztbox_2_item img {
	width: 20px;
	margin-right: 6px;
}

.ztbox_2_item span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.ztbox_2_item_cz {
	width: 118px;
	height: 40px;
	border-radius: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	background: #eff5fe;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;
	align-items: center;
	justify-content: center;
	text-align: center;
	display: flex;
	margin-left: 20px;
	/* 黑色主色（字段一级） */
	color: #333333;
}

/* 基本资料 */
.basicdata {
	background: rgba(0, 0, 0, 0);
	padding-left: 25px;
	padding-right: 25px;
	border-radius: 20px;
	margin-top: 60px;
	opacity: 1;
	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
}

.basicdata_1 {
	/* width: 715px; */
	opacity: 1;
	display: flex;
}

.basicdata_1 img {
	width: 200px;
	height: 200px;
	border: 1px solid #f4f4f5;
	border-radius: 20px;
	border-radius: 100px;
}

.basicdata_1_item {
	display: block;
	margin-left: 40px;
	align-items: center;
}
.changeHead {
	width: 120px;
	height: 40px;
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	border-radius: 10px;
	background: rgba(239, 245, 254, 0.1);
	text-align: center;
	align-content: center;
}

.changeHead_top {
	margin-top: 50px;
}
.changeHead_left {
	margin-left: 20px;
}
.basicdata_2 {
	margin-top: 26px;
	width: 100%;
}

.basicdata_2_item {
	width: 100%;
	display: block;
}
.my_zl_left {
	display: inline-block;
	width: 150px;
	margin-bottom: 16px;
}
.my_zl_right {
	margin-bottom: 30px;
	display: flex;
}
.my_zl_left span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.my_zl_right span {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #fff;
	align-content: center;
}
.my_zl_right input {
	background: rgba(255, 255, 255, 0.05);
	border-radius: 4px;
	padding-left: 10px;
	width: 100%;
	height: 50px;
	opacity: 1;
	border: 1px solid rgba(255, 255, 255, 0.05);
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #fff;
}

.nicknameedit div:nth-child(1) {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #767680;
}

.nicknameedit div:nth-child(2) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
	margin-left: 102px;
}

.basicdata_2_item_gzh {
	height: 280px;
}

.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #0056ff;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}

.avatar {
	width: 181px;
	height: 181px;
	display: block;
}

.avatar-uploader {
	width: 181px;
	height: 182px;
	background: #fafafa;
	border-radius: 0px 0px 0px 0px;
	opacity: 1;
	border: 1px solid #d1d1d1;
}

.ewmBox span {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #272525;
	margin-top: 14px;
}

.ewmBox {
	margin-left: 40%;
	transform: translateX(-50%);
}

.subBtn {
	width: 200px;
	height: 50px;

	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	/* border: 1px solid #0056ff; */
	/* margin: 86px auto; */
	margin-top: 80px;
	margin-bottom: 20px;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
}

@import url("../../../assets/person.css");

.timezone-list {
	top: 60px;
}

.nofloat {
	float: none;
}
</style>
<style>
input::-webkit-input-placeholder {
	/* WebKit browsers */
	color: #888888;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;

	font-size: 14px;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #888888;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;

	font-size: 14px;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #888888;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;

	font-size: 14px;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: #888888;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;

	font-size: 14px;
}

.basicdata_title {
	width: 100%;
	margin-top: 25px;
	display: flex;
}

.basicdata_title_img img {
	width: 16px;
	height: 16px;
	margin-right: 10px;
}
.basicdata_title_text {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.changeHead_tip {
	margin-top: 20px;

	/* width: 600px; */
	height: 40px;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	/* 正文色/正文辅助色 */
	color: #767676;
	justify-content: flex-start;
}
.el-radio__inner {
	border-radius: 4px;
}
.el-radio__label {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	text-align: justify; /* 浏览器可能不支持 */
	letter-spacing: 0px;
	/* 黑色主色（字段一级） */
	color: #fff;
}
.ztbox_2_item_title {
	width: 110px;
}
.ztbox_2_item_left {
	margin-right: 20px;
}
.el-dialog__body {
	background: rgba(0, 0, 0, 0);
	padding: 0;
}
.el-dialog {
	background: rgba(0, 0, 0, 0);
	justify-content: center;
	text-align: center;
	display: flex;
}
</style>
