div<template>
	<div class="eventWraper" v-if="searchLists.length > 0">
		<div class="rsTitle rsTitleBg flex align_items_c justify_content_sb">
			<div class="rsTitleLeft flex align_items_c justify_content_sb">
				<div>MetaEra 热搜</div>
				<!-- <div
					class="flex align_items_c pointer"
					@click="$router.push({ path: '/search', query: { keyword: '' } })"
				>
					<div class="rsTitleLeft_point"></div>
					<div class="rsTitleLeft_point"></div>
					<div class="rsTitleLeft_point"></div>
				</div> -->
			</div>
		</div>
		<div class="rsList resou_content">
			<template v-for="(item, index) in searchLists">
				<router-link
					class="rsItem_a"
					:key="index"
					target="_blank"
					:to="{ name: '/gw_detail', params: { id: item.id } }"
				>
					<div class="rsItem flex align_items_c pointer">
						<div
							v-if="!unshowImg"
							class="reItem_Right"
							:class="{ reItem_Right_hidden: !item.pic }"
						>
							<img class="rsItemImg" :src="item.pic" :key="item.pic" alt="" />
						</div>
						<div
							class="reItem_Left"
							:class="{
								reItem_Left_full: !(item.pic != null && item.pic.length > 0),
							}"
						>
							<div class="reItem_Left_title hovercolor">{{
								localLanguage == 2
									? item.title
										? item.title
										: item.title
									: item.title
							}}</div>
							<div class="flex align_items_c time_line_top">
								<div class="reItem_Left_line"></div>
								<div class="reItem_Left_time">{{ item.release_time }}</div>
							</div>
						</div>
					</div>
				</router-link>
			</template>
		</div>
	</div>
</template>

<script>
import { homeSearchList } from "@/api/home";
import { newBus } from "@/components/pc/topHeader2.vue";

export default {
	props: ["unshowImg"],
	name: "",
	data() {
		return {
			allData: [],
			searchLists: [],
			localLanguage: 1,
		};
	},
	created() {
		this.getDataList();
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		getDataList() {
			let res = JSON.parse(sessionStorage.getItem("homeSearchListData"));
			if (res != null && res.code == 200) {
				this.allData = res.data.searchLists;
				var tmpData = [];
				tmpData = tmpData.concat(this.allData);
				this.searchLists = tmpData;
			}
			homeSearchList({})
				.then((res) => {
					sessionStorage.setItem("homeSearchListData", JSON.stringify(res));
					if (res.code == 200) {
						this.allData = res.data.searchLists;
						var tmpData = [];
						tmpData = tmpData.concat(this.allData);
						this.searchLists = tmpData;
					} else {
					}
				})
				.catch((error) => {});
		},
	},
};
</script>

<style scoped>
.eventWraper .resou_content {
	padding: 0 !important;
}
.eventWraper .rsTitle {
	border: 0;
}
.rsList {
	background: transparent !important;
	margin-top: 0px;
}
.rsItem {
	padding-top: calc(100vw * 20 / 1440);
	padding-bottom: calc(100vw * 30 / 1440);
	border-bottom: 1px solid rgba(216, 216, 216, 0.2);
}
/* .reItem_Left {
	width: 148px;
} */
.reItem_Left_full {
	width: 100%;
}
.reItem_Left_title {
	/* width: 100%; */
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 18 / 1440), 18px);
	font-weight: bold !important;
	line-height: calc(100vw * 24 / 1440);
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #fff;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.reItem_Left_time {
	width: 175px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: calc(100vw * 12 / 1440);
	font-weight: 500;
	line-height: 1;
	letter-spacing: 0em;
	/* margin-top: calc(100vw * 7 / 1440); */
	color: #aaa;
}
.reItem_Right {
	flex-shrink: 0;
	margin-right: calc(100vw * 10 / 1440);
	width: calc(100vw * 160 / 1440);
	height: calc(100vw * 90 / 1440);
	border-radius: 8px;
	overflow: hidden;
}
.reItem_Right_hidden {
	display: none;
}
.rsItemImg {
	width: 100%;
	height: 100%;
	border-radius: 8px;
	object-fit: cover;
}
.eventWraper .reItem_Left_line {
	background: #fff;
	/* margin-top: calc(100vw * 7 / 1440); */
}
</style>
