<template>
	<div
		class="activities_list"
		:class="{ smallBox: styleType == 'small' }"
		v-if="mainList.length > 0"
	>
		<div style="margin-bottom: 40px"> </div>
		<div class="ac_mainside">
			<el-carousel :interval="0" arrow="always" indicator-position="none">
				<el-carousel-item v-for="(item, index) in mainList" :key="index">
					<div class="ac_mainside_item pointer" @click="tipShow(item)">
						<div class="ac_mainside_item_left">
							<div class="ac_mainside_item_tag">{{
								item.otherMainEvent == 1 ? "ME 周边活动合集" : "ME 独家活动合集"
							}}</div>
							<div
								class="ac_mainside_item_title wz_detail_pc_content_author_name2"
								>{{ item.title }}</div
							>
							<!-- <div class="ac_mainside_item_num">
										{{ item.count }} Side Events
									</div> -->
							<div class="ac_mainside_item_show pointer">
								<span>查看活动合集</span>
								<img
									src="https://zy.metaera.media/assets/images/home/arrow_white_left.png"
								/>
							</div>
						</div>
						<div class="ac_mainside_item_right">
							<img v-lazy="item.pic" :key="item.pic" />
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div
			style="
				height: 2px;
				background: rgba(216, 216, 216, 0.2);
				margin-top: 30px;
			"
		></div>
	</div>
</template>
<script>
export default {
	name: "",
	props: ["mainList", "styleType"],
	data() {
		return {};
	},
	methods: {
		tipShow(item) {
			this.item = item;
			// this.showTips = true;
			// 生成目标路由的 URL
			const routePath = this.$router.resolve({
				name: "/ac_detail",
				params: { id: this.item.id },
			}).href;
			// 在新标签页中打开目标路由
			window.open(routePath, "_blank");
		},
	},
};
</script>
<style scoped>
/* .ac_calendar_aclist_bg {
	margin: 0 calc(100vw * 1 / 12);
	margin-top: 35px;
} */
.ac_mainside .el-carousel__container {
	height: calc(100vw * (325 / 1440)) !important;
}
.ac_mainside_item {
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: space-between;
}

.ac_mainside_item_left {
	width: 50%;
	box-sizing: border-box;
	/* margin-left: clamp(0px, calc(100vw * 40 / 1440), 40px); */
	margin-left: 40px;
	padding-right: clamp(10px, calc(100vw * 30 / 1440), 30px);
}

.ac_mainside_item_tag {
	display: inline-flex;
	margin-top: clamp(6px, calc(100vw * 10 / 1280), 20px);
	padding: 12px 10px;
	border-radius: 20px;
	opacity: 1;
	/* 纯白 */
	background: #ffffff;
	font-size: clamp(14px, calc(100vw * 16 / 1280), 18px);
	font-weight: bold;
	line-height: 16px;
	text-align: center;
	align-items: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 一级文字 */
	color: #333333;
	text-align: center;
	align-items: center;
	align-content: center;
}

.ac_mainside_item_title {
	margin-top: clamp(6px, calc(100vw * 10 / 1280), 20px);
	font-size: clamp(20px, calc(100vw * 30 / 1440), 30px);
	font-weight: bold;
	line-height: clamp(30px, calc(100vw * 70 / 1440), 70px);
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_mainside_item_num {
	margin-top: clamp(12px, calc(100vw * 14 / 1280), 20px);
	font-size: clamp(12px, calc(100vw * 16 / 1280), 20px);
	font-weight: 500;
	line-height: clamp(14px, calc(100vw * 16 / 1280), 10px);
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_mainside_item_show {
	/* width: 170px; */
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 20px;
	opacity: 1;
	box-sizing: border-box;
	border: 1.5px solid #ffffff;
	display: inline-flex;
	align-items: center;
	justify-content: space-around;
	margin-top: clamp(12px, calc(100vw * 16 / 1280), 20px);
}

.ac_mainside_item_show span {
	margin-left: 10px;
	font-size: clamp(14px, calc(100vw * 16 / 1280), 18px);
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	display: flex;
	align-items: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
.ac_mainside_item_show img {
	width: 16px;
	height: 16px;
	object-fit: cover;
	margin-right: 10px;
}
.ac_mainside_item_right {
	width: 50%;
	box-sizing: border-box;
	height: 100%;
	aspect-ratio: 16 / 9;
	margin-right: 40px;
	margin-left: 0%;
}

.ac_mainside_item_right img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

.smallBox .ac_mainside_item_title {
	font-size: clamp(16px, calc(100vw * 26 / 1440), 26px);
	line-height: clamp(20px, calc(100vw * 30 / 1440), 30px);
	font-weight: 500;
}
</style>
<style>
.smallBox .ac_mainside .el-carousel__container {
	height: calc(100vw * (210 / 1440)) !important;
}
</style>
<style>
.activities_list .el-carousel__arrow--left {
	left: 0;
}
.activities_list .el-carousel__arrow--right {
	right: 0;
}
</style>
