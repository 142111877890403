<template>
	<div class="ac_calendar_aclist_item pointer">
		<div class="ac_calendar_aclist_item_content" @click="tipShow(item)">
			<div class="ac_calendar_aclist_item_img">
				<img v-lazy="item.pic" :key="item.pic" />
				<div class="ac_calendar_start_hour">{{
					item.mainevent ? item.mainevent : formatTime(item.start_time)
				}}</div>
				<div
					class="ac_calendar_line"
					:class="{
						ac_calendar_line_noStart: item.startStatus == 0,
					}"
					v-if="item.startStatus == 0"
				>
					活动尚未开始
				</div>
				<div
					class="ac_calendar_line"
					:class="{ ac_calendar_line_end: item.startStatus == 2 }"
					v-if="item.startStatus == 2"
				>
					活动已结束
				</div>
				<div
					class="ac_calendar_line"
					:class="{
						ac_calendar_line_progress: item.startStatus == 1,
					}"
					v-if="item.startStatus == 1"
				>
					活动正在进行中
				</div>
			</div>
			<div class="ac_tag">{{ item.mainevent }}</div>
			<div class="ac_calendar_aclist_item_title">{{ item.title }}</div>
			<div class="ac_calendar_aclist_item_info">
				<div class="ac_calendar_aclist_item_area">
					<img src="https://zy.metaera.media/assets/images/active/6.png" />
					<span>{{ item.address ? item.address : "暂无地址" }}</span>
				</div>

				<div class="ac_calendar_aclist_item_date flex">
					<img src="https://zy.metaera.media/assets/images/active/7.png" />
					<div>
						<p>{{ formatDate(item.start_time) }} {{ item.timeZone }}</p>
						<p>{{ formatDate(item.start_time) }} {{ item.timeZone }}</p>
					</div>
				</div>
			</div>
			<div
				class="ac_calendar_aclist_item_people"
				v-if="item.sideevent == null || item.sideevent == ''"
			>
				<div class="ac_calendar_aclist_item_organizer">
					<span>ORGANIZER：</span>
					<img :src="getHostArray(item).pic" />
					<span>{{ getHostArray(item).name }}</span>
				</div>
			</div>

			<div class="ac_calendar_aclist_item_side" v-else>
				<div class="ac_calendar_aclist_item_side_left">
					# {{ item.sideevent_title }}</div
				>
				<div class="ac_calendar_aclist_item_side_right">SideEvent</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["item"],
	name: "",
	data() {
		return {};
	},
	methods: {
		tipShow(item) {
			// this.showTips = true;
			// 生成目标路由的 URL
			const routePath = this.$router.resolve({
				name: "/ac_detail",
				params: { id: this.item.id },
			}).href;
			// 在新标签页中打开目标路由
			window.open(routePath, "_blank");
		},
		formatTime(timestamp) {
			const date = new Date(timestamp);
			let hours = date.getHours();
			const minutes = date.getMinutes();
			const ampm = hours >= 12 ? "PM" : "AM";
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			const strMinutes = minutes < 10 ? "0" + minutes : minutes;
			return hours + ":" + strMinutes + " " + ampm;
		},
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString("zh-CN", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				hour12: false,
			});
		},
		getHostArray(item) {
			if (item.organizer && item.organizer.length > 10) {
				return JSON.parse(item.organizer)[0];
			} else {
				return { pic: "", name: "" };
			}
		},
	},
	created() {},
	mounted() {},
};
</script>

<style scoped>
.ac_calendar_aclist_item {
	padding-bottom: 30px;
	margin-top: 40px;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
	/* border-bottom: 1px solid rgba(216, 216, 216, 0.2); */
}

.ac_calendar_aclist_item_content {
	position: relative;
	z-index: 1;
}
.ac_calendar_aclist_item_img {
	position: relative;

	border-radius: 10px;
	/* padding: 12px; */
}

.ac_calendar_aclist_item_img img {
	width: 100%;
	border-radius: 10px;
	aspect-ratio: 16 / 9;
	object-fit: cover;
}
.ac_calendar_start_hour {
	top: 10px; /* 距离顶部的距离 */
	left: 10px; /* 距离左边的距离 */
	position: absolute;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
	color: white; /* 字体颜色 */
	border-radius: 7px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	display: flex;
	align-items: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	display: none;
}
.ac_calendar_line {
	position: absolute;
	bottom: 0; /* 距离顶部的距离 */
	left: 0;
	right: 0; /* 距离右边的距离 */
	text-align: center;
	padding: 6px 0;
	background: linear-gradient(
		85deg,
		#9945ff 0%,
		#8752f3 24%,
		#5497d5 46%,
		#43b4ca 56%,
		#28e0b9 69%,
		#19fb9b 96%
	);
	border-radius: 3px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_line_noStart {
	background: #333; /* 半透明背景 */
}

.ac_calendar_line_end {
	background: linear-gradient(85deg, #431e70 0%, #0b6e44 96%);
}

.ac_calendar_line_noStart div {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin-right: 10px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}

.ac_calendar_line_progress {
	color: #fff;
}
.ac_calendar_line_progress div {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin-right: 10px;
	background: #08bf66;
}
.ac_calendar_aclist_item_title {
	margin-top: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(14px, calc(100vw * 16 / 1280), 18px);
	font-weight: bold;
	line-height: 36px;
	height: 72px;
	/* 纯白 */
	color: #ffffff;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	margin-right: 10px;
}

.ac_calendar_aclist_item_title_1 {
	-webkit-line-clamp: 1;
}
.ac_calendar_aclist_item_info {
	margin-top: 6px;
	display: block;
	justify-content: space-between;
}

.ac_tag {
	margin-top: 20px;
	color: #fff;
	font-size: 14px;
	line-height: 14px;
}

.ac_calendar_aclist_item_area,
.ac_calendar_aclist_item_date {
	margin-top: 10px;
}
.ac_calendar_aclist_item_date p {
	font-size: 12px;
	line-height: 16px;
	color: #fff;
	margin-bottom: 5px;
}

.ac_calendar_aclist_item_side {
	margin-top: 15px;
	display: inline-flex;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 20px;
	opacity: 1;
	box-sizing: border-box;
	border: 1px solid #ffffff;
}

.ac_calendar_aclist_item_side_left,
.ac_calendar_aclist_item_side_right {
	font-size: clamp(10px, calc(100vw * 12 / 1280), 14px);
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff !important;
}

.ac_calendar_aclist_item_side_left {
	/* width: 60%; */
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	margin-right: 10px;
}
.ac_calendar_aclist_item_info span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 12 / 1280), 14px);
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.ac_calendar_aclist_item_info img {
	width: auto;
	height: 15px;
	margin-right: 10px;
}
.ac_calendar_aclist_item_people {
	margin-top: 8px;
	display: inline-flex;
	justify-content: space-between;

	padding-top: 4px;
	padding-bottom: 8px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 20px;
	opacity: 1;
	box-sizing: border-box;
	background: rgba(216, 216, 216, 0.1);
}
.ac_calendar_aclist_item_people span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 10px;
	font-weight: normal;
	line-height: 10px;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.ac_calendar_aclist_item_people img {
	margin-left: 10px;
	width: 18px;
	height: 18px;
	margin-right: -10px;
	border-radius: 9px;
	object-fit: cover;
}
.ac_calendar_aclist_item_organizer img {
	margin-right: 4px;
}
</style>
