<template>
	<div class="new_wzitem_sub">
		<router-link
			target="_blank"
			:to="{ name: '/gw_detail', params: { id: item.id } }"
			style="display: flex"
		>
			<img
				class="new_wzitem_sub_img pointer"
				v-lazy="item.pic"
				:key="item.pic"
				alt=""
			/>
			<div
				class="new_wzitem_sub_Left"
				:class="{ reItem_Left_full: item.pic != null && item.pic.length > 0 }"
			>
				<div class="new_wzitem_sub_Left_title hovercolor pointer">{{
					localLanguage == 2
						? item.title
							? item.title
							: item.title
						: item.title
				}}</div>
				<div class="new_wzitem_sub_Left_bottom">
					<div class="new_wzitem_sub_Left_time">{{ item.release_time }}</div>
				</div>
			</div>
		</router-link>
	</div>
</template>

<script>
import { newBus } from "@/components/pc/topHeader2.vue";

export default {
	props: ["item"],
	data() {
		return {
			allData: [],
			localLanguage: 1,
		};
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			// 处理收到的通知，并更新组件状态
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {},
};
</script>

<style scoped>
.new_wzitem_sub {
	border-top: 1px solid rgba(216, 216, 216, 0.2);
	padding: clac(100vw * (3/144)) 0;
	padding: 30px 0;
	display: flex;
	/* min-height: 140px; */
}
.new_wzitem_sub_Left {
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.reItem_Left_full {
	width: 100%;
}

.new_wzitem_sub_Left_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	font-size: clamp(13px, clac(100vw * (16/1440)), 16px);
	line-height: 1.4;
	/* 黑色主色（字段一级） */
	color: #fff;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.new_wzitem_sub_Left_content {
	margin-left: clamp(10px, calc(100vw * 10 / 1280), 20px);
	margin-top: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0em;

	/* 正文色/正文辅助色 */
	color: #767676;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.new_wzitem_sub_Left_time,
.new_wzitem_sub_Left_author {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	color: #b2b2b2;
	padding-top: 10px;
}
.new_wzitem_sub_Right {
	display: none;
}
.new_wzitem_sub_img {
	margin-right: clamp(10px, calc(100vw * 10 / 1280), 20px);
	width: clamp(140px, calc(100vw * 160 / 1440), 160px);
	/* height: 180px; */
	height: clamp(80px, calc(100vw * 90 / 1440), 90px);
	border-radius: 6px;
	object-fit: cover;
}

.new_wzitem_sub_Left_line {
	margin-left: clamp(10px, calc(100vw * 10 / 1280), 20px);
	margin-right: 7px;
	bottom: 0px;
	width: 2px;
	height: 12px;
	border-radius: 24px;
	opacity: 1;
	background: #b6b9cc;
}
</style>
