<template>
	<div class="search-pc padding_120">
		<div class="flex align_items_c justify_content_sb">
			<div class="searchBox flex align_items_c">
				<img
					src="https://zy.metaera.media/assets/images/tab/h_search.png"
					alt=""
					@click="search"
					class="pointer"
				/>
				<input
					type="text"
					placeholder="请输入关键词"
					v-model="keywords"
					@keydown.enter="search"
				/>
			</div>
			<img
				@click="
					$router.push({
						path: '/home',
					})
				"
				class="closeBtn"
				src="https://zy.metaera.media/assets/images/search/close_btn.png"
			/>
		</div>
		<div class="historySearch">
			<div class="historySearch_l flex justify_content_sb align_items_c">
				<span>历史搜索</span>
				<span class="pointer" @click="delHistory('', 2, -1)">清除历史</span>
			</div>
			<div class="historySearch_2 flex flex_wrap">
				<div v-for="(item, index) in searchRecords" :key="index">
					<div
						class="historySearch_2_item flex align_items_c pointer"
						v-if="index < 5"
					>
						<span
							@click="
								$router.push({ name: '/gw_search', params: { key: item.name } })
							"
							>{{ item.name }}</span
						>
						<img
							src="https://zy.metaera.media/assets/images/search/1327.png"
							alt=""
							class="pointer"
							@click="delHistory(item.id, 1, index)"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="search-bottom-box flex justify_content_sb">
			<div class="search-left MainRight_400">
				<div class="rsTitle">热门搜索</div>
				<div class="flex align_items_c justify_content_sb">
					<div class="rsList_search subList">
						<div
							class="rsItem flex align_items_c hovercolor pointer"
							v-for="(item, index) in hotAllSearchList"
							:key="index"
						>
							<router-link
								target="_blank"
								:to="{ name: '/kuaixun_wz', params: { id: item.id } }"
							>
								<div class="ellipsis_multiple">{{
									localLanguage == 2
										? item.title
											? item.title
											: item.title
										: item.title
								}}</div>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="search-right MainLeft_760">
				<div class="rsTitle">热门文章</div>
				<div class="" v-for="(items, indexs) in recommendLists" :key="indexs">
					<wzitem :item="items"></wzitem>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	hotSearch,
	getSearchRecords,
	delSearchRecords,
	getRecommendLists,
} from "@/api/home";
import { getToken } from "@/utils/auth";
import { newBus } from "../../components/pc/topHeader2.vue";
import wzitem from "../../components/pc/wzitem.vue";
export default {
	name: "",
	components: {
		wzitem,
	},
	data() {
		return {
			keywords: "",
			hotAllSearchList: [],
			hotSearchList: [],
			hotSearchList2: [],
			searchRecords: [],
			localLanguage: 1,
			recommendLists: [],
		};
	},
	created() {
		this.keywords = this.$route.params.keyword;
		console.log("keyword", this.keywords);

		this.hotSearch();
		this.getSearchRecords();
		if (this.keywords != null && this.keywords.length > 0) {
			this.search();
		}
	},
	mounted() {
		this.getRecommendLists();
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		getRecommendLists() {
			getRecommendLists({ page: 1, size: 10 })
				.then((res) => {
					if (res.code == 200) {
						this.recommendLists = res.data.list;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		change() {
			// console.log(this.keywords);
		},
		hotSearch() {
			hotSearch({})
				.then((res) => {
					if (res.code == 200) {
						this.hotAllSearchList = res.data;
						this.hotSearchList = res.data.slice(0, 5);
						this.hotSearchList2 = res.data.slice(-5);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getSearchRecords() {
			const hasToken = getToken();
			if (hasToken) {
				getSearchRecords({})
					.then((res) => {
						if (res.code == 200) {
							this.searchRecords = [];
							for (let index = 0; index < res.data.length; index++) {
								const element = res.data[index];
								var has = false;
								for (let tag = 0; tag < this.searchRecords.length; tag++) {
									const tagItem = this.searchRecords[tag];
									if (element.name == tagItem.name) {
										has = true;
										break;
									}
								}
								if (!has) {
									this.searchRecords.push(element);
								}
							}
						}
					})
					.catch((error) => {
						// this.$message.error(error.message);
					});
			} else {
				let _obj = JSON.parse(sessionStorage.getItem("searchRecords"));
				this.searchRecords = [];
				if (_obj) {
					for (let index = 0; index < _obj.length; index++) {
						const element = _obj[index];
						if (_obj[index] != null) {
							var has = false;
							for (let tag = 0; tag < this.searchRecords.length; tag++) {
								const tagItem = this.searchRecords[tag];
								if (element.name == tagItem.name) {
									has = true;
									break;
								}
							}
							if (!has) {
								this.searchRecords.push(element);
							}
						}
					}
				}
			}
		},
		delHistory(id, type, index) {
			const hasToken = getToken();
			if (hasToken) {
				delSearchRecords({ id: id, type: type })
					.then((res) => {
						if (res.code == 200) {
							this.getSearchRecords();
						}
					})
					.catch((error) => {
						// this.$message.error(error.message);
					});
			} else {
				let _obj = this.searchRecords;
				if (type == 2) {
					_obj = [];
				} else {
					if (_obj != null) {
						this.$set(_obj, index, 1);
					}
				}
				this.searchRecords = _obj;
				sessionStorage.setItem(
					"searchRecords",
					JSON.stringify(this.searchRecords)
				);
				this.getSearchRecords();
			}
		},
		search() {
			this.$router.push({ name: "/gw_search", params: { key: this.keywords } });
		},
	},
};
</script>
<style scoped>
.search-pc {
	height: 100vh;
	padding-top: 30px;
}
.closeBtn {
	width: 24px;
	height: 24px;
	margin-left: 40px;
}
.searchBox {
	width: 100%;
	height: 50px;
	border-radius: 17px;
	background: rgba(255, 255, 255, 0.05);
	box-shadow: 4.68px 15.3px 68px 0px rgba(54, 62, 147, 0.0902);
}

.searchBox img {
	width: 22px;
	height: 22px;
	margin-left: 20px;
	margin-right: 10px;
}
.searchBox input {
	width: 80%;
	height: 90%;
	border: none;
	outline: none;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: #fff !important;
	background: transparent;
}

.search-bottom-box {
	margin-top: 35px;
}
.search-left {
	/* width: calc(100vw * 310 / 1200); */
}
.search-right {
	/* width: calc(100vw * 680 / 1200); */
}
.search-bottom-box .rsTitle {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #fff;
	font-size: clamp(12px, calc(100vw * 20 / 1200), 20px);
	font-weight: 600;
	line-height: 1;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.historySearch {
	margin-top: calc(100vw * 40 / 1200);
}
.historySearch_l span:nth-child(1) {
	font-size: clamp(12px, calc(100vw * 20 / 1200), 20px);
	line-height: 1;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #fff;
}
.historySearch_l span:nth-child(2) {
	font-size: clamp(12px, calc(100vw * 16 / 1200), 16px);
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #a1a1aa;
}
.historySearch_2 {
	margin-top: calc(100vw * 24 / 1200);
}
.historySearch_2_item {
	padding: 9px 15px;
	margin-right: calc(100vw * 30 / 1200);
	margin-bottom: calc(100vw * 15 / 1200);
	background: rgba(255, 255, 255, 0.1);
	border-radius: 10px;
}
.historySearch_2_item span {
	font-size: clamp(8px, calc(100vw * 12 / 1200), 12px);
	line-height: 1;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #fff;
}
.historySearch_2_item img {
	width: 14px;
	height: 14px;
	margin-left: 8px;
}
.allList {
	display: none;
}
.subList {
	display: block;
}
.rsList_search {
	margin-top: 20px;
}
.ellipsis_multiple {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(12px, calc(100vw * 18 / 1200), 18px);
	font-weight: bold;
	line-height: calc(100vw * 24 / 1200);
	color: #fff;
}

.ellipsis_one_line {
	width: 2px;
	height: 10px;
	border-radius: 153px;
	opacity: 1;
	/* 主色（一级颜色） */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	margin-right: 10px;
}
</style>
