<template>
	<div class="width_1200 margin_auto">
		<div class="sj_1"> </div>
		<div
			class="Main width_1200 margin_auto flex justify_content_sb"
			style="padding-bottom: 20px"
		>
			<div class="MainLeft margin_left_20_media">
				<div class="MainLeft_1_title"> 大事件 </div>
				<div class="steps sjsteps">
					<div v-for="(items, indexs) in newEventDetails" :key="indexs">
						<el-steps direction="vertical" :active="1">
							<el-step v-for="(item, index) in items.subList" :key="index">
								<template slot="description">
									<div class="stepsTime" v-if="index == 0">{{
										items.release_time2
									}}</div>
									<div>
										<div class="stepsTimeDet">{{
											(item.release_time + "").split(/[ ]+/)[1]
										}}</div>
										<router-link
											target="_blank"
											:to="{ name: '/kuaixun_wz', params: { id: item.id } }"
										>
											<div class="stepsVal ellipsis_one pointer hovercolor"
												>{{ item.title }}
											</div>
										</router-link>
										<img
											src="https://zy.metaera.media/assets/images/event/163.png"
											alt=""
										/>
									</div>
								</template>
							</el-step>
						</el-steps>
					</div>
				</div>
			</div>
			<div class="MainRight">
				<event type="recommend" title="主编推荐"></event>

				<div class="heatwz">
					<div class="heatwzTitle"> 24小时热文 </div>
					<othresou></othresou>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { myMixin } from "@/utils/mixin.js";
import { getEventDetails } from "@/api/home";
export default {
	mixins: [myMixin],
	name: "",
	data() {
		return {
			EventDetails: [],
			newEventDetails: [],
		};
	},
	created() {
		this.getEventDetails();
	},
	methods: {
		getEventDetails() {
			getEventDetails({})
				.then((res) => {
					if (res.code == 200) {
						this.EventDetails = res.data.list;
						this.newEventDetails = [];
						this.newEventDetails = this.getNewData(
							this.EventDetails,
							this.newEventDetails
						);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.sj_1 {
	width: 100%;
	height: 140px;
	background-image: url("https://zy.metaera.media/assets/images/event/1036.png");
	background-size: 100% 100%;
	margin-top: 30px;
}

.MainLeft {
	width: 600px;
}

.MainLeft_1_title {
	margin-top: 34px;
	position: relative;
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.MainLeft_1_title::after {
	position: absolute;
	content: "";
	width: 54px;
	height: 3px;
	background: #294afd;
	border-radius: 0px 0px 0px 0px;
	opacity: 1;
	left: 0;
	bottom: -10px;
}

.sjsteps {
	margin-top: 50px;
}

.stepsTimeDet {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #b5b5b5;
	margin-top: 5px;
}

.sjsteps img {
	width: 47px;
	height: 17px;
	margin-top: 18px;
	padding-bottom: 20px;
}

.MainRight {
	width: 370px;
	margin-top: 34px;
}

.MainRightTitle {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.MainRight_1 {
	width: 100%;
	height: 120px;
	background: rgba(255, 255, 255, 0);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #dcdcdc;
	margin-top: 21px;
}

.MainRight_1_item {
	padding: 0 12px;
	line-height: 32px;
	height: 32px;
	background: rgba(255, 255, 255, 0);
	border-radius: 2px 2px 2px 2px;
	opacity: 1;
	border: 1px solid #f0f0f0;
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #767680;
	margin-left: 19px;
}

.MainRight_1_item_btn {
	width: 14px;
	height: 14px;
	margin-left: 3px;
}

.heatwz {
	margin-top: 30px;
}

.heatwzTitle {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.dexBoxm {
	margin-top: 20px;
}

.wzitemLTime {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #999999;
}

.wzitem {
	height: 105px;
}

.wzitemL {
	height: 100%;
}

.wzitem img {
	width: 170px;
	height: 105px;
}
</style>
<style>
.sjsteps .stepsVal {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-top: 3px;
}

.sjsteps .el-step__main {
	height: auto !important;
}

.sjsteps .el-step__description {
	padding-right: 0% !important;
}
</style>
