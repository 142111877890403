import axios from "axios";
import qs from "qs";
import { MessageBox, Message, Loading } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";

let loading;
// loading开始 方法
function startLoading() {
	// element-ui loading 服务调用方式
	// loading = Loading.service({
	// 	lock: true,
	// 	text: "拼命加载中...",
	// 	spinner: "el-icon-loading", // 自定义图标
	// 	background: "rgba(0, 0, 0, 0.3)",
	// });
}

// loading结束 方法
function endLoading() {
	// loading.close();
}

// create an axios instance
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 100000, // request timeout
});

// request interceptor
service.interceptors.request.use(
	(config) => {
		// do something before request is sent

		if (store.getters.token || getToken()) {
			config.headers["Authorization"] = getToken();
			config.headers["language"] = "cn";
			config.headers["os"] = "ios";
		}
		startLoading();
		// 备注，*****
		//   if (config.method == "post" && config.dataType != 'json') {
		//     config.data = qs.stringify(config.data);
		// }
		return config;
	},
	(error) => {
		// do something with request error
		console.log(error); // for debug

		endLoading();
		return Promise.reject(error);
	}
);

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	(response) => {
		endLoading();
		const res = response.data;

		// if the custom code is not 20000, it is judged as an error.
		if (res.code !== 200) {
			// 是导出文件放行

			if (res.code == undefined) {
				return res;
			}

			if (
				res.code == 1005 ||
				res.code == 10300 ||
				res.code == 10100 ||
				res.code == 10200
			) {
				//没有数据了
				return res;
			}

			// token失效刷新页面
			if (res.code == 10500) {
				location.reload();
				return Promise.reject(res);
			}

			if (res.code == 5006) {
				// Message({
				// 	message: res.msg,
				// 	type: "error",
				// 	duration: 2 * 1000,
				// });
				return res;
			}
			// Message({
			// 	message: res.message,
			// 	type: "error",
			// 	duration: 2 * 1000,
			// });
		}

		return res;
	},
	(error) => {
		endLoading();
		console.log("err" + error); // for debug

		// Message({
		// 	message: error.message,
		// 	type: "error",
		// 	duration: 5 * 1000,
		// });
		return Promise.reject(error);
	}
);

export default service;
