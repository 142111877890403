<template>
	<div>
		<div class="dexBoxm">
			<div
				class="wzitem flex justify_content_sb align_items_c pointer"
				v-for="(item, index) in hotLists"
				:key="index"
			>
				<router-link
					target="_blank"
					:to="{ name: '/gw_detail', params: { id: item.id } }"
					style="display: flex"
				>
					<div class="wzitem2Img">
						<img :src="item.pic" :key="item.pic" alt="" />
					</div>
					<div class="wzitemL">
						<div class="wzitemLTitle">
							<div
								style="color: #000000; -webkit-line-clamp: 2"
								class="ellipsis_multiple hovercolor"
							>
								{{
									localLanguage == 2
										? item.title
											? item.title
											: item.title
										: item.title
								}}</div
							>
						</div>
						<div class="wzitemLTime flex align_items_c justify_content_sb">
							<div class="wzitemLTime_l">{{ item.author.nickname }}</div>
						</div>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { homeHotList } from "@/api/home";
import { newBus } from "@/components/pc/topHeader2.vue";

export default {
	name: "",
	data() {
		return {
			hotLists: [],
			localLanguage: 1,
		};
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	created() {
		this.getDataList();
	},
	methods: {
		getDataList() {
			let res = JSON.parse(sessionStorage.getItem("homeHotListData"));
			if (res != null && res.code == 200) {
				this.hotLists = res.data.hotLists;
			}
			homeHotList({})
				.then((res) => {
					sessionStorage.setItem("homeHotListData", JSON.stringify(res));
					if (res.code == 200) {
						this.hotLists = res.data.hotLists;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.MainRight_6 {
	margin-top: 19px;
}

.MainRight_6_title {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.dexBoxm {
	padding: 20px 22px;
}

.wzitemLTime {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #999999;
}

.wzitem {
	height: 105px;
}

.wzitemL {
	width: 100%;
	margin-left: 10;
}

.wzitem2Img {
	width: 149px;
	height: 92px;
	/* overflow: hidden; */
	border-radius: 4px;
}

.wzitem2Img img {
	display: block;
	width: 149px;
	height: 100%;
	border-radius: 4px;
	object-fit: cover;
}
</style>
