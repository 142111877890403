<template>
	<div>
		<!-- footer -->
		<div class="footer">
			<div class="mz">
				<div class="width_1200 margin_auto flex align_items_c">
					免责声明：Meta
					Era所有资讯仅代表作者个人观点，不构成任何投资理财建议。</div
				>
			</div>
			<div class="width_1200 margin_auto">
				<div class="footer_top flex justify_content_sb">
					<!-- <div class="footer_top_l">
						<div class="urltitle">友情链接</div>
						<div class="flex footer_top_url justify_content_sb">
							<img src="https://zy.metaera.media/assets/images/6.png" alt="" />
							<img src="https://zy.metaera.media/assets/images/7.png" alt="" />
							<img src="https://zy.metaera.media/assets/images/8.png" alt="" />
							<img src="https://zy.metaera.media/assets/images/9.png" alt="" />
							<img src="https://zy.metaera.media/assets/images/10.png" alt="" />
							<img src="https://zy.metaera.media/assets/images/11.png" alt="" />
							<img src="https://zy.metaera.media/assets/images/12.png" alt="" />
							<img src="https://zy.metaera.media/assets/images/13.png" alt="" />
							<img src="https://zy.metaera.media/assets/images/14.png" alt="" />
							<img src="https://zy.metaera.media/assets/images/15.png" alt="" />
							<img src="https://zy.metaera.media/assets/images/16.png" alt="" />
						</div>
						<div class="rss">RSS</div>
						<div class="flex catelist">
							<div>快讯</div>
							<div>文章</div>
						</div>
					</div> -->
					<div class="footer_top_r">
						<div>MetaEra APP下载</div>
						<img
							src="https://zy.metaera.media/assets/images/aboutus/download.png"
							alt=""
						/>
						<div class="ios">iOS&Android</div>
					</div>
				</div>
				<div class="footer_bot flex align_items_c justify_content_sb">
					<div>Copyright © 2022 MetaEra. All Rights Reserved</div>
					<div class="footer_bot_list flex align_items_c pointer">
						<div @click="$router.push({ path: '/aboutus' })">关于MetaEra</div>
						<div @click="$router.push({ path: '/aboutus' })">联系我们</div>
						<div @click="$router.push({ path: '/copyright' })">版权声明</div>
						<div @click="open(config.privacy_policy.value)">隐私协议</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	data() {
		return {
			config: {},
		};
	},
	created() {},
	methods: {},
};
</script>

<style scoped>
/* footer */

.mz {
	width: 100%;
	height: 15px;
	background: #1f1f22;
	border-radius: 0px 0px 0px 0px;
	opacity: 1;
}

.mz div {
	height: 100%;
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #9e9fa7;
}
.footer {
	width: 100%;
	height: 290px;
	background: #1f1f22;
	border-radius: 0px 0px 0px 0px;
	opacity: 1;
}

.footer_top {
	height: 204px;
	border-bottom: 1px solid #393942;
}

.footer_bot {
	height: 70px;
	width: 100%;
}

.footer_bot div:nth-child(1) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #b0b0bb;
}

.footer_bot_list div:nth-child(1) {
	margin-left: 0;
}

.footer_bot_list div {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #b0b0bb;
	margin-left: 15px;
}

.urltitle {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	margin-top: 33px;
}

.footer_top_url {
	margin-top: 19px;
	width: 960px;
}

.footer_top_url img {
	width: 60px;
}

.footer_top_r {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.footer_top_r div:nth-child(1) {
	font-size: 13px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
}

.footer_top_r img {
	width: 90px;
	height: 90px;
	margin-top: 13px;
}

.ios {
	font-size: 13px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #ffffff;
	margin-top: 10px;
}

.rss {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	margin-top: 15px;
}

.catelist {
	margin-top: 21px;
}

.catelist div {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #b0b0bb;
}

.catelist div:nth-child(2) {
	margin-left: 12px;
}
@media screen and (max-width: 900px) {
	.mz {
		width: 100%;
		height: 40px;
		background: #1f1f22;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
	}
	.mz div {
		height: 100%;
		font-size: 10px;
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 400;
		color: #9e9fa7;
	}
	.footer_top {
		display: none;
	}
	.footer {
		height: 100px;
	}
	.footer_bot {
		height: 60px;
		width: 100%;
	}
	.footer_bot div:nth-child(1) {
		font-size: 14px;
		margin-left: 10px;
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 400;
		color: #b0b0bb;
	}
	.footer_bot_list {
		margin-right: 10px;
	}
}
</style>
