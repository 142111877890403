<template>
	<div class="tageBox tageBoxEtf" id="tageBoxRef">
		<el-tabs v-model="activeName">
			<el-tab-pane
				:label="
					localLanguage == 2
						? item.name_en
							? item.name_en
							: item.name
						: item.name
				"
				:name="item.id + ''"
				v-for="(item, index) in categoryLists"
				:key="index + 'ca'"
			>
				<!--   Spot     -->
				<div
					class="info-box flex justify_content_sb flex_wrap"
					v-if="activeName != 'Plate' && activeName != 'ETF' && spot.length > 0"
				>
					<div class="flex data-info" v-for="item in spot" :key="item.instId">
						<p class="typeName">
							<img
								src="https://zy.metaera.media/assets/images/home/蒙版组 51@2x.png"
								v-if="item.instId.includes('SOL')"
							/>
							<img
								src="https://zy.metaera.media/assets/images/home/ETH (3)@2x.png"
								v-else-if="item.instId.includes('ETH')"
							/>
							<img
								src="https://zy.metaera.media/assets/images/home/20250121-192523.png"
								v-else-if="item.instId.includes('TRUMP')"
							/>
							<img
								src="https://zy.metaera.media/assets/images/home/20250121-202936.png"
								v-else-if="item.instId.includes('DOGE')"
							/>
							<img
								src="https://zy.metaera.media/assets/images/home/XRP (XRP)@2x.png"
								v-else-if="item.instId.includes('XRP')"
							/>
							<img
								src="https://zy.metaera.media/assets/images/home/BTC.svg"
								v-else
							/>
							<span>{{ item.instId.replace("-USDT", "") }}</span>
						</p>
						<p class="typePrice">${{ formatNumber(item.last.toString()) }}</p>
						<!-- <p class="typeChange">+3.60%</p> -->
					</div>
				</div>
				<!--    ETF     -->
				<div class="etf-info-box" v-if="activeName == 'ETF'">
					<div class="etf-price-type flex">
						<div class="etf-price-name zhan-wei" style="height: auto"
							>Curr.</div
						>
						<div class="etf-price-name zhan-wei">Reg.</div>
						<div class="etf-price-name">Daily Net Inflow</div>
						<div class="etf-price-name">Cum. Net Inflow</div>
						<div class="etf-price-name">Value Traded</div>
						<div class="etf-price-name">Net Assets</div>
					</div>
					<div class="etf-data-box flex">
						<div class="etf-left-box">
							<p class="typeName etf-info-name pointer" @click="chageType(1)">
								<img
									src="https://zy.metaera.media/assets/images/home/BTC.svg"
								/>
								<span>BTC</span>
							</p>
							<p class="typeName etf-info-name pointer" @click="chageType(2)">
								<img
									src="https://zy.metaera.media/assets/images/home/3287b3-ethereum-eth.svg"
								/>
								<span>ETH</span>
							</p>
						</div>
						<div class="etf-right-box left-bottom">
							<div class="area-price-info flex" v-if="etf_btc.dailyNetInflow">
								<div class="etf-area">United States</div>
								<div class="etf-price-num"
									>${{
										formatNumber(
											(etf_btc.dailyNetInflow.value / 1000000).toFixed(2)
										)
									}}
									M</div
								>
								<div class="etf-price-num"
									>${{
										formatNumber(
											(etf_btc.cumNetInflow.value / 1000000).toFixed(2)
										)
									}}
									M</div
								>
								<div class="etf-price-num"
									>${{
										formatNumber(
											(etf_btc.dailyTotalValueTraded.value / 1000000).toFixed(2)
										)
									}}
									M</div
								>
								<div class="etf-price-num"
									>${{
										formatNumber(
											(etf_btc.totalNetAssets.value / 1000000).toFixed(2)
										)
									}}
									M</div
								>
							</div>
							<div class="area-price-info flex" v-if="etf_eth.dailyNetInflow">
								<div class="etf-area">United States</div>

								<div class="etf-price-num"
									>${{
										formatNumber(
											(etf_eth.dailyNetInflow.value / 1000000).toFixed(2)
										)
									}}
									M</div
								>
								<div class="etf-price-num"
									>${{
										formatNumber(
											(etf_eth.cumNetInflow.value / 1000000).toFixed(2)
										)
									}}
									M</div
								>
								<div class="etf-price-num"
									>${{
										formatNumber(
											(etf_eth.dailyTotalValueTraded.value / 1000000).toFixed(2)
										)
									}}
									M</div
								>
								<div class="etf-price-num"
									>${{
										formatNumber(
											(etf_eth.totalNetAssets.value / 1000000).toFixed(2)
										)
									}}
									M</div
								>
							</div>
						</div>
					</div>
				</div>
				<!--    Plate     -->
				<div class="plate-info-box flex" v-if="activeName == 'Plate'">
					<div class="plate-info" v-for="item in 5" :key="item">
						<p class="plate-top">The biggest increase in 24 hours</p>
						<p class="plate-mid">ssLAL</p>
						<p class="plate-bottom">+280.21%</p>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
		<a
			href="https://sosovalue.com/tc"
			target="_blank"
			v-if="etf_btc.cumNetInflow"
		>
			<div class="update_time_show">
				<span
					>截至 {{ etf_btc.cumNetInflow.lastUpdateDate }} POWERED BY
					{{ activeName == "ETF" ? "SOSOVALUE" : "OKX" }}</span
				>
				<img src="https://zy.metaera.media/assets/images/home/right_top.svg" />
			</div>
		</a>
	</div>
</template>

<script>
import { newBus } from "../../components/pc/topHeader2.vue";
export default {
	props: ["etf_btc", "etf_eth", "spot"],
	data() {
		return {
			localLanguage: 1,
			activeName: "Spot",
			type: 1,
			categoryLists: [
				{
					id: "Spot",
					name: "Spot",
					name_en: "Industry Research",
					create_date: "2024-11-27 23:33:39",
				},
				{
					id: "ETF",
					name: "ETF",
					name_en: "Industry News",
					create_date: "2023-11-09 02:35:18",
				},
				// {
				// 	id: "Plate",
				// 	name: "Plate",
				// 	name_en: "Policy Interpretatio",
				// 	create_date: "2024-12-16 23:48:24",
				// },
			],
		};
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		formatNumber(value) {
			if (!value) return "0";
			// 将数字按小数点分割为整数部分和小数部分
			const [integerPart, decimalPart] = value.toString().split(".");
			// 对整数部分每三位加逗号
			const formattedInteger = integerPart.replace(
				/\B(?=(\d{3})+(?!\d))/g,
				","
			);
			// 如果有小数部分，则拼接小数部分，否则只返回整数部分
			return decimalPart
				? `${formattedInteger}.${decimalPart}`
				: formattedInteger;
		},
		chageType(type) {
			this.type = type;
			this.$emit("etfChangeFilter", { type: this.type });
		},
	},
	watch: {
		activeName(val) {
			if (val == "ETF") {
				this.$nextTick(() => {
					// this.$refs.tageBoxRef.scrollTop = 0;
				});
			} else if (val == "Spot") {
				this.$emit("getSpot");
			}
		},
	},
};
</script>

<style>
.tageBoxEtf.tageBox {
	margin-top: 0;
}
.tageBoxEtf .el-tabs__content {
	min-height: 180px;
}
.tageBoxEtf .el-tabs__nav.is-top {
	padding-top: 8px;
	box-sizing: border-box;
	height: calc(100vw * 40 / 1440);
}
.etf-info-box {
	margin: 20px 0 10px;
	color: #fff;
}
.zhan-wei {
	background: transparent !important;
}
.etf-price-type {
	overflow: hidden;
	padding-left: 10px;
	padding-bottom: 10px;
}
.etf-price-name {
	flex-grow: 1;
	font-size: clamp(15px, calc(100vw * 16 / 1440), 16px);
	text-align: center;
	font-weight: 500;
}
.etf-info-name {
	box-sizing: border-box;
	flex-shrink: 0;
	width: 90px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	position: relative;
	z-index: 1;
}

.etf-info-name:first-of-type {
	border-bottom: 1px solid rgba(143, 143, 189, 0.2);
}

.active1 p:first-child,
.active2 p:last-child {
	z-index: 0;
	background: rgba(143, 143, 189, 0.4);
	border-left: 2px solid#fff;
}
.active1 p:last-child {
	border-radius: 0 4px 0 4px;
	margin-top: -2px;
}
.active2 p:first-child {
	border-radius: 4px 0 4px 0;
	margin-bottom: -2px;
}
.etf-data-box {
	border-radius: 0 8px 8px 0;
	overflow: hidden;
}

.etf-right-box {
	/* padding: 0 10px; */
	flex-grow: 1;
	height: 120px;
	text-align: center;
}

.area-price-info {
	height: 50px;
	line-height: 50px;
}

.area-price-info:first-of-type {
	border-bottom: 1px solid rgba(143, 143, 189, 0.2);
}

.etf-area {
	flex-shrink: 0;
	width: 100px;
	font-size: 12px;
	font-weight: bold;
}

.etf-price-num {
	flex: 1;
	font-size: clamp(12px, calc(100vw * 13 / 1440), 14px);
	font-weight: bold;
	text-align: right;
	padding-right: 10px;
}

.left-bottom {
	border-radius: 0 8px 8px 8px;
}
.left-top {
	border-radius: 8px 8px 8px 0;
}

.plate-info-box {
	padding: 24px 0 45px;
	/* flex-wrap: nowrap; */
}
.plate-info:first-of-type {
	border-left: none;
	padding-left: 0;
}
.plate-info {
	padding-left: 10px;
	border-left: 1px solid rgba(255, 255, 255, 0.2);
	/* 纯白 */
	color: #ffffff;
}
.plate-top {
	font-family: AlibabaPuHuiTi;
	font-size: 16px;
	font-weight: normal;
	line-height: 20px;
}
.plate-mid {
	margin-top: 12px;
	font-family: AlibabaPuHuiTi;
	font-size: 20px;
	font-weight: bold;
	line-height: 20px;
}
.plate-bottom {
	margin-top: 8px;
	width: 60px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	border-radius: 4px;
	background: #1ab083;
	font-size: 10px;
}
.info-box {
	padding: 30px 0;
}
.data-info {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	height: 58px;
	align-items: center;
	width: 30%;
	justify-content: space-between;
}
.typeName img {
	width: 25px;
	height: 25px;
	margin-right: 5px;
	object-fit: cover;
	border-radius: 50%;
	vertical-align: middle;
}

.typeName span {
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: bold;
	line-height: 14px;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.typePrice {
	/* margin: 0 10px 0 35px; */
	margin-right: 20px;
	flex-grow: 1;
	text-align: right;
	font-family: PingFang SC;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.typeChange {
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #1ab083;
}
.update_time_show {
	/* border-top: 1px solid rgba(255, 255, 255, 0.5); */
	padding-top: 13px;
}
.update_time_show span {
	font-family: AlibabaPuHuiTi;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	opacity: 0.4;
}
.update_time_show img {
	width: 12px;
	height: 12px;
	vertical-align: middle;
}
</style>
