<template>
	<div class="third_party">
		<iframe
			src="https://www.metaerasummit.com/"
			width="100%"
			height="100%"
			frameborder="0"
			allowfullscreen
		></iframe>
	</div>
</template>

<script>
export default {
	name: "",
	methods: {},
};
</script>
<style scoped>
.third_party {
	height: 100vh;
	width: 100vw;
	margin-left: -230px;
}
</style>
