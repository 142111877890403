<template>
	<div class="pc_bottom" :class="{ pc_bottom_color: type == 'color' }">
		<div class="pc_bottom_content">
			<div class="pc_bottom_content_logo">
				<img
					v-if="type == 'color'"
					src="https://zy.metaera.media/assets/images/logo.png"
					alt=""
				/>
				<img
					v-else
					src="https://zy.metaera.media/assets/images/logo_w.png"
					alt=""
				/>

				<span>
					MetaEra是Web3行業的品牌與增長專家，為客戶提供創意型解決方案。MetaEra在全球各地區利用全方位優勢資源，為您的品牌管理和業務增長提供定製化服務。
				</span>
			</div>
			<div class="pc_bottom_content_right">
				<div class="pc_bottom_content_product">
					<div
						class="pc_bottom_content_title"
						:class="{ pc_bottom_content_title_color: type == 'color' }"
						>产品</div
					>
					<div
						class="pc_bottom_content_btn pointer hovercolor"
						:class="{ pc_bottom_content_btn_color: type == 'color' }"
						@click="toPath('/home_kuaixun')"
					>
						快讯
					</div>
					<div
						class="pc_bottom_content_btn pointer hovercolor"
						:class="{ pc_bottom_content_btn_color: type == 'color' }"
						@click="toPath('/home_zl')"
					>
						专栏
					</div>
					<div
						class="pc_bottom_content_btn pointer hovercolor"
						:class="{ pc_bottom_content_btn_color: type == 'color' }"
						@click="toPath('/events')"
					>
						活动
					</div>
				</div>
				<div class="pc_bottom_content_explore">
					<div
						class="pc_bottom_content_title"
						:class="{ pc_bottom_content_title_color: type == 'color' }"
						>探索</div
					>
					<div
						class="pc_bottom_content_btn pointer hovercolor"
						:class="{ pc_bottom_content_btn_color: type == 'color' }"
						@click="toSearch()"
					>
						搜索
					</div>
					<div
						class="pc_bottom_content_btn"
						:class="{ pc_bottom_content_btn_color: type == 'color' }"
						@click="toPath()"
					>
						下载
					</div>
					<div class="pc_bottom_content_btn">
						<img
							src="https://zy.metaera.media/assets/images/aboutus/download.png"
							alt=""
						/>
					</div>
				</div>
				<div class="pc_bottom_content_company">
					<div
						class="pc_bottom_content_title"
						:class="{ pc_bottom_content_title_color: type == 'color' }"
						>公司</div
					>
					<div
						class="pc_bottom_content_btn pointer hovercolor"
						:class="{ pc_bottom_content_btn_color: type == 'color' }"
						@click="toPath('/aboutus')"
					>
						关于MetaEra
					</div>
					<div
						class="pc_bottom_content_btn pointer hovercolor"
						:class="{ pc_bottom_content_btn_color: type == 'color' }"
						@click="toPath('/aboutus')"
					>
						联系我们
					</div>
				</div>
			</div>
		</div>
		<div class="pc_bottom_bottom">
			<div class="pc_bottom_bottom_left">
				<span
					class="pc_bottom_bottom_left_title"
					:class="{ pc_bottom_content_btn_color: type == 'color' }"
				>
					© 2022 Welcome. All right reserved.
				</span>
				<span
					class="pc_bottom_bottom_left_title pointer hovercolor"
					:class="{ pc_bottom_content_btn_color: type == 'color' }"
					@click="gotoPrivacy()"
				>
					Privacy Policy
				</span>
				<span
					class="pc_bottom_bottom_left_title pointer hovercolor"
					:class="{ pc_bottom_content_btn_color: type == 'color' }"
					@click="gotoPrivacy()"
				>
					Terms of Service
				</span>
			</div>
			<div class="pc_bottom_bottom_right" v-if="type == 'color'">
				<img
					class="pointer"
					src="https://zy.metaera.media/assets/images/active/9_b.png"
					@click="showUrl('https://www.youtube.com/@MetaEraCN')"
				/>
				<img
					class="pointer"
					src="https://zy.metaera.media/assets/images/active/10_b.png"
					@click="showUrl('https://x.com/MetaEraCN')"
				/>
				<img
					class="pointer"
					src="https://zy.metaera.media/assets/images/active/11_b.png"
					@click="
						showUrl(
							'https://www.linkedin.com/company/metaerahk/?originalSubdomain=hk'
						)
					"
				/>
			</div>
			<div class="pc_bottom_bottom_right" v-else>
				<img
					class="pointer"
					src="https://zy.metaera.media/assets/images/active/9.png"
					@click="showUrl('https://www.youtube.com/@MetaEraCN')"
				/>
				<img
					class="pointer"
					src="https://zy.metaera.media/assets/images/active/10.png"
					@click="showUrl('https://x.com/MetaEraCN')"
				/>
				<img
					class="pointer"
					src="https://zy.metaera.media/assets/images/active/11.png"
					@click="
						showUrl(
							'https://www.linkedin.com/company/metaerahk/?originalSubdomain=hk'
						)
					"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	props: {
		type: {
			type: String,
			default: "black",
		},
	},
	mounted() {},
	created() {},
	data() {
		return {
			token: 0,
		};
	},
	methods: {
		toSearch() {
			this.$router.push({ path: "/search" });
		},
		toPath(router) {
			this.$router.push({ path: router });
		},
		showUrl(url) {
			window.open(url, "");
		},
		gotoPrivacy() {
			this.$router.push({
				path: "/copyright",
			});
		},
	},
	watch: {},
};
</script>
<style scoped>
.pc_bottom {
	width: 100%;
	height: 360px;
	padding-top: 80px;
	background: black;
	/* border: 1px solid #e1e3f1; */
}

.pc_bottom_color {
	margin-left: 5%;
	width: 90%;
	/* margin-bottom: 20px; */
	background: rgba(255, 255, 255, 0.6);
	border-radius: 31px;
	border: 1px solid #e1e3f1;
}

.pc_bottom_content {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.pc_bottom_content_logo {
	margin-left: 90px;
	width: 40%;
}
.pc_bottom_content_logo img {
	width: 310px;
	height: auto;
}

.pc_bottom_content_logo span {
	margin-top: 40px;
	font-size: 14px;
	font-weight: normal;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: rgba(255, 255, 255, 0.5);
}
.pc_bottom_content_right {
	display: flex;
	width: 50%;
	margin-top: 0px;
	margin-left: 0px;
}
.pc_bottom_content_product,
.pc_bottom_content_explore,
.pc_bottom_content_company {
	width: 33%;
}
.pc_bottom_content_title {
	margin-bottom: 27px;
	font-family: Inter;
	font-size: 13px;
	font-weight: normal;
	line-height: 18.2px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	color: rgba(255, 255, 255, 0.65);
}

.pc_bottom_content_title_color {
	color: #333333;
	opacity: 0.5;
}

.pc_bottom_content_btn {
	margin-bottom: 24px;
	font-size: 20px;
	font-weight: normal;
	line-height: 16px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.pc_bottom_content_btn_color {
	color: #333333 !important;
}

.pc_bottom_content_btn img {
	width: 90px;
	height: auto;
}

.pc_bottom_bottom {
	width: 100%;
	margin-top: 60px;
	display: flex;
	justify-content: space-between;
}

.pc_bottom_bottom_left {
	margin-left: 80px;
	display: flex;
}
.pc_bottom_bottom_left_title {
	margin-right: 25px;
	font-family: Inter;
	font-size: 13px;
	font-weight: normal;
	line-height: 18.2px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	color: rgba(255, 255, 255, 0.65);
}

.pc_bottom_bottom_right {
	display: flex;
	width: 100px;
	height: 20px;
	margin-right: 180px;
	/* justify-content: center; */
}

.pc_bottom_bottom_right img {
	width: 20px;
	height: 100%;
	margin-right: 16px;
	/* margin-left: 16px; */
}
</style>

<style scoped>
@media screen and (max-width: 900px) {
	.pc_bottom {
		/* width: 100%; */
		height: auto;
		padding-top: 40px;
	}
	.pc_bottom_content {
		display: block;
	}

	.pc_bottom_content_logo {
		margin-left: 10%;
		width: 80%;
	}

	.pc_bottom_content_right {
		width: 80%;
		margin-top: 30px;
		margin-left: 10%;
	}
	.pc_bottom_bottom {
		display: block;
		margin-top: 20px;
	}
	.pc_bottom_content_title {
		font-size: 12px;
	}
	.pc_bottom_content_btn {
		font-size: 16px;
	}

	.pc_bottom_bottom_left {
		margin-left: 20px;
		margin-right: 20px;
	}

	.pc_bottom_bottom_left_title {
		margin-right: 0px;
		font-family: Inter;
		font-size: 13px;
		font-weight: normal;
		line-height: 18.2px;
		display: contents;
		align-items: center;
	}

	.pc_bottom_bottom_right {
		justify-content: flex-end;
		width: 100%;
	}
	.pc_bottom_bottom {
		width: 100%;
	}
}
</style>
