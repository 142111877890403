import Vue from "vue";
import router from "../router";
const hrefFilter = function (value1, value2) {
	if (value1) {
		return router.resolve({ name: value1, params: value2 }).href;
	} else {
		return "";
	}
};
export default hrefFilter;
