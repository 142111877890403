<template>
	<div>
		<div class="js-content">
			<div class="js_feedback">
				<div class="js_feedback_header_box">
					<img src="https://staticn.jinse.cn/w/img/2d4a1f7.png"
				/></div>
				<div class="js_feedback_con_box">
					<div class="js_feedback_form_item_type_box">
						<div
							class="js_feedback_form_item_type"
							v-for="(item, index) in 4"
							:key="index"
							@click="form.type = item"
							:class="{
								js_feedback_form_item_type_activity: form.type == item,
							}"
						>
							<span v-if="item == 1">功能建议</span>
							<span v-if="item == 2">体验建议</span>
							<span v-if="item == 3">内容建议</span>
							<span v-if="item == 4">其他</span>
						</div>
					</div>
					<div class="js-box__textarea">
						<el-input
							type="textarea"
							placeholder="请在这里填写您对MetaEra的意见与反馈"
							v-model="form.content"
							maxlength="500"
							show-word-limit
						>
						</el-input>
					</div>

					<div class="js_feedback_form_item_contact_box"
						><input
							placeholder="联系手机号/微信号/QQ号/邮箱"
							value=""
							v-model="form.contact"
							class=""
						/>
						<div class="contact_required_coin"> </div>
					</div>
					<div class="js_feedback_form_item_linkman_box"
						><input
							placeholder="如何称呼您，如赵先生/赵女士"
							value=""
							v-model="form.name"
							class=""
						/>
						<div class="linkman_required_coin"> </div>
					</div>
					<div class="js_feedback_form_item_submit" @click="submit">
						<div class="submit_button"> 提交反馈 </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { submitFeedback } from "@/api/user";
export default {
	name: "",
	data() {
		return {
			form: {
				type: 1,
				content: "",
				contact: "",
				name: "",
			},
		};
	},
	methods: {
		submit() {
			if (this.form.content == "") {
				this.$message.error("请完善意见与反馈(5-500字)");
			} else if (this.form.contact == "") {
				this.$message.error("请完善联系方式");
			} else if (this.form.name == "") {
				this.$message.error("请完善称呼信息");
			} else {
				submitFeedback(this.form)
					.then((res) => {
						if (res.code == 200) {
							this.$message.success("提交成功！");
							this.clearFormAdd();
						} else {
						}
					})
					.catch((error) => {
						// this.$message.error(error.message);
					});
			}
		},
		clearFormAdd() {
			for (let key in this.form) {
				this.form[key] = "";
			}
		},
	},
};
</script>

<style scoped>
.js-content {
	background: #f7f8fa;
}

.js_feedback {
	width: 750px;
	text-align: center;
	margin: 34px auto 49px;
	position: relative;
}

.js_feedback .js_feedback_header_box,
.js_feedback .js_feedback_header_box img {
	width: 750px;
	height: 170px;
}

.js_feedback .js_feedback_con_box {
	border-radius: 20px;
	position: relative;
	top: -20px;
	padding: 55px 171px;
	width: 750px;
	background: #fff;
	box-sizing: border-box;
}

.js_feedback .js_feedback_con_box .js_feedback_form_item_type_box {
	height: 24px;
	display: flex;
	margin-bottom: 21px;
}

.js_feedback
	.js_feedback_con_box
	.js_feedback_form_item_type_box
	.js_feedback_form_item_type {
	height: 24px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 24px;
	padding: 0 8px;
	border-radius: 15px;
	margin-right: 9px;
	color: #a0a0a9;
	background: #f9f9f9;
	cursor: pointer;
}

.js_feedback
	.js_feedback_con_box
	.js_feedback_form_item_type_box
	.js_feedback_form_item_type_activity {
	background: #ffb100;
	color: #fff;
}

.js_feedback_form_item_content_box {
	background: #fff;
	height: 148px;
	box-sizing: border-box;
	border-radius: 4px;
	position: relative;
	color: #000;
}

.el-input {
	height: 148px;
	width: 408px;
	box-sizing: border-box;
	border-radius: 4px;
	padding-left: 22px;
	padding-top: 17px;
	overflow: auto;
	color: #000;
}

.js_feedback .js_feedback_form_item_content_box textarea {
	height: 148px;
	width: 408px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	padding: 17px 22px;
	box-sizing: border-box;
	color: #000;
}

.js_feedback .js_feedback_form_item_content_box textarea:focus {
	outline: none !important;
	border-color: #fb2;
	height: 148px;
	width: 408px;
	color: #000;
}

.js_feedback
	.js_feedback_form_item_content_box
	textarea::-webkit-input-placeholder {
	color: #000;
}

.js_feedback .js_feedback_form_item_content_box textarea:-moz-placeholder,
.js_feedback .js_feedback_form_item_content_box textarea::-moz-placeholder {
	color: #000;
}

.js_feedback
	.js_feedback_form_item_content_box
	textarea::-ms-input-placeholder {
	color: #000;
}

.js_feedback .js_feedback_form_item_content_box .js_textareanum_tip {
	position: absolute;
	bottom: 17px;
	right: 13px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
	text-align: right;
	color: #a0a0a8;
	background: #fff;
	padding: 1px;
}

.js_feedback .js_feedback_form_item_content_box .content_required_coin {
	position: absolute;
	top: 22px;
	left: 14px;
	height: 5px;
	width: 5px;
}

.js_feedback .js_feedback_form_item_content_box .content_required_coin img {
	position: absolute;
	height: 5px;
	width: 5px;
}

.js_feedback .js_feedback_form_item_contact_box {
	margin-top: 21px;
	background: #fff;
	height: 48px;
	box-sizing: border-box;
	border-radius: 4px;
	position: relative;
}

.js_feedback .js_feedback_form_item_contact_box input {
	height: 48px;
	width: 408px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 48px;
	color: #000;
	padding-left: 22px;
	border-radius: 4px;
	border: 1px solid #c7c7cd;
	box-sizing: border-box;
}

.js_feedback .js_feedback_form_item_contact_box input:focus {
	outline: none !important;
	border: 1px solid #fb2;
	width: 408px;
	border-radius: 4px;
	height: 48px;
	box-sizing: border-box;
}

.js_feedback .js_feedback_form_item_contact_box .contact_required_coin {
	position: absolute;
	top: 22px;
	left: 14px;
	height: 5px;
	width: 5px;
}

.js_feedback .js_feedback_form_item_contact_box .contact_required_coin img {
	position: absolute;
	height: 5px;
	width: 5px;
}

.js_feedback .js_feedback_form_item_linkman_box {
	margin-top: 21px;
	background: #fff;
	height: 48px;
	box-sizing: border-box;
	border-radius: 4px;
	position: relative;
}

.js_feedback .js_feedback_form_item_linkman_box input {
	height: 48px;
	width: 408px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 48px;
	color: #000;
	padding-left: 22px;
	border-radius: 4px;
	border: 1px solid #c7c7cd;
	box-sizing: border-box;
}

.js_feedback .js_feedback_form_item_linkman_box input:focus {
	outline: none !important;
	border: 1px solid #fb2;
	width: 408px;
	height: 48px;
	border-radius: 4px;
	box-sizing: border-box;
}

.js_feedback .js_feedback_form_item_linkman_box .linkman_required_coin {
	position: absolute;
	top: 23px;
	left: 14px;
	height: 5px;
	width: 5px;
}

.js_feedback .js_feedback_form_item_linkman_box .linkman_required_coin img {
	position: absolute;
	height: 5px;
	width: 5px;
}

.js_feedback .js_feedback_form_item_submit {
	margin-top: 34px;
	height: 42px;
	box-sizing: border-box;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.js_feedback .js_feedback_form_item_submit .submit_button {
	width: 242px;
	height: 42px;
	left: 599px;
	top: 762px;
	background: #fb2;
	border-radius: 4px;
	line-height: 42px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	text-align: center;
	color: #fff;
	cursor: pointer;
}

.js_feedback .tip_con_box {
	height: 40px;
	line-height: 40px;
	padding-left: 20px;
	padding-right: 20px;
	background: #fb2;
	position: fixed;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	color: #000;
	font-size: 16px;
	border-radius: 4px;
}

.redBorder {
	border: 1px solid #e44d66 !important;
}
</style>
<style>
.js-box__textarea .el-textarea textarea {
	height: 200px !important;
}
</style>
