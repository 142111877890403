<template>
	<div class="wzitem_sub pointer">
		<router-link
			target="_blank"
			:to="{ name: '/gw_detail', params: { id: item.id } }"
		>
			<div style="display: flex">
				<div class="wzitem_sub_Left_line"></div>
				<div class="wzitem_sub_Left_author hovercolor">{{
					localLanguage == 2
						? item.title
							? item.title
							: item.title
						: item.title
				}}</div>
			</div>
		</router-link>
	</div>
</template>

<script>
import { newBus } from "@/components/pc/topHeader2.vue";
export default {
	props: ["item"],
	data() {
		return {
			allData: [],
			localLanguage: 1,
		};
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {},
};
</script>

<style scoped>
.wzitem_sub {
	display: flex;
	padding-top: 10px;
	padding-bottom: 10px;
}
.wzitem_sub_Left_author {
	margin-right: 20px;
	width: 240px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	color: #000000;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.wzitem_sub_Left_line {
	margin-left: 20px;
	margin-right: 5px;
	margin-top: 7px;
	opacity: 1;
	width: 2px;
	height: 10px;
	border-radius: 1px;
	opacity: 1;
	/* 主色（一级颜色） */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}
</style>
