<template>
	<div>
		<indexPc class="pcPart" v-if="!isMobileDevice"></indexPc>
		<indexMoblie class="mobliePart" v-else></indexMoblie>
	</div>
</template>
<script>
import indexPc from "./index_pc.vue";
import indexMoblie from "./index_mobile.vue";
export default {
	components: {
		indexPc,
		indexMoblie,
	},
	computed: {
		isMobileDevice() {
			return this.$store.state.app.isMobileDevice;
		},
	},
	data() {
		return {};
	},
};
</script>
<style scoped>
.pcPart {
	display: block;
}
.mobliePart {
	display: none;
}
@media screen and (max-width: 900px) {
	.pcPart {
		display: none;
	}
	.mobliePart {
		display: block;
	}
}
</style>
