<template>
	<div class="myMain margin_auto flex">
		<LeftNav :currentPath="'my'"></LeftNav>
		<div class="myMain_r mine_content_right">
			<Individual></Individual>
		</div>
	</div>
</template>

<script>
import Individual from "./Individual/index";
import Mywz from "./mywz/index";
import { getToken } from "@/utils/auth";
import Publish from "./mywz/publish";
import LeftNav from "./leftNav";
export default {
	name: "",
	components: {
		Individual,
		Mywz,
		Publish,
		LeftNav,
	},
	data() {
		return {
			wzid: "",
		};
	},
	created() {
		const hasToken = getToken();
		console.log(hasToken);
		if (hasToken) {
		} else {
			this.$router.push({ path: "/" });
			// location.reload();
		}
	},
	methods: {},
};
</script>

<style scoped>
.myMain {
	width: 100%;
}
.myMain_r {
	width: 100%;
	opacity: 1;
	padding-top: 20px;
}
.active span {
	color: #0056ff;
}
</style>
