<template>
	<div>
		<div class="leftTab_main flex justify_content_sb">
			<div class="leftTab_header_img pointer">
				<img
					src="https://zy.metaera.media/assets/images/logonew.png"
					alt=""
					@click="toHome()"
				/>
			</div>
			<div class="leftTab_topLine"></div>
			<div class="leftTab_nav">
				<div
					class="leftTab_nav_item pointer"
					v-for="(item, index) in navList"
					:key="index"
					@click="toPath(item, index)"
					:class="{ active: index == currentIndex }"
				>
					<img
						class="activeImgTag"
						v-show="index == currentIndex"
						:src="item.activeImg"
					/>
					<img
						class="defaultImgTag"
						v-show="index != currentIndex"
						:src="item.defaultImg"
					/>
					<span class="hovercolor">{{ item.name }}</span>
				</div>
			</div>
			<div class="leftTab_bottomLine"></div>
			<div class="leftTab_fun">
				<div
					class="leftTab_fun_item pointer hovercolor"
					v-for="(item, index) in functionList"
					:key="index"
					@click="toPathFun(item, index)"
				>
					<span>{{ item.name }}</span>
				</div>
			</div>
			<div class="leftTab_bottom_bg"></div>
			<div class="leftTab_Language_list" v-if="showSel == 1">
				<div
					class="pointer"
					@click="selLanguage(0)"
					:class="{ leftTab_Language_list_sel: this.localLanguage == 0 }"
					>简体中文</div
				>
				<div
					class="pointer"
					@click="selLanguage(1)"
					:class="{ leftTab_Language_list_sel: this.localLanguage == 1 }"
					>繁体中文</div
				>
				<div
					class="pointer"
					@click="selLanguage(2)"
					:class="{ leftTab_Language_list_sel: this.localLanguage == 2 }"
					>English</div
				>
			</div>
			<div class="leftTab_Language">
				<div class="leftTab_Language_title">切换语言</div>
				<div class="leftTab_Language_right pointer" @click="showLanguage">
					<div class="leftTab_Language_right_title">
						{{
							this.localLanguage == 0
								? "简体中文"
								: this.localLanguage == 2
								? "English"
								: "繁体中文"
						}}
					</div>
					<div class="leftTab_Language_img">
						<img
							src="https://zy.metaera.media/assets/images/home/arrow.png"
							alt=""
							@click="toHome()"
						/>
					</div>
				</div>
			</div>
			<div class="leftTab_fun_fotter">
				<div
					class="leftTab_fun_fotter_item pointer"
					v-for="(item, index) in fotterList"
					:key="index"
					@click="toPathFun(item, index)"
				>
					<span>{{ item.name }}</span>
				</div>
			</div>
			<div class="leftTab_fotterLine"></div>
			<div class="leftTab_fotter">Copyright © 2023. Made by MetaEra</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import { getToken } from "@/utils/auth";
import { newBus } from "./topHeader2.vue";
import Vue from "vue";
export const eventBus = new Vue();
export default {
	name: "",
	created() {
		this.token = getToken();
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	data() {
		return {
			token: 0,
			currentIndex: 999,
			showHeader: true,
			currentPath: "",
			keyword: "",
			showSel: 0,
			localLanguage: 0,
			navList: [
				{
					name: "首页",
					router: "/",
					defaultImg:
						"https://zy.metaera.media/assets/images/headerImg/home2.png",
					activeImg:
						"https://zy.metaera.media/assets/images/headerImg/homeActive2.png",
				},
				{
					name: "快讯",
					router: "/home_kuaixun",
					defaultImg:
						"https://zy.metaera.media/assets/images/headerImg/kuaixun2.png",
					activeImg:
						"https://zy.metaera.media/assets/images/headerImg/kuaixunActive2.png",
				},
				{
					name: "专栏",
					router: "/home_zl",
					defaultImg:
						"https://zy.metaera.media/assets/images/headerImg/zhuanlan2.png",
					activeImg:
						"https://zy.metaera.media/assets/images/headerImg/zhuanlanActive2.png",
				},
				{
					name: "活动",
					router: "/events",
					defaultImg:
						"https://zy.metaera.media/assets/images/headerImg/huodong2.png",
					activeImg:
						"https://zy.metaera.media/assets/images/headerImg/huodongActive2.png",
				},
				{
					name: "美国",
					router: "/usa",
					activeImg: "https://zy.metaera.media/assets/images/tab/h_usa.png",
					defaultImg: "https://zy.metaera.media/assets/images/tab/h_usa_1.png",
				},
				{
					name: "香港",
					router: "/hk",
					activeImg: "https://zy.metaera.media/assets/images/tab/h_hk_1.png",
					defaultImg:
						"https://zy.metaera.media/assets/images/tab/h_hk_w_1_1.png",
				},
				{
					name: "DataEra",
					router: "https://www.metaera.hk/DataEra",
					defaultImg:
						"https://zy.metaera.media/assets/images/headerImg/dataera.png",
					activeImg:
						"https://zy.metaera.media/assets/images/headerImg/dataeraActive.png",
				},
				{
					name: "工具导航",
					router: "/home_tools",
					defaultImg:
						"https://zy.metaera.media/assets/images/headerImg/home_tool2.png",
					activeImg:
						"https://zy.metaera.media/assets/images/headerImg/home_toolActive2.png",
				},
			],
			functionList: [{ name: "关于我们", router: "/aboutus" }],

			fotterList: [
				{ name: "Privacy", router: "/copyright" },
				{ name: "About Us", router: "/aboutus" },
			],
		};
	},
	create() {},
	mounted() {
		// 包含关系
		if (location.href.indexOf("home_kuaixun") != -1) {
			this.currentIndex = 1;
			sessionStorage.setItem("localLanguageHK", "");
		} else if (location.href.indexOf("home_zl") != -1) {
			this.currentIndex = 2;
			sessionStorage.setItem("localLanguageHK", "");
		} else if (location.href.indexOf("/usa") != -1) {
			this.currentIndex = 4;
		} else if (location.href.indexOf("/hk") != -1) {
			this.currentIndex = 5;
		} else if (location.href.indexOf("home_tools") != -1) {
			this.currentIndex = 7;
			sessionStorage.setItem("localLanguageHK", "");
		} else {
			this.currentIndex = 0;
			sessionStorage.setItem("localLanguageHK", "");
		}
		newBus.$on("global-notification", (data) => {
			let index = 0;
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				index = 1;
			} else if (localLanguage == "english") {
				index = 2;
			} else {
				index = 0;
			}
			this.selLanguage(index);
		});
	},
	methods: {
		showLanguage() {
			if (this.showSel == 0) {
				this.showSel = 1;
			} else {
				this.showSel = 0;
			}
		},
		selLanguage(index) {
			this.showSel = 0;
			console.log(index);
			console.log(this.localLanguage);
			if (this.localLanguage == index) {
				return;
			}
			if (index == 0) {
				sessionStorage.setItem("localLanguage", "简体中文");
				if (this.localLanguage == 1) {
					this.$t2s(document.body);
				} else {
					this.$e2s(document.body, 0);
				}
				this.localLanguage = 0;
			} else if (index == 1) {
				sessionStorage.setItem("localLanguage", "繁体中文");
				if (this.localLanguage == 0) {
					this.$s2t(document.body);
				} else {
					this.$e2s(document.body, 1);
				}
				this.localLanguage = 1;
			} else {
				sessionStorage.setItem("localLanguage", "english");
				this.$s2e(document.body);
				this.localLanguage = 2;
			}
			// 通知发送
			eventBus.$emit("global-notification", {
				localLanguage: this.localLanguage,
			});
		},
		handleEnterKey() {
			this.$router.push({
				path: "/search",
			});
		},
		toHome() {
			this.$router.push("/");
			this.currentIndex = 0;
		},
		login() {
			this.$PromptTip({ dialogVisible: true, showStatus: 1 });
		},

		toPath(item, index) {
			this.keyword = "";
			if (item.router.indexOf("http") !== -1) {
				window.open(item.router, "");
			} else {
				this.$router.push({ path: item.router });
				this.currentIndex = index;
			}
		},
		toPathFun(item, index) {
			this.keyword = "";
			this.$router.push({ path: item.router });
		},
		logOut() {
			store.dispatch("user/logout");
		},
	},
	watch: {
		"$route.name": function (n) {
			this.showHeader = n == "/";
			this.currentPath = n;
			if (n == "/" || n == "/home") {
				this.currentIndex = 0;
			} else if (n == "/home_kuaixun") {
				this.currentIndex = 1;
			} else if (n == "/home_zl") {
				this.currentIndex = 2;
			} else if (n == "/use") {
				this.currentIndex = 4;
			} else if (n == "/hk") {
				this.currentIndex = 5;
			} else if (n == "/home_tools") {
				this.currentIndex = 7;
			} else {
				this.currentIndex = 999;
			}
		},
	},
};
</script>
<style scoped>
.leftTab_main {
	width: 230px;
	height: 100vh;
	background: white;
	display: flex;
	opacity: 1;
	z-index: 999;
	position: fixed;
	left: 0;
	/* box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.29); */
}
.leftTab_header_img {
	width: 100%;
	height: 110px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}
.leftTab_header_img img {
	width: 130px;
	height: auto;
}
.leftTab_topLine {
	display: flex;
	position: absolute;
	top: clamp(80px, calc(100vh * 90 / 700), 110px);
	left: 28px;
	width: 174px;
	height: 1px;
	opacity: 1;
	background: #e5e9fb;
}
.leftTab_bottomLine {
	display: flex;
	position: absolute;
	top: clamp(500px, calc(100vh * 500 / 700), 590px);
	left: 65px;
	width: 100px;
	height: 1px;
	opacity: 1;
	background: #e5e9fb;
}

.leftTab_nav {
	position: absolute;
	left: 25px;
	top: clamp(100px, calc(100vh * 80 / 700), 140px);
	width: 180px;
	opacity: 1;
}

.active {
	position: relative;
	border-radius: 17px;
	opacity: 1;
	background: #ffffff;
	box-shadow: 3.8px 12.43px 49px 0px rgba(54, 62, 147, 0.1686);
}
.active span {
	font-weight: 600 !important;
	/* color: #0056ff !important; */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.leftTab_nav_item {
	margin-left: 4px;
	margin-top: clamp(6px, calc(100vh * 8 / 700), 10px);
	margin-bottom: clamp(6px, calc(100vh * 8 / 700), 10px);
	align-content: center;
	align-items: center;
	opacity: 1;
	width: 172px;
	height: clamp(40px, calc(100vh * 40 / 700), 45px);
	display: flex;
	opacity: 1;
	background: rgba(0, 0, 0, 0);
}

.leftTab_nav_item img {
	margin-left: 30px;
	width: 24px;
	height: 24px;
}

.leftTab_nav_item:hover .activeImgTag {
	display: block !important;
}
.leftTab_nav_item:hover .defaultImgTag {
	display: none;
}
.leftTab_nav_item:hover span {
	color: #0056ff;
}

.leftTab_nav_item span {
	margin-left: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;
	color: #767676;
}

.leftTab_fun {
	position: absolute;
	left: 0px;
	top: clamp(520px, calc(100vh * 500 / 700), 610px);
	width: 230px;
	opacity: 1;
}

.leftTab_fun_item {
	margin-top: 10px;
	margin-bottom: 10px;
	align-content: center;
	align-items: center;
	justify-content: center;
	opacity: 1;
	width: 100%;
	height: 45px;
	display: flex;
	opacity: 1;
}
.leftTab_fun_item span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;

	/* 正文色/正文辅助色 */
	color: #767676;
}
.leftTab_bottom_bg {
	position: absolute;
	left: 0px;
	bottom: -10px;
	width: 230px;
	height: 180px;
	opacity: 1;
	background: white;
	display: flex;
}
.leftTab_fun_fotter {
	position: absolute;
	left: 15px;
	bottom: 50px;
	width: 200px;
	opacity: 1;
	justify-content: space-between;
	display: flex;
}
.leftTab_fun_fotter_item {
	margin-top: 10px;
	margin-bottom: 10px;
	align-content: center;
	align-items: center;
	justify-content: center;
	opacity: 1;
	width: 100%;
	height: 45px;
	display: flex;
	opacity: 1;
}
.leftTab_fun_fotter_item span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 13px;
	font-weight: normal;
	line-height: 13px;
	letter-spacing: 0em;
	color: #656878;
}

.leftTab_fotterLine {
	display: flex;
	position: absolute;
	bottom: 50px;
	left: 28px;
	width: 174px;
	height: 1px;
	opacity: 1;
	background: #e5e9fb;
}
.leftTab_fotter {
	justify-content: center;
	align-content: center;
	align-items: center;
	position: absolute;
	display: flex;
	left: 0px;
	bottom: 25px;
	width: 230px;
	opacity: 1;

	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 10px;
	font-weight: normal;
	line-height: 13px;
	letter-spacing: 0em;

	color: #b2b2b2;
}

.leftTab_Language {
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	bottom: 110px;
	left: 28px;
	width: 174px;
	height: 50px;
	opacity: 1;
}
.leftTab_Language_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 13px;
	font-weight: normal;
	line-height: 13px;
	color: #656878;
}
.leftTab_Language_right {
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	margin-left: 10px;
	width: 90px;
	height: 30px;
	border-radius: 10px;
	opacity: 1;
	background: #ffffff;
	box-shadow: 2.05px 6.69px 27px 0px rgba(54, 62, 147, 0.1686);
}
.leftTab_Language_right_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 13px;
	font-weight: normal;
	line-height: 13px;
	/* 主色（一级颜色） */
	color: #0056ff;
	margin-right: 10px;
}
.leftTab_Language_img img {
	width: 8px;
	height: auto;
	opacity: 1;
}
.leftTab_Language_list {
	padding-top: 20px;
	display: flex;
	position: absolute;
	justify-content: center;
	flex-direction: column;
	bottom: 160px;
	left: 105px;
	width: 90px;
	height: 90px;
	/* height: 60px; */
	border-radius: 10px;
	opacity: 1;
	background: #ffffff;
	/* 投影 */
	box-shadow: 2.05px 6.69px 27px 0px rgba(54, 62, 147, 0.1686);
}

.leftTab_Language_list div {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	color: #333333;
	margin-bottom: 20px;
	margin-left: 10px;
}
.leftTab_Language_list div:hover {
	font-weight: 600;
	color: #0056ff;
}

.leftTab_Language_list_sel {
	/* font-weight: 600;
	color: #0056ff !important; */
}
</style>
