<template>
	<div>
		<div
			class="Main width_1200 margin_auto flex justify_content_sb"
			style="padding-bottom: 20px"
		>
			<div class="MainLeft margin_left_20_media">
				<div class="rsTitle flex align_items_c margin_top_30">
					<div class="rsTitleLeft flex align_items_c">
						<div style="color: #000000; font-weight: bold">精选专题</div>
					</div>
				</div>
				<div class="zxzt margin_top_25 flex align_items_c justify_content_sb">
					<div
						class="zxztItem pointer"
						v-for="(item, index) in selectedspecial"
						:key="index"
						@click="
							$router.push({ name: '/home_jszk', params: { id: item.id } })
						"
					>
						<img :src="item.pic" :key="item.pic" alt="" />
						<div class="flex justify_content_c bf90">{{ item.name }}</div>
					</div>
				</div>
				<div class="rsTitle flex align_items_c margin_top_30">
					<div class="rsTitleLeft flex align_items_c">
						<div style="color: #000000; font-weight: bold">热门专题</div>
					</div>
				</div>
				<div class="ztList margin_top_20">
					<div
						class="wzitem flex align_items_c pointer"
						v-for="(item, index) in popularspecial"
						:key="index"
						@click="
							$router.push({ name: '/home_jszk', params: { id: item.id } })
						"
						style="height: 168px; margin-top: 33px"
					>
						<img
							:src="item.pic"
							:key="item.pic"
							alt=""
							style="width: 109px; height: 100%"
						/>
						<div class="wzitemL" style="width: 100%">
							<div class="ellipsis_one wzitemLTitle">
								<div
									style="color: #000000; font-weight: bold"
									class="ellipsis_one"
								>
									{{ item.name }}</div
								>
								<div class="ellipsis_multiple"> {{ item.desc }}</div>
							</div>
							<div class="wzitemLTime flex align_items_c justify_content_sb">
								<div class="wzitemLTime_l">{{ item.create_time }}</div>
							</div>
						</div>
					</div>
					<pagination
						v-show="total > 0"
						:total="total"
						:page.sync="page"
						:limit.sync="size"
						@pagination="getDataList"
					/>
				</div>
			</div>
			<div class="MainRight">
				<resou></resou>
				<event type="recommend" title="主编推荐"></event>
			</div>
		</div>
	</div>
</template>

<script>
import { getSpecial } from "@/api/home";
export default {
	name: "",
	components: {},
	data() {
		return {
			selectedspecial: [], //精选
			popularspecial: [], //热门
			page: 1,
			size: 25,
			total: "",
		};
	},
	created() {
		this.getDataList();
	},
	methods: {
		getDataList() {
			getSpecial({ page: this.page, size: this.size })
				.then((res) => {
					if (res.code == 200) {
						this.selectedspecial = res.data.selected.splice(0, 4);
						this.popularspecial = res.data.popular.list;
						this.total = res.data.popular.total;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.Main {
	padding-top: 20px;
}

.MainLeft {
	width: 600px;
}
.zxztItem {
	width: 178px;
	height: 280px;
	position: relative;
}
.bf90 {
	width: 90%;
}
.zxztItem img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}
.zxztItem div {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	position: absolute;
	bottom: 16px;
}
.wzitem:nth-child(1) {
	margin-top: 0 !important;
}
.MainRight {
	width: 360px;
}
</style>
