<template>
	<div class="ac-detail-pc" @scroll="handleScroll">
		<topHeader></topHeader>
		<div class="ac_banner_box">
			<div
				class="ac_detail_bg_top padding_120 ac_detail_ticket"
				@mouseover="hideInfo()"
			>
				<div class="ac_detail_ticket_right">
					<div class="ac_detail_ticket_top">
						<div class="ac_detail_content_online">
							{{ item.type == 5 ? "线上活动" : "线下活动" }}
						</div>
						<div
							class="ac_detail_ticket_sideevent"
							v-if="item.mainevent != null && item.mainevent != ''"
						>
							{{ item.mainevent }}
						</div>
					</div>

					<div class="ac_detail_ticket_title">{{ item.title }}</div>
					<div class="ac_detail_ticket_date">
						<div class="ac_detail_ticket_info">
							<div class="ac_detail_ticket_info_item">
								<img
									src="https://zy.metaera.media/assets/images/active/add_tu_w.svg"
								/>
								<span>{{ item.address }}</span>
							</div>
							<div class="ac_detail_ticket_info_item">
								<img
									src="https://zy.metaera.media/assets/images/active/time_tu_w.svg"
								/>
								<span class="ac_detail_ticket_info_item_time">
									{{ formatDate(item.start_time) }} -
									{{ formatDate(item.end_time) }} {{ item.timeZone }}
								</span>
							</div>
							<!-- v-if="
								item.specialCode != undefined && item.specialCode.length > 0
							" -->
							<div
								v-if="
									item.specialCode != undefined && item.specialCode.length > 0
								"
								class="ac_detail_ticket_info_item ac_detail_ticket_info_item1"
							>
								<img
									src="https://zy.metaera.media/assets/images/active/code_tu_w.svg"
								/>

								<div class="ac_detail_code">
									<span>ME邀请码</span>
									<p class="code_box">
										<span class="maincolor">{{ item.specialCode }}</span>
										<img
											class="pointer"
											src="https://zy.metaera.media/assets/images/active/copy_tu_c.svg"
											@click="copyText(item.specialCode)"
										/>
									</p>
								</div>
							</div>
							<div class="ac_detail_ticket_info_item_btn">
								<div
									class="ac_detail_ticket_info_item_share ac_detail_ticket_info_item_share_people pointer"
								>
									<div
										class="ac_detail_ticket_info_item_share_img ac_detail_ticket_info_item_share_img_people"
									></div>
									<span
										class="ac_detail_ticket_info_item_share_text ac_detail_ticket_info_item_share_text_people pointer"
										@click="showUrl(item.url)"
									>
										参加活动
									</span>
								</div>
								<div class="ac_detail_ticket_info_item_share pointer">
									<div class="ac_detail_ticket_info_item_share_img"></div>
									<span
										class="ac_detail_ticket_info_item_share_text pointer"
										@click="share(item.id)"
									>
										分享活动
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="ac_detail_ticket_left">
					<img :src="item.pic" />
				</div>
			</div>
		</div>
		<!-- 活动主办方&协办方 -->
		<div class="ac_detail_ticket_people padding_120">
			<div class="ac_detail_ticket_people_item host">
				<div class="ac_detail_ticket_people_title_1">活动主办方</div>
				<div
					class="ac_detail_ticket_people_list"
					:class="{ ac_detail_ticket_people_list_hide: hostsBool }"
					ref="peopleList_host"
				>
					<div
						v-for="(item, index) in hostsArray"
						:key="index"
						class="host-item-wrapper"
						ref="listItem_host"
					>
						<div
							class="ac_detail_ticket_people_list_item"
							@mouseenter="showInfo(index)"
							@click="toggleInfo(index)"
						>
							<img :src="item.pic" />
						</div>
						<div
							v-if="hoverHostIndex == index"
							class="ac_detail_ticket_people_list_item_layer"
							@mouseleave="hideInfo()"
							:style="layerStyle"
						>
							<div class="ac_detail_ticket_people_list_item_layer_img">
								<img :src="item.pic" />
							</div>
							<div class="ac_detail_ticket_people_list_item_layer_name">
								{{ item.name }}
							</div>
							<div class="ac_detail_ticket_people_list_item_layer_content">
								{{ item.content }}
							</div>
							<div class="ac_detail_ticket_people_list_item_layer_url">
								<div
									v-if="item.insLink.length > 0"
									@click.stop="showUrl(item.insLink)"
								>
									<img
										src="https://zy.metaera.media/assets/images/active/35.png"
									/>
								</div>
								<div
									v-if="item.twLink.length > 0"
									@click.stop="showUrl(item.twLink)"
								>
									<img
										src="https://zy.metaera.media/assets/images/active/36_1.png"
									/>
								</div>
								<div
									v-if="item.ytLink.length > 0"
									@click.stop="showUrl(item.ytLink)"
								>
									<img
										src="https://zy.metaera.media/assets/images/active/37.png"
									/>
								</div>
								<div
									v-if="item.tgLink.length > 0"
									@click.stop="showUrl(item.tgLink)"
								>
									<img
										src="https://zy.metaera.media/assets/images/active/56.png"
									/>
								</div>
							</div>
						</div>
					</div>
					<!-- 展开更多 -->
					<div class="host-item-wrapper more-wraper">
						<div
							v-if="realHostsArray.length > 6"
							class="more-btn"
							:class="{ hide_more: hostsBool }"
							@click="hostMore()"
						>
							<img
								src="https://zy.metaera.media/assets/images/home/arrow_white_left.png"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="ac_detail_ticket_people_item coHost">
				<div class="ac_detail_ticket_people_title_1">活动协办方</div>
				<div
					v-if="coHostsArray.length > 0"
					class="ac_detail_ticket_people_list"
					:class="{
						ac_detail_ticket_people_list_hide: coHostsBool,
					}"
					ref="peopleList"
				>
					<div
						v-for="(item, index) in coHostsArray"
						:key="index"
						class="host-item-wrapper"
						ref="listItem"
					>
						<div
							class="ac_detail_ticket_people_list_item"
							@mouseenter="coShowInfo(index)"
							@click="coToggleInfo(index)"
						>
							<img :src="item.pic" />
						</div>

						<div
							v-if="hoverCoHostIndex == index"
							class="ac_detail_ticket_people_list_item_layer"
							@mouseleave="hideInfo()"
							:style="layerStyle"
						>
							<div class="ac_detail_ticket_people_list_item_layer_img">
								<img :src="item.pic" />
							</div>
							<div class="ac_detail_ticket_people_list_item_layer_name">
								{{ item.name }}
							</div>
							<div class="ac_detail_ticket_people_list_item_layer_content">
								{{ item.content }}
							</div>
							<div class="ac_detail_ticket_people_list_item_layer_url">
								<div
									v-if="item.insLink.length > 0"
									@click.stop="showUrl(item.insLink)"
								>
									<img
										src="https://zy.metaera.media/assets/images/active/35.png"
									/>
								</div>
								<div
									v-if="item.twLink.length > 0"
									@click.stop="showUrl(item.twLink)"
								>
									<img
										src="https://zy.metaera.media/assets/images/active/36_1.png"
									/>
								</div>
								<div
									v-if="item.ytLink.length > 0"
									@click.stop="showUrl(item.ytLink)"
								>
									<img
										src="https://zy.metaera.media/assets/images/active/37.png"
									/>
								</div>
								<div
									v-if="item.tgLink.length > 0"
									@click.stop="showUrl(item.tgLink)"
								>
									<img
										src="https://zy.metaera.media/assets/images/active/56.png"
									/>
								</div>
							</div>
						</div>
					</div>
					<!-- 展开更多 -->
					<div class="host-item-wrapper more-wraper">
						<div
							class="more-btn"
							:class="{ hide_more: coHostsBool }"
							v-if="realCoHostsArray.length > 6"
							@click="coHostMore()"
						>
							<img
								src="https://zy.metaera.media/assets/images/home/arrow_white_left.png"
							/>
						</div>
					</div>
				</div>
				<p class="no_cohost" v-else>暂无活动协办方</p>
			</div>
		</div>
		<!-- 是活动合集 ac_detail_side -->
		<div
			class="main-box flex padding_120 justify_content_sb"
			:class="{
				ac_detail_side: item.sideeventArray.length > 0,
			}"
		>
			<div class="main-left MainLeft_820 MainLeft_right_46">
				<!-- 活动内容 -->
				<div class="ac_detail_overview" @mouseover="hideInfo()">
					<div class="flex justify_content_sb ac_detail_header">
						<div class="ac_detail_overview_title_new">Event Overview</div>
						<!-- 这个是收起展开的判断条件 -->
						<div
							class="ac_detail_overview_title_expand"
							@click="toggleContent()"
							v-if="item.sideeventArray && item.sideeventArray.length > 0"
						>
							<img
								class="expand-icon"
								src="https://zy.metaera.media/assets/images/home/arrow_white_left.png"
								v-if="this.isCollapsed"
							/>
							<img
								class="coll-icon"
								src="https://zy.metaera.media/assets/images/home/arrow_white_left.png"
								v-else
							/>
						</div>
					</div>
					<div
						:class="[
							'ac_detail_overview_content ac_detail_overview_content_pc',
							isCollapsed &&
							item.sideeventArray &&
							item.sideeventArray.length > 0
								? 'collapsed'
								: 'expanded',
						]"
					>
						<p class="ql-editor" v-html="item.content"></p>
					</div>

					<div class="ac_detail_overview_line"></div>
				</div>
				<!-- 活动合集 这里判断条件换一下子-->
				<div class="activites-collection" v-if="otherActivities.length > 0">
					<p class="part_title"><span>其</span>他活动</p>
					<ac-heji class="activities-box" :mainList="otherActivities"></ac-heji>
				</div>
				<!-- 筛选项 -->
				<div
					class="ac_detail_filter"
					v-if="item.sideeventArray && item.sideeventArray.length > 0"
				>
					<div class="ac_detail_overview_title">{{
						item.otherSideEventArray.length > 0
							? "周边活动合集"
							: "独家活动合集"
					}}</div>
					<div class="select-box flex">
						<select_filter
							ref="selFilter"
							@changeFilter="changeFilter"
							:type="
								item.otherSideEventArray.length > 0 ||
								item.sideeventArray.length > 0
							"
						>
						</select_filter>
						<p class="clear pointer" @click="clearFilter">清除筛选</p>
					</div>
					<div
						v-for="(side, index) in item.sideeventArray"
						:key="index"
						class="ac_detail_side_content"
					>
						<perCooperate
							:side="side"
							v-if="
								judgeSideEventShow(side) && item.otherSideEventArray.length > 0
							"
						>
						</perCooperate>
						<perSingle
							:side="side"
							v-if="
								judgeSideEventShow(side) && item.otherSideEventArray.length == 0
							"
						>
						</perSingle>
					</div>
				</div>
			</div>
			<div
				class="main-right MainRight_330"
				v-if="item.sideeventArray.length <= 0"
			>
				<activePerItem
					v-for="(item, index) in selarticleList"
					:item="item"
					:key="index"
				>
				</activePerItem>
				<download></download>
			</div>
		</div>
		<!-- <bottom></bottom> -->
	</div>
</template>

<script>
import { getToken, getUserMobile } from "@/utils/auth";
import store from "@/store";
import modulePart from "@/components/modulePart.vue";

import topHeader from "../../components/pc/topHeader2.vue";
import bottom from "../../components/pc/bottom.vue";
import download from "../../components/pc/download.vue";
import activities from "../../components/activities.vue";
import acHeji from "./ac_heji.vue";
import hostMore from "./ac_host_more.vue";
import { getShareSignaTure } from "@/api/user";
import clipboard from "clipboard";
import {
	acitvityDetail,
	acitvityOrder,
	getActivityList,
	acitvityDetailHeji,
} from "@/api/home";
import { getUserId } from "@/utils/auth";
import acSuccess from "./ac_success.vue";
import { loadStripe } from "@stripe/stripe-js";
import activePerItem from "./active_per_item.vue";
import select_filter from "./select_filter.vue";
import perCooperate from "./per_cooperate.vue";
import perSingle from "./per_single.vue";
export default {
	name: "",
	components: {
		topHeader,
		bottom,
		hostMore,
		acSuccess,
		activities,
		acHeji,
		activePerItem,
		select_filter,
		perCooperate,
		perSingle,
		modulePart,
		download,
	},
	data() {
		return {
			selarticleList: [], // 右侧相关活动数据
			layerStyle: {}, // 用于动态设置浮层的样式
			hostsBool: false,
			coHostsBool: false,
			token: getToken(),
			item: {},
			showHost: false,
			tmpArray: [],
			tipTitle: "",
			loading: false,
			id: "",
			hostsArray: [],
			coHostsArray: [],
			realHostsArray: [],
			realCoHostsArray: [],
			hoverHostIndex: 9999,
			hoverCoHostIndex: 9999,
			windowWidth: 1280,
			showSuccess: false,
			tipTitle: "活动参与成功",
			tipMessage: "",
			ticket_sel_index: 0,
			ticketArray: [],
			count: 1,
			code: "",
			onCheck: 0,
			isCollapsed: true,
			state: 0,
			stateArray: ["All", "Not started", "In progress", "Over"],
			type: 0,
			maineventArray: [
				"All",
				"Afterparty",
				"Meetup",
				"Summit",
				"Networking",
				"Dinner / Gala",
				"Hackathon",
				"Workshop",
				"Other",
			],
			sharetitle: "MetaEra",
			sharedescs:
				"MetaEra是Web3行业的品牌与增长专家，为客户提供创意型解决方案。MetaEra在全球各地区利用全方位优势资源，为您的品牌管理和业务增长提供定制化服务。",
			wxsharelink: location.href,
			shareimg: "https://zy.metaera.media/logo_share.png",
			otherActivities: [],
			startTime: "开始日期",
			endTime: "结束日期",
			categoryValue: "全部",
		};
	},
	mounted() {
		this.token = getToken();
	},
	created() {
		this.token = getToken();
		this.id = this.$route.params.id;
		this.getAcitvityDetail();
		this.getAcitvityDetailHeji();
	},
	methods: {
		// 获取推荐活动
		getActivityList() {
			let res = JSON.parse(sessionStorage.getItem("homegetActivityListData"));
			if (res != null && res.code == 200) {
				this.selarticleList = res.data.list;
			}
			getActivityList({ type: 0, page: 1, size: 5 })
				.then((res) => {
					sessionStorage.setItem(
						"homegetActivityListData",
						JSON.stringify(res)
					);
					if (res.code == 200) {
						this.selarticleList = res.data.list;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		// 获取其他活动
		getAcitvityDetailHeji() {
			let obj = {};
			acitvityDetailHeji(obj)
				.then((res) => {
					if (res.code == 200) {
						this.loading = false;
						this.otherActivities = res.data.list;
						console.log("其他活动", res.data);
					} else {
						this.$message.error("获取其他活动失败，请重新获取");
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		// clearFilter
		clearFilter() {
			this.$refs.selFilter.resetVal();
		},

		changeFilter(item) {
			console.log("这个是接收到的筛选条件", item);
			this.startTime = item.startDate;
			this.endTime = item.endDate;
			this.categoryValue = item.categoryValue;
		},
		judgeSideEventShow(item) {
			let isShow = false;

			if (
				((((item.start_time <= this.endTime &&
					item.start_time >= this.startTime) ||
					(item.end_time <= this.endTime &&
						item.start_time >= this.startTime)) &&
					this.startTime != "开始日期") ||
					this.startTime == "开始日期") &&
				(this.categoryValue == "全部" || item.mainevent == this.categoryValue)
			) {
				isShow = true;
			}
			return isShow;
		},
		//微信分享方法
		WxSharMenu() {
			let urlStr = encodeURIComponent(document.location.href.split("#")[0]);
			getShareSignaTure({
				url: urlStr,
			})
				.then((res) => {
					if (res.code == 200) {
						this.appId = res.data.appId;
						this.timestamp = res.data.timesTamp;
						this.nonceStr = res.data.nonceStr;
						this.signature = res.data.signaTure;

						wx.config({
							//注意appId,timestamp,nonceStr,signature四个参数，就是把当前url作为参数请求接口，是接口返回生成的
							debug: false, //生产环境需要关闭debug模式
							appId: this.appId, //appId通过微信服务号后台查看
							timestamp: this.timestamp, //生成签名的时间戳
							nonceStr: this.nonceStr, //生成签名的随机字符串
							signature: this.signature, //签名
							jsApiList: [
								"updateAppMessageShareData",
								"updateTimelineShareData",
							], //需要调用的JS接口列表
						});
						//分享参数，标题，描述，链接，图片title,desc,link,imgUrl
						wx.ready(() => {
							wx.updateAppMessageShareData({
								title: this.sharetitle,
								desc: this.sharedescs,
								link: this.wxsharelink,
								imgUrl: this.shareimg,
								success: function () {
									// 设置成功
									//alert("分享朋友圈设置成功")
								},
							});
							wx.updateTimelineShareData({
								title: this.sharetitle,
								desc: this.sharedescs,
								link: this.wxsharelink,
								imgUrl: this.shareimg,
								success: function () {
									// 设置成功
									//alert(desc)
								},
							});
						});
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		toggleContent() {
			this.isCollapsed = !this.isCollapsed;
		},
		share(id) {
			let msg = "https://web.metaera.hk/activity/" + id;
			clipboard.copy(msg);
			this.$message({ message: "复制成功", type: "success" });
		},
		stateClick(item, type) {
			this.state = type;
		},
		typeClick(item, type) {
			this.type = type;
		},
		formatTime(timestamp) {
			const date = new Date(timestamp);
			let hours = date.getHours();
			const minutes = date.getMinutes();
			const ampm = hours >= 12 ? "PM" : "AM";
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			const strMinutes = minutes < 10 ? "0" + minutes : minutes;
			return hours + ":" + strMinutes + " " + ampm;
		},
		copyText(msg) {
			clipboard.copy(msg);
			this.$message({ message: "复制成功", type: "success" });
		},
		hideInfo() {
			this.hoverHostIndex = 9999;
			this.hoverCoHostIndex = 9999;
			this.layerStyle = {};
		},
		showInfo(index) {
			this.hoverCoHostIndex = 9999;
			this.hoverHostIndex = index;
			this.$nextTick(() => {
				const peopleList = this.$refs.peopleList_host;
				const listItem = this.$refs.listItem_host[index];

				const layer = listItem.querySelector(
					".ac_detail_ticket_people_list_item"
				);

				const peopleListRect = peopleList.getBoundingClientRect();
				const layerRect = layer.getBoundingClientRect();
				console.log(layerRect.right);
				console.log(peopleListRect.right);

				if (layerRect.right + 300 > this.windowWidth) {
					let overflowWidth = this.windowWidth - peopleListRect.right;
					// overflowWidth = -20;
					console.log(overflowWidth);
					this.layerStyle = {
						right: `-${overflowWidth}px`,
						left: "auto",
					};
				} else {
					this.layerStyle = {
						left: "0",
						right: "auto",
					};
				}
			});
		},
		toggleInfo(index) {
			this.hoverCoHostIndex = 9999;
			this.hoverHostIndex = index;
		},
		coShowInfo(index) {
			this.hoverHostIndex = 9999;
			this.hoverCoHostIndex = index;
			this.$nextTick(() => {
				const peopleList = this.$refs.peopleList;
				const listItem = this.$refs.listItem[index];

				const layer = listItem.querySelector(
					".ac_detail_ticket_people_list_item"
				);

				const peopleListRect = peopleList.getBoundingClientRect();
				const layerRect = layer.getBoundingClientRect();
				console.log(layerRect.right);
				console.log(peopleListRect.right);

				if (layerRect.right + 200 > peopleListRect.right) {
					const overflowWidth = layerRect.right - peopleListRect.right;

					console.log(overflowWidth);
					this.layerStyle = {
						right: `${overflowWidth}px`,
						left: "auto",
					};
				} else {
					this.layerStyle = {
						left: "0",
						right: "auto",
					};
				}
			});
		},
		coToggleInfo(index) {
			this.hoverHostIndex = 9999;
			this.hoverCoHostIndex = index;
		},
		hostMore() {
			if (this.hostsBool) {
				this.hostsBool = false;
				this.hostsArray = this.realHostsArray.slice(0, 6);
			} else {
				this.hostsBool = true;
				this.hostsArray = this.realHostsArray;
			}
		},
		coHostMore() {
			if (this.coHostsBool) {
				this.coHostsBool = false;
				this.coHostsArray = this.realCoHostsArray.slice(0, 6);
			} else {
				this.coHostsBool = true;
				this.coHostsArray = this.realCoHostsArray;
			}
		},
		checkLogin() {
			if (this.token == 0 || this.token == undefined) {
				return false;
			} else {
				return true;
			}
		},

		handleScroll(event) {},
		showUrl(url) {
			if (url == undefined || url.length == 0) {
				return;
			}
			window.open(url, "");
		},
		getMonthStr(time) {
			const timeDate = new Date(time);

			const monthNames = [
				"JAN",
				"FEB",
				"MAR",
				"APR",
				"MAY",
				"JUN",
				"JUL",
				"AUG",
				"SEP",
				"OCT",
				"NOV",
				"DEC",
			];

			return monthNames[timeDate.getMonth()];
		},
		getDayStr(time) {
			const timeDate = new Date(time);
			return timeDate.getDate();
		},
		goBack() {
			console.log("123123");

			if (window.history.length > 1) {
				this.$router.go(-1);
			} else {
				this.$router.push("/");
			}
		},
		getAcitvityDetail() {
			let obj = { id: this.id };
			acitvityDetail(obj)
				.then((res) => {
					if (res.code == 200) {
						this.loading = false;
						this.item = res.data;
						if (
							this.item.otherSideEventArray &&
							this.item.otherSideEventArray.length > 0
						) {
							this.item.sideeventArray = this.item.otherSideEventArray;
						}
						if (this.item.sideeventArray.length == 0) {
							// 没有独家活动合集和周边活动合集的时候显示右侧相关活动
							this.getActivityList();
						}
						console.log("这里是什么呢", res.data, this.item.sideeventArray);
						this.sharetitle = res.data.title;
						this.sharedescs = res.data.title;
						this.realHostsArray = JSON.parse(res.data.organizer);
						this.realCoHostsArray = JSON.parse(res.data.host);

						if (this.realHostsArray.length > 6) {
							this.hostsArray = this.realHostsArray.slice(0, 6);
						} else {
							this.hostsArray = this.realHostsArray;
						}
						if (this.realCoHostsArray.length > 6) {
							this.coHostsArray = this.realCoHostsArray.slice(0, 6);
						} else {
							this.coHostsArray = this.realCoHostsArray;
						}
						let tmp = JSON.parse(this.item.ticket);

						this.ticketArray = tmp;
						this.ticket_sel_index = 0;
						this.WxSharMenu();
						this.isCollapsed =
							this.item.sideeventArray && this.item.sideeventArray.length > 0;
					} else {
						this.$message.error("获取活动详情失败，请重新获取");
						this.goBack();
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString("zh-CN", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				hour12: false,
			});
		},
		gotoPrivacy() {
			this.$router.push({
				path: "/copyright",
			});
		},
		handleInput(value) {
			// 验证输入的值是否为正整数
			console.log(value);

			const num = Number(value);
			if (!isNaN(num) && Number.isInteger(num) && num > 0) {
				this.count = num.toString();
			} else {
				this.count = "";
			}
		},
		onCheckClick() {
			this.onCheck = this.onCheck == 0 ? 1 : 0;
		},
		showNext() {
			if (this.ticketArray[this.ticket_sel_index].type == 0) {
				this.$router.push({
					path: "/ac_mine",
				});
			}
		},
		next() {
			if (this.ticketArray[this.ticket_sel_index].type == 0) {
				this.freeTicket();
			} else {
				this.showUrl(this.item.url);
				// this.buyTicket();
			}
		},
		ticketClick(index) {
			this.ticket_sel_index = index;
		},
		goBack() {
			if (window.history.length > 1) {
				this.$router.go(-1);
			} else {
				this.$router.push("/");
			}
		},
		async buyTicket() {
			if (this.onCheck != 1) {
				this.$message.error("请阅读并勾选隐私协议");
				return;
			}
			if (this.count < 1) {
				this.$message.error("请正确输入购票数量");
				return;
			}

			let ticketItem = this.ticketArray[this.ticket_sel_index];
			if (ticketItem["type"] == 1 && this.code.length == 0) {
				this.$message.error("请正确输入折扣码");
				return;
			}
			const stripe = await loadStripe(
				"pk_live_51PYrHdEQq0jGyNkS55zMD21yxNNrl2pZU9nlo2IxhgKcbEgVWQGRyjWhB3pEzzgrDwTxohG54aairByrxCqIg32g00gWroSP0b"
			); // 替换为你的Publishable Key
			let obj = {
				id: this.id,
				type: ticketItem["type"],
				count: this.count,
				code: this.code,
				userid: getUserId(),
			};
			this.$loading.show();
			console.log(obj);

			acitvityOrder(obj)
				.then((res) => {
					this.$loading.hide();
					if (res.code == 200) {
						let ticketId = res.data.ticketId;
						if (ticketId) {
							this.$message({ message: "success", type: "success" });
							this.$router.push({
								path: "/ac_mine",
							});
						} else {
							stripe.redirectToCheckout({
								sessionId: res.data.checkout_session,
							});
						}
					} else {
						this.$message.error(res.message);
					}
				})
				.catch((error) => {
					this.$loading.hide();
					this.$message.error(error.message);
				});
		},
		freeTicket() {
			if (this.onCheck != 1) {
				this.$message.error("请阅读并勾选隐私协议");
				return;
			}
			if (this.count < 1) {
				this.$message.error("请正确输入购票数量");
				return;
			}
			let obj = {
				id: this.id,
				type: 0,
				count: this.count,
				userid: getUserId(),
			};
			this.$loading.show();
			acitvityOrder(obj)
				.then((res) => {
					this.$loading.hide();
					if (res.code == 200) {
						this.showSuccess = true;
					} else {
						this.$message.error(res.message);
					}
				})
				.catch((error) => {
					this.$loading.hide();
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.ac-detail-pc {
	height: 100vh;
	overflow-y: scroll;
	background: black;
}
.ac_detail_top {
	margin-top: 100px;
	width: 100%;
	background: url(https://zy.metaera.media/assets/images/active/13.png);
	background-size: cover;
	background-position: center;
	overflow: hidden;
	position: relative; /* 为了让伪元素相对于这个容器定位 */
}
.ac_detail_top img {
	margin-top: 80px;
	margin-bottom: 80px;
	width: 60%;
	margin-left: 20%;
	border-radius: 20px;
}
.ac_banner_box {
	width: 100%;
	background: url(https://zy.metaera.media/assets/images/jianbian.png);
	background-size: cover;
	background-position: 0 0;
	overflow: hidden;
}
.ac_detail_ticket {
	display: flex;
	padding: clamp(50px, calc(100vw * 80 / 1440), 80px) 0;
}

.ac_detail_ticket_left {
	flex-shrink: 0;
	width: clamp(400px, calc(100vw * 58 / 144), 580px);
	height: clamp(200px, calc(100vw * 350 / 144), 350px);
	position: relative;
	border-radius: 20px;
}

.ac_detail_ticket_left img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

.ac_calendar_start_hour {
	top: 10px; /* 距离顶部的距离 */
	left: 10px; /* 距离左边的距离 */
	position: absolute;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
	color: white; /* 字体颜色 */
	border-radius: 7px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_line {
	top: 10px; /* 距离顶部的距离 */
	right: 10px; /* 距离右边的距离 */
	position: absolute;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
	color: white; /* 字体颜色 */
	border-radius: 7px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_line_noStart {
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	color: #0256ff;
}
.ac_calendar_line_end {
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	color: gray;
}

.ac_calendar_line_noStart div {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin-right: 10px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}

.ac_calendar_line_progress {
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	color: #08bf66;
}
.ac_calendar_line_progress div {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin-right: 10px;
	background: #08bf66;
}
.ac_detail_ticket_right {
	flex-grow: 1;
	margin-right: calc(100vw * 34 / 1440);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.ac_detail_ticket_top {
	display: flex;
	align-items: center;
}
.ac_detail_content_online {
	font-size: calc(100vw * 14 / 1440);
	line-height: calc(100vw * 14 / 1440);
	color: #e1e3f1;
	margin-right: 10px;
}
.ac_detail_ticket_sideevent {
	margin-right: 20px;
	height: 30px;
	border-radius: 20px;
	background: white;
	padding-left: 23px;
	padding-right: 23px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	display: inline-flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #333333;
	border: 1px solid #333333;
}
/* 顶部左侧文字样式，注意和筛选结果样式区分 */
.ac_detail_ticket_title {
	margin-top: 5px;
	font-size: clamp(14px, calc(100vw * 60 / 1440), 60px);
	font-weight: 900;
	line-height: clamp(20px, calc(100vw * 70 / 1440), 70px);
	/* 黑色主色（字段一级） */
	color: #fff;

	/* overflow: hidden; */
	/* display: -webkit-box; */
	/* -webkit-line-clamp: 2; */
	/* text-overflow: ellipsis; */
	/* -webkit-box-orient: vertical; */
}

.ac_detail_ticket_specialCode {
	margin-top: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	font-weight: 400;
	line-height: 34px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;

	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}
.ac_detail_ticket_info_item {
	margin-top: calc(100vw * 15 / 1440);
	display: flex;
	/* text-align: center; */
	align-content: center;
	align-items: center;
}
.ac_detail_ticket_info_item span {
	margin-left: clamp(5px, calc(100vw * 10 / 1440), 10px);
	color: #fff;
	font-size: clamp(10px, calc(100vw * 20 / 1440), 20px);
	font-weight: 500;
	line-height: 1.3;
	/* width: 95%; */
	word-break: break-all;
}

.code_box {
	margin-left: 10px;
	padding: 0 calc(100vw * 15 / 1440);
	height: calc(100vw * 27 / 1440);
	line-height: calc(100vw * 27 / 1440);
	border-radius: 6px;
	text-align: center;
	display: inline-block;
	background: rgba(216, 216, 216, 0.1);
}
.code_box span {
	margin-left: 0;
	margin-right: 6px;
}
.ac_detail_ticket_info_item_btn {
	margin-top: calc(100vw * 30 / 1440);
	display: flex;
}
.ac_detail_ticket_info_item_share {
	display: flex;
	align-items: center;
	padding: calc(100vw * 10 / 1440) calc(100vw * 36 / 1440);
	border-radius: calc(100vw * 20 / 1440);
	text-align: center;
	align-content: center;

	border-radius: 20px;
	opacity: 1;
	box-sizing: border-box;
	border: 1.5px solid #ffffff;
	margin-right: 30px;
}

.ac_detail_ticket_info_item_share_people {
	border: none;
	background: rgba(216, 216, 216, 0.2);
}

.ac_detail_ticket_info_item_share_img {
	aspect-ratio: 1 / 1;
	width: clamp(10px, calc(100vw * 20 / 1440), 20px);
	height: clamp(10px, calc(100vw * 20 / 1440), 20px);
	margin-right: 5px;
	background-size: cover;
	background-position: center;
	background-image: url("https://zy.metaera.media/assets/images/active/share_tu.png");
}

.ac_detail_ticket_info_item_share_img_people {
	background-image: url("https://zy.metaera.media/assets/images/home/share_people.png");
}

.ac_detail_ticket_info_item_share_text {
	color: #fff;
	font-size: clamp(10px, calc(100vw * 18 / 1440), 18px);
	line-height: 1;
}

.ac_detail_ticket_info_item_share:hover .ac_detail_ticket_info_item_share_img {
	background-image: url("https://zy.metaera.media/assets/images/home/share_w.png");
}

.ac_detail_ticket_info_item_share:hover
	.ac_detail_ticket_info_item_share_img_people {
	background-image: url("https://zy.metaera.media/assets/images/home/share_people.png");
}

.ac_detail_ticket_info_item_share:hover span {
	color: #ffffff;
}

.ac_detail_ticket_info_item_share:hover {
	background: #a855f7;
	border: 1.5px solid #a855f7;
}

.ac_detail_ticket_info_item_share_people:hover {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border: 1.5px solid #ffffff;
}

.ac_detail_ticket_info_item_time {
	display: block;
}
/* 这个是什么 */
.ac_detail_ticket_info_item_bg {
	text-align: center;
	align-content: center;
	align-items: center;
	height: 40px;
	margin-left: 12px;
	padding-right: 12px;
	background: rgba(125, 156, 247, 0.1);
	border-radius: 10px;
}
.ac_detail_ticket_info_item_bg img {
	margin-left: 10px !important;
	margin-top: -5px;
}

.ac_detail_ticket_info_item_bg span {
	font-family: "Bison-Bold" !important;
	font-size: 24px !important;
	font-weight: 300;
	line-height: 24px !important;
	letter-spacing: 0.1em;
	color: #0256ff !important;
}

.ac_detail_ticket_info_item_bg_copy {
	height: 30px;
}

.ac_detail_ticket_info_item_bg_copy span {
	font-size: 14px !important;
}

.ac_detail_ticket_info_item_bg_copy img {
	width: 12px !important;
	height: 12px !important;
	object-fit: cover;
}

.ac_detail_ticket_info_item img {
	width: 16px;
	height: 16px;
	object-fit: contain;
}

.ac_detail_ticket_info_item_url {
	color: #0256ff !important;
}

.ac_detail_ticket_people {
	border-bottom: 1px solid rgba(216, 216, 216, 0.2);
	justify-content: space-between;
	display: flex;
}

.ac_detail_ticket_people_title {
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ac_detail_ticket_people_item {
	padding-top: calc(100vw * 30 / 1440);
	padding-bottom: calc(100vw * 38 / 1440);
	width: 50%;
	box-sizing: border-box;
}
.host {
	padding-right: calc(100vw * 60 / 1440);
}
.coHost {
	border-left: 1px solid rgba(216, 216, 216, 0.2);
	padding-left: calc(100vw * 55 / 1440);
}
.ac_detail_ticket_people_title_1 {
	font-size: clamp(20px, calc(100vw * 24 / 1440), 24px);
	font-weight: bold;
	line-height: 1;
	/* 黑色主色（字段一级） */
	color: #fff;
	margin-bottom: calc(100vw * 28 / 1440);
}
.ac_detail_ticket_people_title_2 {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	display: none;
	font-variation-settings: "opsz" auto;
	/* 正文色/正文色 */
	color: #1a1a1a;
}

.ac_detail_ticket_people_title_2 img {
	width: 12px;
	height: auto;
}

.ac_detail_ticket_people_list {
	width: 100%;
	position: relative;
	display: flex;
	flex-wrap: wrap; /* 允许换行 */
	row-gap: calc(100vw * 20 / 1440);
	column-gap: calc(100vw * 30 / 1440); /* 设置子项之间的间距 */
}

.host-item-wrapper {
	position: relative; /* 确保浮层相对于这个元素定位 */
}
.ac_detail_ticket_people_list_item {
	position: relative;
	display: inline-block;
	display: flex;
	align-items: center;
	flex-wrap: wrap; /* 允许文本换行 */
	width: clamp(30px, calc(100vw * 51 / 1440), 51px);
	height: clamp(30px, calc(100vw * 51 / 1440), 51px);
	/* margin-top: calc(100vw * 20 / 1440); */
}

.ac_detail_ticket_people_list_item img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
	box-sizing: border-box;
	flex-shrink: 0; /* 防止图像缩小 */
}

.ac_detail_ticket_people_list_item_layer {
	position: absolute;
	top: 100%;
	left: 0;
	background: white;
	padding: 30px;
	border-radius: 20px;
	margin-top: 5px;
	z-index: 99;
	width: 300px;
	/* height: 100px; */
	box-sizing: border-box;
	box-shadow: 0px 4px 46px 0px rgba(73, 73, 73, 0.16);
}

.ac_detail_ticket_people_list_item_layer_img {
	margin-top: 0px;
}
.ac_detail_ticket_people_list_item_layer_img img {
	width: 40px;
	height: 40px;
	border-radius: 20px;
}

.ac_detail_ticket_people_list_item_layer_name {
	margin-top: 23px;
	font-family: PingFang SC;
	font-size: 18px;
	font-weight: normal;
	line-height: 18px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #333333;
}
.ac_detail_ticket_people_list_item_layer_content {
	margin-top: 20px;
	font-family: PingFang SC;
	font-size: 16px;
	font-weight: normal;
	line-height: 24px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #333333;

	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}

.ac_detail_ticket_people_list_item_layer_url {
	display: flex;
	margin-top: 20px;
}

.ac_detail_ticket_people_list_item_layer_url img {
	width: 26px;
	height: 26px;
	margin-right: 30px;
}
.more-wraper {
	position: absolute;
	bottom: 0;
	right: 0;
	/* margin-right: calc(100vw * 5 / 1440); */
}
.more-btn {
	width: calc(100vw * 51 / 1440);
	height: calc(100vw * 50 / 1440);
	border-radius: 50%;
	background: rgba(216, 216, 216, 0.2);
	display: flex;
	align-items: center;
	justify-content: center;
	/* position: absolute; */
	/* right: 0; */
	/* bottom: 0; */
}
.more-btn img {
	width: calc(100vw * 30 / 1440);
	height: calc(100vw * 30 / 1440);
	transform: rotate(90deg);
}
.hide_more img {
	transform: rotate(270deg);
}
.no_cohost {
	font-size: clamp(16px, calc(100vw * 20 / 1440), 20px);
	line-height: clamp(16px, calc(100vw * 20 / 1440), 20px);
	color: #fff;
}
.main-box {
	padding-top: calc(100vw * 3 / 144);
}
.main-left {
	flex-grow: 0;
	border-right: 1px solid rgba(216, 216, 216, 0.2);
}
</style>

<!-- ac_detail_rigister -->
<style scoped>
.ac_detail_rigister_bg {
}
.ac_detail_rigister {
	margin-left: 10%;
	margin-right: 10%;
	margin-bottom: 50px;
	margin-top: 0px;
	width: 80%;
	border-radius: 20px;
	padding-top: 24px;
	padding-bottom: 20px;
	background: rgba(245, 246, 250, 0.5);
	text-align: center;
}
.ac_detail_rigister_title,
.ac_detail_rigister_title span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	font-weight: normal;
	line-height: 20px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}
.ac_detail_rigister_info {
	display: inline-block;
	height: 52px;
	border-radius: 13px;
	align-items: center;
	align-content: center;
	margin-top: 25px;
	margin-bottom: 20px;
	box-sizing: border-box;
	/* 主要色/主要色 */
	border: 1px solid #0256ff;
}
.ac_detail_rigister_info img {
	border-radius: 40px;
	margin-left: 20px;
	width: 36px;
	height: 36px;
	opacity: 1;
	box-sizing: border-box;
	border: 1px solid #e2ebff;
}

.ac_detail_rigister_info span {
	margin-left: 10px;
	margin-right: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}

.ac_detail_rigister_action {
	width: 100%;
	height: 50px;
	text-align: center;
	align-content: center;
	align-items: center;
}

.ac_detail_select_time {
	margin-top: 20px;
	display: inline-flex;
	text-align: center;
	align-content: center;
	align-items: center;
}

.ac_detail_select_time_title {
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.ac_detail_select_date {
	margin-top: 0px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}
.ac_detail_rigister_action span {
	border-radius: 40px;
	opacity: 1;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 10px;
	padding-bottom: 10px;
	/* 主要色/主要色 */
	/* background:linear-gradient(45deg, #9945FF,#8752F3,#5497D5,#43B4CA,#28E0B9,#19FB9B); */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	align-items: center;
	align-content: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
</style>

<!-- ac_detail_login -->
<style scoped>
.ac_detail_login_bg {
	width: 100%;
	margin-left: 0px;
	margin-right: 0px;
}
.ac_detail_login {
	margin-left: 10%;
	margin-right: 10%;
	margin-bottom: 50px;
	margin-top: 0px;
	border-radius: 20px;
	padding-top: 24px;
	padding-bottom: 24px;
	background: rgba(245, 246, 250, 0.5);
	text-align: center;
}
.ac_detail_login_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	font-weight: normal;
	line-height: 25px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}

.ac_detail_login_action {
	width: 100%;
	height: 40px;
	margin-top: 20px;
	text-align: center;
	align-content: center;
	align-items: center;
}

.ac_detail_login span {
	width: 290px;
	border-radius: 40px;
	opacity: 1;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 10px;
	padding-bottom: 10px;
	/* 主要色/主要色 */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);

	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	align-items: center;
	align-content: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
</style>

<!-- ac_detail_overview -->
<style scoped>
.ac_detail_overview {
	/* margin-bottom: 50px; */
}
.ac_detail_header {
	padding-top: calc(100vw * 1 / 144);
	/* padding-bottom: calc(100vw * 4 / 144); */
}
.ac_detail_overview_title_expand {
	align-items: center;
	align-content: center;
}
.expand-icon {
	transform: rotate(90deg);
}
.coll-icon {
	transform: rotate(270deg);
}
.ac_detail_overview_title_expand img {
	width: 24px;
	height: 24px;
	object-fit: cover;
}
.ac_detail_overview_title_new {
	font-size: calc(100vw * 24 / 1440);
	font-weight: bold;
	line-height: 1;
	/* 正文色/正文色 */
	color: #fff;
}

.ac_detail_overview_title {
	font-size: clamp(16px, calc(100vw * 24 / 1440), 24px);
	line-height: 1;
	font-weight: bold;
	/* 纯白 */
	color: #fff;
}
.select-box {
	align-items: end;
	/* position: relative; */
}
.clear {
	font-size: 18px;
	font-weight: bold;
	line-height: 1;
	/* 纯白 */
	color: #ffffff;
	height: 40px;
	line-height: 40px;
}
.collapsed {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.expanded {
	max-height: none; /* 展开时高度不限 */
}
.ac_detail_overview_content {
	margin-top: 0px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 34px;
	letter-spacing: 0em;
	text-align: justify;
	font-variation-settings: "opsz" auto;
	color: #fff;
	margin-left: 0px;
	margin-right: 0px;
	/* padding-left: 30px; */
	/* padding-right: 30px; */
	/* background: #f5f6fa; */
	overflow: hidden;
	transition: max-height 0.3s ease;
}
/* 不是合集的时候不显示 */
.ac_detail_overview_line {
	display: none;
}
/* 合集特殊样式处理以下 */
.ac_detail_side .main-left {
	border: 0;
	flex-grow: 1;
}
.ac_detail_side .ac_detail_header {
	align-items: center;
	height: calc(100vw * 62 / 1440);
	border-radius: 10px;
	background: rgba(79, 79, 79, 0.1);
	padding: 0 calc(100vw * 20 / 1440);
}
.ac_detail_side .ac_detail_overview_content {
	background: rgba(245, 246, 250, 0.1);
	padding: 0 calc(100vw * 20 / 1440);
}
.ac_detail_side .ac_detail_overview_line {
	height: 1px;
	border-radius: 10px;
	opacity: 1;
	box-sizing: border-box;
	border: 1px solid rgba(216, 216, 216, 0.2);
}

/* .ac_detail_side .activities-box,
.ac_detail_side .main-right,
.ac_detail_side .activites-collection {
	display: none;
} */
/* 合集特殊样式处理以上 */
.activites-collection {
	margin-top: clamp(20px, calc(100vw * 30 / 1440), 30px);
	padding-top: clamp(20px, calc(100vw * 30 / 1440), 30px);
	border-top: 1px solid rgba(216, 216, 216, 0.2);
}
.part_title,
.part_title span {
	font-size: clamp(16px, calc(100vw * 24 / 1440), 24px);
	line-height: 1;
	color: #fff;
}
.part_title span {
	padding-bottom: 6px;
	border-bottom: 1px solid rgba(255, 255, 255);
}
</style>
<!-- ac_ticket_select -->
<style scoped>
.ac_ticket_select {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 70px;
	margin-bottom: 40px;
	padding-top: 0px;
}

.ac_ticket_select_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: normal;
	line-height: 28px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #1a1a1a;
	text-decoration: underline;
	text-decoration-color: #0256ff; /* 设置下划线颜色 */
	text-decoration-thickness: 4px; /* 设置下划线高度 */
	text-underline-offset: 10px; /* 调整下划线与文字的距离 */
}

.ac_ticket_select_bg {
	background: white;
	border-radius: 10px;
}

.ac_ticket_select_user {
	display: inline-flex;
	padding-right: 20px;
	margin-top: 35px;
	height: 52px;
	border-radius: 13px;
	/* 正文色/正文色 */
	background: rgba(26, 26, 26, 0.1);
}

.ac_ticket_select_user img {
	margin-left: 13px;
	margin-top: 6px;
	width: 40px;
	height: 40px;
	opacity: 1;
	border-radius: 20px;
}

.ac_ticket_select_user span {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;
	align-content: center;
	align-items: center;
	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
	display: flex;
}
.ac_ticket_list {
	margin-top: 30px;
	display: flex;
}

.ac_ticket_item {
	position: relative; /* 设置相对定位 */
	display: inline-block; /* 使每个项目成为行内块元素 */
	margin-right: clamp(20px, calc(100vw * 40 / 1280), 80px);
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 8px;
	padding-bottom: 8px;
	border-radius: 14px;
	border: 1px solid rgba(26, 26, 26, 0);
}

.ac_ticket_item_type {
	position: absolute; /* 设置绝对定位 */
	width: 12vw;
	bottom: 55%; /* 将文字放置在图片的底部 */
	left: 8%; /* 将文字水平居中 */
	color: white; /* 设置文字颜色 */
	padding: 5px 10px; /* 设置内边距 */
	border-radius: 5px; /* 设置圆角 */
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 14 / 1280), 20px);
	display: -webkit-box; /* 使用 Webkit 的盒模型 */
	-webkit-box-orient: vertical; /* 垂直排列子元素 */
	-webkit-line-clamp: 2; /* 限制最多显示两行 */
	overflow: hidden; /* 隐藏超出容器的内容 */
	text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
	white-space: normal; /* 允许换行 */
}

.ac_ticket_item_name {
	position: absolute; /* 设置绝对定位 */
	width: 12vw;
	bottom: 20%; /* 将文字放置在图片的底部 */
	left: 8%; /* 将文字水平居中 */
	color: white; /* 设置文字颜色 */
	padding: 5px 10px; /* 设置内边距 */
	border-radius: 5px; /* 设置圆角 */
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 12 / 1280), 20px);
	display: -webkit-box; /* 使用 Webkit 的盒模型 */
	-webkit-box-orient: vertical; /* 垂直排列子元素 */
	-webkit-line-clamp: 2; /* 限制最多显示两行 */
	overflow: hidden; /* 隐藏超出容器的内容 */
	text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
	white-space: normal; /* 允许换行 */
}

.ac_ticket_item_sel {
	border: 1px solid #336bf6;
}
.ac_ticket_item img {
	display: block; /* 确保图片是块级元素 */
	height: clamp(80px, calc(100vw * 80 / 1280), 140px);
	width: auto;
}

.ac_ticket_option {
	margin-top: 50px;
	display: flex;
	justify-content: center;
}

.ac_ticket_option_item {
	margin-left: 25px;
	margin-right: 25px;
	width: 320px;
	height: 44px;
	border-radius: 40px;
	opacity: 1;

	/* 主要色/主要色 */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);

	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	align-items: center;
	align-content: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #ffffff;
	display: grid;
}

.ac_ticket_select_num {
	display: flex;
	margin-top: 30px;
}
.ac_ticket_select_item {
	margin-right: 50px;
}
.ac_ticket_select_item_text {
	margin-bottom: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #333333;
}

.ac_ticket_select_time {
	margin-top: 40px;
}

.ac_ticket_select_time_title {
	font-family: PingFang SC;
	font-size: 18px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.ac_ticket_select_date {
	margin-top: 20px;
	font-family: PingFang SC;
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.ac_ticket_select_price {
	margin-top: 50px;
}
.ac_ticket_select_price_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: 600;
	line-height: 18px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #333333;
}

.ac_ticket_select_price_amount {
	margin-top: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 50px;
	font-weight: 500;
	line-height: 50px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 主要色/主要色 */
	color: #0256ff;
}

.ac_ticket_select_check {
	margin-top: 20px;
}

.ac_ticket_select_check img {
	width: 14px;
	height: 14px;
}
.ac_ticket_select_check_text {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	font-size: 12px;
	color: #333333;
}

.ac_ticket_select_check_text_item {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	font-size: 12px;
	font-variation-settings: "opsz" auto;
	color: #336bf6;
}

.ac_ticket_space_bottom {
	height: 110px;
}
</style>

<style scoped>
@media screen and (max-width: 900px) {
	.ac_detail_top {
		margin-top: 40px;
		width: 100%;
	}

	.ac_ticket_select_title {
		font-size: 18px;
		line-height: 18px;
	}

	.ac_detail_top img {
		margin-top: 40px;
		margin-bottom: 40px;
		width: 80%;
		margin-left: 10%;
		border-radius: 20px;
	}

	.ac_ticket_content {
		width: 90%;
		margin-left: 5%;
		margin-top: 20px;
	}

	.ac_ticket_content_box1 {
		display: block;
		margin-top: 10px;
		align-items: center;
	}

	.ac_ticket_overview_content {
		margin-top: 20px;
		border-radius: 10px;
		background: rgba(216, 216, 216, 0.3);
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 0px;
		padding-bottom: 10px;
	}

	.ac_ticket_overview_content_item {
		font-size: 12px;
		line-height: 14px;
	}
	.ac_ticket_select_bg {
		margin-top: 30px;
		padding-top: 1px;
		padding-bottom: 18px;
		padding-left: 18px;
		padding-right: 18px;
	}
	.ac_ticket_select {
		margin-left: 18px;
		margin-right: 18px;
		margin-top: 20px;
	}

	.ac_ticket_select_user {
		display: inline-flex;
		margin-top: 20px;
		height: 40px;
		border-radius: 6px;
	}

	.ac_ticket_select_user img {
		margin-left: 10px;
		margin-top: 5px;
		width: 30px;
		height: 30px;
	}

	.ac_ticket_list {
		margin-top: 20px;
		display: block;
	}

	.ac_ticket_select_price {
		margin-top: 30px;
	}
	.ac_ticket_space_bottom {
		height: 40px;
	}
	.ac_ticket_option {
		margin-top: 20px;
		display: flex;
		justify-content: center;
	}

	.ac_ticket_option_item {
		height: 32px;
		font-size: 14px;
		line-height: 14px;
	}
	.ac_ticket_item img {
		display: block; /* 确保图片是块级元素 */
		height: auto;
		width: 100%;
	}

	.ac_ticket_item_type {
		position: absolute; /* 设置绝对定位 */
		width: 60vw;
		bottom: 55%; /* 将文字放置在图片的底部 */
		left: 8%; /* 将文字水平居中 */
		color: white; /* 设置文字颜色 */
		padding: 5px 10px; /* 设置内边距 */
		border-radius: 5px; /* 设置圆角 */
		font-size: clamp(10px, calc(100vw * 18 / 414), 20px);
	}

	.ac_ticket_item_name {
		position: absolute; /* 设置绝对定位 */
		width: 60vw;
		bottom: 20%; /* 将文字放置在图片的底部 */
		left: 8%; /* 将文字水平居中 */
		color: white; /* 设置文字颜色 */
		padding: 5px 10px; /* 设置内边距 */
		border-radius: 5px; /* 设置圆角 */
		font-size: clamp(10px, calc(100vw * 16 / 414), 20px);
	}
}
</style>
<!-- ac_detail_filter -->
<style scoped>
.ac_detail_filter {
	border-top: 1px solid rgba(216, 216, 216, 0.2);
	margin-top: clamp(20px, calc(100vw * 40 / 1440), 40px);
	padding-top: clamp(20px, calc(100vw * 30 / 1440), 30px);
	margin-bottom: 100px;
}

.ac_detail_filter_content {
	padding-bottom: clamp(20px, calc(100vw * 30 / 1440), 30px);
	margin-top: clamp(20px, calc(100vw * 30 / 1440), 30px);
	box-sizing: border-box;
	border-bottom: 1px solid rgba(216, 216, 216, 0.2);
}

.ac_detail_filter_state,
.ac_detail_filter_type {
	display: flex;
}
.ac_detail_filter_type {
	margin-top: clamp(20px, calc(100vw * 30 / 1440), 30px);
}
.ac_detail_filter_title {
	margin-right: clamp(10px, calc(100vw * 30 / 1440), 30px);
	font-size: clamp(16px, calc(100vw * 20 / 1440), 20px);
	font-weight: bold;
	line-height: 1;
	color: #fff;
}
.ac_detail_filter_item_content .ac_detail_filter_title {
	font-weight: 500;
	line-height: 1.5;
}
.ac_detail_filter_title_sel {
	line-height: 1 !important;
	padding: calc(100vw * 6 / 1440) calc(100vw * 16 / 1440);
	border-radius: 10px;
	opacity: 1;
	background: #333333;
	color: white;
}

.ac_detail_filter_maintitle {
	flex-shrink: 0;
}
.ac_detail_filter_item_content {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.ac_detail_side_content {
	margin-top: calc(100vw * 40 / 1440);
}

.ac_calendar_aclist_item_side {
	margin-top: 15px;
	display: inline-flex;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 20px;
	opacity: 1;
	box-sizing: border-box;
	border: 1px solid #ffffff;
}

.ac_calendar_aclist_item_side_left,
.ac_calendar_aclist_item_side_right {
	font-size: clamp(10px, calc(100vw * 12 / 1280), 14px);
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff !important;
}

.ac_calendar_aclist_item_side_left {
	/* width: 60%; */
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	margin-right: 10px;
}

.ac_calendar_aclist_item_side_right {
	/* width: 40%; */
}
</style>
<style>
.ac_detail_overview_content_pc .ql-editor {
	background: rgba(0, 0, 0, 0) !important;
}
</style>
