<template>
	<div>
		<LeftNav :currentPath="'collect'"></LeftNav>

		<div class="mywz_publish margin_auto justify_content_sb mine_content_right">
			<div class="mywz_publish_header">
				<div class="mywz_publish_header_title">我的收藏</div>
			</div>
			<div style="height: 20px"></div>
			<div class="mywz_publish_line"></div>
			<div style="height: 20px"></div>

			<div v-if="mywzList.length > 0">
				<div v-for="(mywzItem, mywzIndex) in mywzList" :key="mywzIndex">
					<mywzItem
						:item="mywzItem"
						@editItem="editItem"
						@delItem="delItem"
					></mywzItem>
				</div>
			</div>
			<div
				v-if="hasData && mywzList.length == 0"
				class="mywz_publish_list_empty"
			>
				<div class="mywz_publish_list_empty_icon">
					<img
						src="https://zy.metaera.media/assets/images/active/64.png"
						alt=""
					/>
				</div>
				<div class="mywz_publish_list_empty_text">
					暂无收藏,快去添加你的收藏吧~
				</div>
				<div class="mywz_publish_list_empty_action" @click="gotoPubish()"
					>浏览文章
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getCategoryList, delInformation } from "@/api/article";
import { myCollection } from "@/api/user";
import mywzItem from "./collect_item.vue";
import { newBus } from "@/components/pc/topHeader2.vue";

import { getUserId } from "@/utils/auth";
import { getToken } from "@/utils/auth";
import LeftNav from "../leftNav";
export default {
	name: "",
	components: {
		mywzItem,
		LeftNav,
	},
	data() {
		return {
			mywzList: [],
			hasData: false,
			localLanguage: 1,
		};
	},
	created() {
		const hasToken = getToken();
		console.log(hasToken);
		if (hasToken) {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
			this.myCollection();
		} else {
			this.$router.push({ path: "/" });
		}
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		gotoPubish() {
			this.$router.push({
				path: "/",
			});
		},

		myCollection() {
			myCollection({
				id: getUserId(),
			})
				.then((res) => {
					if (res.code == 200) {
						this.hasData = true;
						this.mywzList = res.data.list;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>
<style scoped>
@import url("../../../assets/person.css");
.mywz_publish {
	border-radius: 20px;
	opacity: 1;
	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 30px;
	padding-bottom: 30px;
}

.mywz_publish_header {
	display: flex;
	justify-content: space-between;
}
.mywz_publish_header_title {
	font-size: 24px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #fff;
	display: inline-block;
	align-content: center;
	align-items: center;
	text-align: center;
}
.mywz_publish_header_acticon {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #fff;
	display: inline-block;
	align-content: center;
	align-items: center;
	text-align: center;
	border-radius: 10px;

	width: 120px;
	height: 40px;
	background: rgba(239, 245, 254, 0.1);
}

.mywz_publish_tips {
	font-size: 14px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #999999;
	margin-top: 10px;
	margin-bottom: 10px;
}
.mywz_publish_line {
	width: 100%;
	height: 1px;
	opacity: 0.2;
	background: #d8d8d8;
}
.mywz_publish_status {
	height: 41px;
	align-items: center;
	margin-top: 20px;
}
.mywz_publish_status_title,
.mywz_publish_category_title {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #fff;
	width: 60px;
}

.mywz_publish_status_item,
.mywz_publish_category_item,
.mywz_publish_status_item_active,
.mywz_publish_category_item_active {
	font-size: 14px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #fff;
	margin-right: 10px;
	margin-right: 10px;
	height: 33px;
	border-radius: 10px;
	padding-left: 10px;
	padding-right: 10px;
	display: inline-block;
	align-content: center;
	align-items: center;
	text-align: center;
}
.mywz_publish_status_item_active,
.mywz_publish_category_item_active {
	background: rgba(239, 245, 254, 0.1);
}
.mywz_publish_category {
	margin-top: 10px;
	margin-bottom: 20px;
	height: 41px;
	align-items: center;
}
.mywz_publish_list_header {
	height: 41px;
	align-items: center;
}
.mywz_publish_list_count {
	align-items: center;
	margin-left: 16px;
}
.mywz_publish_list_count span {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #fff;
}
.mywz_publish_list_empty {
	width: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}
.mywz_publish_list_empty_icon {
	width: 100px;
	height: 100px;
	margin-top: 64px;
	object-fit: cover;
}
.mywz_publish_list_empty_icon img {
	width: 100%;
	height: 100%;
}
.mywz_publish_list_empty_text {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #fff;
	margin-top: 36px;
}
.mywz_publish_list_empty_action {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 6px 6px 6px 6px;
	opacity: 1;
	color: white;
	margin-top: 36px;
	width: 133px;
	height: 44px;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: row;
	cursor: pointer;
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	align-items: center;
}
</style>
