<template>
	<div>
		<div class="dexBox">
			<div class="width_1200 margin_auto height_100 flex justify_contentd">
				<div class="flex justify_content_sb align_items_c rzBox">
					<div>文章 (1050)</div>
					<img
						src="https://zy.metaera.media/assets/images/dex/132.png"
						@click="copyText"
						alt=""
					/>
				</div>
			</div>
		</div>
		<div
			class="Main width_1200 margin_auto flex justify_content_sb"
			style="padding-bottom: 20px"
		>
			<div class="MainLeft">
				<div class="news flex direction_column justify_content_c align_items_c">
					<div class="headImg">
						<img :src="userInfo.avatar_img" alt="" class="headImgMain" />
					</div>
					<div class="flex align_items_e titlebs">
						<div>{{ userInfo.nickname }}</div>
						<!-- <img src="https://zy.metaera.media/assets/images/wz/474.png" alt="" /> -->
					</div>
					<div class="flex align_items_c btnlist">
						<div
							class="gzBtn flex align_items_c justify_content_c pointer"
							v-if="userInfo.is_follow == 0"
							@click="followAuthorFun($route.params.id, 1)"
						>
							+关注
						</div>
						<div
							class="gzBtn flex align_items_c justify_content_c pointer"
							v-if="userInfo.is_follow == 1"
							@click="followAuthorFun($route.params.id, 0)"
						>
							已关注
						</div>
						<div class="sxBtn flex align_items_c justify_content_c"> 私信 </div>
					</div>
					<div class="sjList flex align_items_c justify_content_c">
						<div
							class="sjItem flex align_items_c justify_content_c direction_column"
						>
							<div>{{ userInfo.follow_num }}</div>
							<div>关注</div>
						</div>
						<div
							class="sjItem flex align_items_c justify_content_c direction_column"
						>
							<div>{{ userInfo.fans_num }}</div>
							<div>粉丝</div>
						</div>
						<div
							class="sjItem flex align_items_c justify_content_c direction_column"
						>
							<div>{{ userInfo.visits_num }}</div>
							<div>浏览数</div>
						</div>
					</div>
				</div>
				<div class="jieshao">
					<div>介绍</div>
					<div>
						{{ userInfo.desc }}
					</div>
				</div>
				<div class="resouBox">
					<resou></resou>
				</div>
			</div>
			<div class="MainRight">
				<div class="MainRight_head flex align_items_c"> TA的文章 </div>
				<div class="wzNum"> 共计{{ UserInformation.length }}篇 </div>
				<div class="dexBoxm" v-if="UserInformation.length > 0">
					<div
						class="wzitem flex align_items_c pointer"
						v-for="(item, index) in UserInformation"
						:key="index"
					>
						<router-link
							target="_blank"
							:to="{ name: '/gw_detail', params: { id: item.id } }"
						>
							<img :src="item.pic" :key="item.pic" alt="" />
							<div class="wzitemL">
								<div class="wzitemLTitle">
									<div
										style="color: #000000; font-weight: bold"
										class="ellipsis_one"
										>{{ item.title }}</div
									>
									<div>{{ item.illustrate }}</div>
								</div>
								<div class="wzitemLTime flex align_items_c justify_content_sb">
									<div class="wzitemLTime_l">{{ item.update_date }}</div>
								</div>
							</div>
						</router-link>
					</div>
				</div>
				<!-- <noData v-else></noData> -->
			</div>
		</div>
	</div>
</template>

<script>
import { toUserInfo, getUserInformation } from "@/api/home";
import { myMixin } from "@/utils/mixin.js";
import clipboard from "clipboard";
export default {
	mixins: [myMixin],
	name: "",
	data() {
		return {
			userInfo: {},
			UserInformation: [],
		};
	},
	created() {
		this.gettoUserInfo(this.$route.params.id);
		this.getUserInformation(this.$route.params.id);
	},
	methods: {
		copyText() {
			let msg = "https://metaera.com.hk/home_wz?id=" + this.$route.params.id;
			clipboard.copy(msg);
			this.$message({ message: "复制成功", type: "success" });
		},
		gettoUserInfo(id) {
			toUserInfo({ id: id })
				.then((res) => {
					if (res.code == 200) {
						this.userInfo = res.data;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getUserInformation(id) {
			getUserInformation({ id: id })
				.then((res) => {
					if (res.code == 200) {
						this.UserInformation = res.data.list;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.Main {
	padding-top: 20px;
}

.MainLeft {
	width: 370px;
}
.dexBox {
	width: 100%;
	height: 180px;
	background-image: url("https://zy.metaera.media/assets/images/rz/1013.png");
	background-size: 100% 100%;
}
.rzBox {
	width: 770px;
	margin-top: 76px;
}
.rzBox div {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
}
.rzBox img {
	width: 34px;
}
.news {
	position: relative;
	top: -120px;
	width: 100%;
	height: 338px;
	background: #fafafa;
	border-radius: 20px 20px 20px 20px;
	opacity: 1;
}

.news img {
	width: 91px;
	height: 91px;
	border-radius: 50px;
}

.titlebs {
	margin-top: 5px;
}

.titlebs img {
	width: 17px;
	height: 17px;
}

.titlebs div {
	font-size: 28px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000;
}

.gzBtn {
	width: 70px;
	height: 30px;
	background: linear-gradient(136deg, #8a11ff 0%, #176dff 100%);
	border-radius: 15px 15px 15px 15px;
	opacity: 1;
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
}

.btnlist {
	margin-top: 31px;
}

.sxBtn {
	width: 52px;
	height: 30px;
	background: rgba(255, 255, 255, 0);
	border-radius: 15px 15px 15px 15px;
	opacity: 1;
	border: 1px solid #5daeff;
	margin-left: 12px;
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #5daeff;
}

.sjList {
	width: 100%;
	margin-top: 26px;
}

.sjItem {
	width: 33%;
	height: 38px;
}

.sjItem div:nth-child(1) {
	font-size: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000;
}

.sjItem div:nth-child(2) {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #909099;
}

.jieshao {
	height: 147px;
	background: #fafafa;
	border-radius: 20px 20px 20px 20px;
	opacity: 1;
	position: relative;
	top: -100px;
	padding: 31px 0 0 30px;
}
.jieshao div:nth-child(1) {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}
.jieshao div:nth-child(2) {
	width: 300px;
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #676767;
	line-height: 20px;
	margin-top: 16px;
}
.resouBox {
	position: relative;
	top: -100px;
}
.MainRight {
	width: 770px;
}
.MainRight_head {
	width: 100%;
	height: 28px;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	border-bottom: 1px solid #e1e1e1;
	padding-bottom: 12px;
}
.wzNum {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #767680;
	margin-top: 15px;
}
.dexBoxm {
	margin-top: 20px;
}
.headImg {
	width: 142px;
	background-image: url("https://zy.metaera.media/assets/images/wz/1111.png");
	background-size: 100%;
	height: 113px;
	background-repeat: no-repeat;
}

.headImg img {
	width: 88px;
	height: 88px;
	margin-left: 4px;
	margin-top: 5px;
}
/* .headImg2{
        background-image: url("https://zy.metaera.media/assets/images/wz/1.png");
        
    } */
.headImgMain {
	position: relative;
	top: 9px;
	left: 23px;
	width: 100px;
	height: 100px;
	border-radius: 100px;
}
</style>
<style>
.dexBoxm .wzitem {
	padding-bottom: 20px;
	border-bottom: 1px solid #efefef;
}
.dexBoxm .wzitem:nth-last-child(1) {
	border-bottom: none;
}
</style>
