<template>
	<div class="commendBoxPC">
		<div class="zbtj flex align_items_c justify_content_sb">
			<div class="zbjtTitle"> 主编推荐</div>
		</div>
		<div class="commendBoxPC_content">
			<div class="commendBoxPC_top">
				<router-link
					target="_blank"
					:to="{ name: '/gw_detail', params: { id: zbtjList[0].id } }"
				>
					<div class="commendBoxPC_top_img">
						<img :src="zbtjList[0].pic" alt="" />
					</div>
					<div class="commendBoxPC_top_content">
						<div class="commendBoxPC_top_content1">
							<div>
								<span class="commendBoxPC_top_title pointer hovercolor">
									{{
										this.localLanguage == 2
											? zbtjList[0].title
												? zbtjList[0].title
												: zbtjList[0].title
											: zbtjList[0].title
									}}
								</span>
							</div>
							<div>
								<span class="commendBoxPC_top_desc">
									{{
										this.localLanguage == 2
											? zbtjList[0].illustrate_en
												? zbtjList[0].illustrate_en
												: zbtjList[0].illustrate
											: zbtjList[0].illustrate
									}}
								</span>
							</div>
						</div>

						<div class="commendBoxPC_top_content2">
							<div class="commendBoxPC_top_Left_line"></div>
							<div class="commendBoxPC_top_Left_author"
								>{{ zbtjList[0].author.nickname }}
							</div>
							<div>·</div>
							<div class="commendBoxPC_top_Left_time">
								{{ zbtjList[0].release_time }}
							</div>
						</div>
					</div>
				</router-link>
			</div>
			<ul class="commendBoxPC_bottom">
				<div
					class="commendBoxPC_bottom_item"
					v-for="(item, zbtjListIndex) in zbtjList"
					:key="zbtjListIndex"
					v-show="zbtjListIndex > 0"
				>
					<router-link
						target="_blank"
						:to="{ name: '/gw_detail', params: { id: item.id } }"
					>
						<div class="flex">
							<div class="commendBoxPC_bottom_item_point"> </div>
							<div class="commendBoxPC_bottom_item_title pointer hovercolor">
								{{
									localLanguage == 2
										? item.title
											? item.title
											: item.title
										: item.title
								}}
							</div>
						</div>
						<div class="commendBoxPC_bottom_item_time">
							{{ item.release_time }}
						</div>
					</router-link>
				</div>
			</ul>
		</div>
	</div>
</template>

<script>
import { newBus } from "../../components/pc/topHeader2.vue";
export default {
	props: ["zbtjList"],
	data() {
		return {
			localLanguage: 1,
		};
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		// 跳转标签详情
		toLabel(items) {
			this.$router.push({
				name: "/gw_dex",
				params: { id: items.id },
			});
		},
	},
};
</script>

<style scoped>
.zbtj {
	margin-top: 30px;
}
.zbtj img,
.rightMoreBtn,
.tipPoint {
	display: none;
}
.zbtj div:nth-child(1) {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.tjList {
	margin-top: 17px;
}

.tjItem {
	width: 157px;
	height: 100px;
}

.tjItemLabel div {
	height: 25px;
	padding: 0 9px;
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #000000;
	background: #f5f5f6;
	border-radius: 13px 13px 13px 13px;
	opacity: 1;
	line-height: 25px;
	margin-left: 11px;
}

.tjItemLabel div:nth-child(1) {
	margin-left: 0;
}

.tjItemDet {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	line-height: 24px;
	margin-top: 12px;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.content {
	overflow: hidden;
	text-overflow: ellipsis;
	color: #000;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	line-height: 24px;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.commendBoxPC {
	height: 487px;
}

.commendBoxPC_content {
	margin-top: 12px;
	height: 459px;
	border-radius: 20px;
	opacity: 1;
	background: #ffffff;
	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
}

.commendBoxPC_top {
	display: flex;
	height: 227px;
	width: 100%;
}
.commendBoxPC_top_img {
	margin-top: 29px;
	margin-left: 20px;
	width: 259px;
	height: 160px;
	object-fit: cover;
}
.commendBoxPC_top_img img {
	width: 259px !important;
	height: 160px !important;
	object-fit: cover;
	border-radius: 17px !important;
}

.commendBoxPC_top_content {
	display: flex;
	position: relative;
	height: 150px;
	width: 290px;
	margin-left: 20px;
	margin-top: 33px;
	flex-direction: column;
}
.commendBoxPC_top_content1 {
	position: absolute;
	width: 290px;
	top: 0px;
}
.commendBoxPC_top_content2 {
	position: absolute;
	bottom: 0px;
	display: flex;
	align-items: center;
}
.commendBoxPC_top_title {
	margin-right: 25px;
	margin-top: 4px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.commendBoxPC_top_desc {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;

	color: #656878;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.commendBoxPC_top_Left_line {
	width: 2px;
	height: 14px;
	opacity: 1;
	background: #b6b9cc;
}

.commendBoxPC_top_Left_author,
.commendBoxPC_top_Left_time {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	margin-top: 5px;
	/* 字段三级 */
	color: #b2b2b2;
}
.commendBoxPC_bottom {
	padding-top: 26px;
	padding-top: 20px;
	width: 100%;
	height: 184px;
	flex-wrap: wrap;
	display: flex;
	background: white;
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
	gap: 0px;
}
.commendBoxPC_bottom_item {
	height: 70px;
	opacity: 1;
	width: 280px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.commendBoxPC_bottom_item_point {
	margin-left: 39px;
	margin-top: 8px;
	width: 6px;
	height: 6px;
	opacity: 1;
	/* background: #6974c8; */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 3px;
}

.commendBoxPC_bottom_item_title {
	margin-left: 13px;
	width: 220px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 21px;
	color: #656878;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.commendBoxPC_bottom_item_time {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 13px;
	font-weight: normal;
	line-height: 13px;
	color: #b2b2b2;
	margin-left: 58px;
	margin-top: 10px;
}
</style>
