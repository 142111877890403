<template>
	<div>
		<div
			class="Main width_1200 margin_auto flex justify_content_sb"
			style="padding-bottom: 20px"
		>
			<div class="MainLeft">
				<div class="tageBox flex justify_content_sb">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane label="全部" name="0"> </el-tab-pane>
						<el-tab-pane label="峰会" name="1"></el-tab-pane>
						<el-tab-pane label="系列活动" name="2"></el-tab-pane>
						<el-tab-pane label="沙龙/论坛" name="3"></el-tab-pane>
						<el-tab-pane label="培训" name="4"></el-tab-pane>
						<el-tab-pane label="线上活动" name="5"></el-tab-pane>
					</el-tabs>
					<!-- <img src="https://zy.metaera.media/assets/images/active/4.png" alt=""> -->
				</div>
				<div class="flex justify_content_sb align_items_c">
					<div class="activeNum">共计{{ activityList.length }}场活动</div>
					<!-- <div class="flex align_items_c">
                        <el-dropdown>
                            <span class="el-dropdown-link">
                                全部<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>黄金糕</el-dropdown-item>
                                <el-dropdown-item>狮子头</el-dropdown-item>
                                <el-dropdown-item>螺蛳粉</el-dropdown-item>
                                <el-dropdown-item disabled>双皮奶</el-dropdown-item>
                                <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-dropdown style="margin-left: 35px;">
                            <span class="el-dropdown-link">
                                全部<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>黄金糕</el-dropdown-item>
                                <el-dropdown-item>狮子头</el-dropdown-item>
                                <el-dropdown-item>螺蛳粉</el-dropdown-item>
                                <el-dropdown-item disabled>双皮奶</el-dropdown-item>
                                <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div> -->
				</div>
				<div class="activeList">
					<div
						class="activeItem flex"
						v-for="(item, index) in activityList"
						:key="index"
					>
						<div style="width: 200px">
							<img
								:src="item.pic"
								:key="item.pic"
								alt=""
								class="activeItemImg"
							/>
						</div>

						<div class="activeItemLeft">
							<div class="flex align_items_c">
								<div
									class="activeItemLeftBtn flex align_items_c justify_content_c"
									>报名</div
								>
								<div class="activeItemLeftTitle">{{ item.title }}</div>
							</div>
							<div
								class="activeItemLeftVal ellipsis_multiple"
								style="-webkit-line-clamp: 4"
								v-html="item.content"
							>
							</div>
							<div class="flex justify_content_sb align_items_c">
								<div class="activeBtnLIst flex align_items_c">
									<img
										src="https://zy.metaera.media/assets/images/active/2.png"
										alt=""
									/>
									<div>{{ item.start_time }}</div>
								</div>
								<div class="activeBtnLIst flex align_items_c">
									<img
										src="https://zy.metaera.media/assets/images/active/3.png"
										alt=""
									/>
									<div class="ellipsis_one address"
										>{{ item.province }}{{ item.city }}{{ item.area
										}}{{ item.address }}</div
									>
								</div>
							</div>
							<div
								class="detailBtn flex align_items_c justify_content_c pointer"
								@click="
									$router.push({ name: '/wz_detail', params: { id: item.id } })
								"
							>
								查看详情
							</div>
						</div>
					</div>
					<pagination
						v-show="total > 0"
						:total="total"
						:page.sync="page"
						:limit.sync="size"
						@pagination="getActivityList"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getActivityList } from "@/api/home";
export default {
	name: "",
	data() {
		return {
			activeName: "0",
			activityList: [],
			page: 1,
			size: 25,
			total: "",
		};
	},
	created() {
		this.getActivityList();
	},
	methods: {
		handleClick(val) {
			this.page = 1;
			this.getActivityList();
		},
		async getActivityList() {
			// this.$loading.show()
			await getActivityList({
				type: this.activeName,
				page: this.page,
				size: this.size,
			})
				.then((res) => {
					if (res.code == 200) {
						this.activityList = res.data.list;
						this.total = res.data.total;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
			this.$loading.hide();
		},
	},
};
</script>

<style scoped>
.Main {
	padding-top: 20px;
}

.MainLeft {
	width: 100%;
}

.activeNum {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #a0a0a9;
	margin-top: 10px;
}

.activeList {
	width: 70%;
}

.activeItem {
	padding-bottom: 40px;
	margin-top: 40px;
	border-bottom: 1px solid #f5f5f5;
}

.activeItem:nth-last-child(1) {
	border-bottom: none;
}

.activeItem:nth-child(1) {
	margin-top: 22px;
}

.activeItemImg {
	height: 250px;
	width: 200px;
}

.activeItemLeft {
	margin-left: 40px;
}

.activeItemLeftBtn {
	width: 44px;
	height: 25px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 4px 4px 4px 4px;
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
}

.activeItemLeftTitle {
	font-size: 17px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-left: 6px;
}

.activeItemLeftVal {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #b2b7c4;
	margin-top: 10px;
}

.activeBtnLIst {
	margin-top: 11px;
}

.activeBtnLIst img {
	width: 12px;
	margin-right: 3px;
}

.activeBtnLIst div {
	font-family: DINPro-Medium, DINPro;
	font-weight: 500;
	color: #696e7f;
}
.address {
	width: 150px;
}

.detailBtn {
	width: 321px;
	height: 33px;
	background: #f5f5f7;
	border-radius: 2px 2px 2px 2px;
	opacity: 1;
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #696e7f;
	margin-top: 40px;
}

.tageBox .el-tabs {
	width: 90%;
}

.tageBox img {
	width: 152px;
	height: 40px;
}
</style>
