<template>
	<div class="version2 up_step_wraper">
		<div class="top-part flex padding_top_20 margin_left_20">
			<img
				class="pointer"
				@click="$parent.$parent.showStatus = 1"
				src="https://zy.metaera.media/assets/images/my/arrow_left.svg"
			/>
			<div>
				<p class="main-title">账号升级</p>
				<p class="sub-title">将手机号登录升级为邮箱登录</p>
			</div>
		</div>
		<div class="icon-box flex">
			<template v-for="(item, index) in stepData">
				<div
					class="step-box"
					:class="{
						done: index + 1 < currentStep,
						active: index + 1 == currentStep,
						next: index + 1 > currentStep,
					}"
				>
					<div v-if="index + 1 < currentStep" class="step-icon">
						<img src="https://zy.metaera.media/assets/images/my/right_tu.svg" />
					</div>
					<div class="step-num" v-else>{{ index + 1 }}</div>
					<div class="step-text">{{ item }}</div>
				</div>
				<div
					v-if="index < stepData.length - 1"
					class="step-line flex align_items_c"
				>
					<span></span>
				</div>
			</template>
		</div>
		<div class="up-info-box">
			<div class="js-login">
				<div class="js-login__form">
					<!--   密码登录     -->
					<template v-if="currentStep == 1 && loginType == 1">
						<p class="js-login__phone flex align_items_c">
							<img
								src="https://zy.metaera.media/assets/images/my/phone_tu.svg"
								class="pointer"
							/>
							<input
								type="text"
								placeholder="请输入账号"
								v-model="loginForm.username"
							/>
						</p>
						<p class="js-login__psd margin_top_20">
							<img
								src="https://zy.metaera.media/assets/images/my/pass_tu.png"
								class="pointer"
							/>
							<input
								placeholder="请输入密码"
								type="password"
								v-model="loginForm.password"
							/>
						</p>
						<p class="js-login__info">
							<span
								class="js-login__changePhone pointer"
								@click="changeLoginType(2)"
							>
								验证码登录</span
							>
							<a href="mailto:meta@metaera.hk">
								<span class="js-login__forget pointer">忘记账号？点击申诉</span>
							</a>
						</p>
					</template>
					<template v-if="currentStep == 1 && loginType == 2">
						<p class="js-login__phone flex align_items_c">
							<img
								src="https://zy.metaera.media/assets/images/my/phone_tu.svg"
								class="pointer"
							/>
							<input
								type="text"
								placeholder="请输入手机号"
								v-model="loginForm.username"
							/>
						</p>
						<div class="margin_top_20 flex login_psd_box">
							<p class="js-login__psd flex-shrink">
								<img
									src="https://zy.metaera.media/assets/images/my/yzm_tu.svg"
									class="pointer"
								/>
								<input
									placeholder="请输入验证码"
									type="text"
									v-model="loginForm.code"
								/>
							</p>
							<p class="login_psd_btn flex pointer">
								<span class="login_psd_text" @click="tosendSms" v-show="show">{{
									btnText
								}}</span>
								<span class="login_psd_count" v-show="!show"
									>{{ count }} s</span
								>
							</p>
						</div>
						<p class="js-login__info">
							<span
								class="js-login__changePhone pointer"
								@click="changeLoginType(1)"
							>
								密码登录</span
							>
						</p>
					</template>
					<template v-if="currentStep == 2">
						<p class="js-login__phone flex align_items_c">
							<img
								src="https://zy.metaera.media/assets/images/my/email_tu.png"
								class="pointer"
							/>
							<input
								type="text"
								placeholder="请输入邮箱"
								v-model="loginForm.email"
							/>
						</p>
						<div class="margin_top_20 flex login_psd_box">
							<p class="js-login__psd flex-shrink">
								<img
									src="https://zy.metaera.media/assets/images/my/yzm_tu.svg"
									class="pointer"
								/>
								<input
									placeholder="请输入验证码"
									type="text"
									v-model="loginForm.emailCode"
								/>
							</p>
							<p class="login_psd_btn flex pointer">
								<span
									class="login_psd_text"
									@click="toSendMailSms"
									v-show="show"
									>{{ btnText }}</span
								>
								<span class="login_psd_count" v-show="!show"
									>{{ count }} s</span
								>
							</p>
						</div>
					</template>
					<template v-if="currentStep == 3">
						<p class="js-login__psd margin_top_20">
							<img
								src="https://zy.metaera.media/assets/images/my/pass_tu.png"
								class="pointer"
							/>
							<input
								placeholder="请设置密码"
								type="password"
								v-model="loginForm.new_password1"
							/>
						</p>
						<p class="js-login__psd margin_top_20">
							<img
								src="https://zy.metaera.media/assets/images/my/pass_tu.png"
								class="pointer"
							/>
							<input
								placeholder="请确认密码"
								type="password"
								v-model="loginForm.new_password2"
							/>
						</p>
					</template>
					<button
						v-if="currentStep == 3"
						@click="changeToLast"
						class="lastStep flex align_items_c justify_content_c pointer"
					>
						上一步
					</button>
					<button
						@click="handleLogin"
						class="loginBtn flex align_items_c justify_content_c pointer"
						:class="{
							loginBtn3: currentStep == 3,
							loginBtn2: currentStep == 2,
						}"
					>
						{{ currentStep == 3 ? "完成" : "下一步" }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import "./login_common.css";
import { sendSms, getAppConfig, sendEmailSms } from "@/api/home";
import { login, verifyEmailSms, resetPassword } from "@/api/user";
import {
	getToken,
	setToken,
	removeToken,
	setUserId,
	setUserMobile,
	removeUserMobile,
	removeUserId,
} from "@/utils/auth";
export default {
	data() {
		return {
			loginForm: {
				username: "",
				code: "",
				new_password1: "",
				new_password2: "",
				phone_code: "86",
				email: "",
				emailCode: "",
			},
			passType: "password",
			currentStep: 1,
			allSteps: 3,
			loginType: 1, // 1 是密码登录，2是验证码登录
			stepData: ["手机号验证", "绑定邮箱", "设置密码"],
			localLanguage: 0,
			btnText: "获取验证码",
			show: true,
			timer: null,
			count: "",
		};
	},
	mounted() {
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
			this.btnText = "获取验证码";
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
			this.btnText = "Get Verification Code";
		} else {
			this.localLanguage = 1;
			this.btnText = "获取验证码";
		}
	},
	methods: {
		concatUs() {
			console.log("点击这里申诉");
		},
		changeLoginType(num) {
			this.loginType = num;
		},
		changeToLast() {
			// 回到上一步
			this.currentStep = this.currentStep - 1;
		},
		handleLogin() {
			console.log("判断是第几步", this.currentStep, this.loginType);

			if (this.currentStep == 1) {
				login({
					mobile: this.loginForm.username.trim(),
					password: this.loginForm.password,
					type: "1",
				})
					.then((response) => {
						if (response.code != 200) {
							this.$message.error(response.message);
						} else {
							setToken(response.data.token);
							this.currentStep = 2;
						}
					})
					.catch((error) => {
						this.$message.error(response.message);
					});
			} else if (this.currentStep == 2) {
				verifyEmailSms({
					email: this.loginForm.email,
					code: this.loginForm.emailCode,
				})
					.then((response) => {
						if (response.code != 200) {
							this.$message.error(response.message);
						} else {
							this.currentStep = 3;
						}
					})
					.catch((error) => {
						this.$message.error(error.message);
					});
			} else if (this.currentStep == 3) {
				resetPassword({
					new_password1: this.loginForm.new_password1,
					new_password2: this.loginForm.new_password2,
				}).then((response) => {
					if (response.code != 200) {
						this.$message.error(response.message);
						return;
					} else {
						this.$parent.$parent.showStatus = 1;
					}
				});
			}
		},

		toSendMailSms() {
			sendEmailSms({
				email: this.loginForm.email,
			})
				.then((res) => {
					if (res.code == 200) {
						this.show = false;
						const TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.show = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					} else {
						this.$message.error(res.message);
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},

		tosendSms() {
			sendSms({
				phone: this.loginForm.username,
				phone_code: this.loginForm.phone_code,
			})
				.then((res) => {
					if (res.code == 200) {
						this.show = false;
						const TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.show = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					} else {
						this.$message.error(res.message);
					}
				})
				.catch((error) => {
					console.log(error);
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.up_step_wraper {
	padding: 0 0 44px 0;
	min-height: 460px;

	.top-part {
		color: #333;

		img {
			margin-right: 15px;
		}

		.main-title {
			font-size: 24px;
			font-weight: bold;
			line-height: 24px;
		}

		.sub-title {
			font-size: 16px;
			line-height: 16px;
			margin-top: 10px;
		}
	}
	.js-login__form {
		margin-top: 36px;
	}

	.icon-box {
		margin-top: 40px;
		padding: 0 32px;
	}

	.step-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;
	}

	.step-line {
		width: 30px;
		height: 30px;

		span {
			width: 100%;
			height: 1px;
			background: #d8d8d8;
		}
	}

	.step-icon {
		width: 30px;
		height: 30px;
		line-height: 30px;
		border-radius: 50%;
		text-align: center;
	}

	.step-text {
		font-size: 16px;
		line-height: 16px;
		margin-top: 10px;
	}

	.step-num {
		width: 30px;
		height: 30px;
		line-height: 30px;
		border-radius: 50%;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		text-align: center;
	}

	.active .step-num {
		background: #333;
	}

	.active .step-text {
		color: #333;
	}

	.next .step-num {
		background: #d8d8d8;
	}

	.next .step-text {
		color: #d8d8d8;
	}

	.done .step-text {
		color: #0dea60;
	}

	.step-icon,
	.done + .step-line span {
		background: #0dea60;
	}
	.loginBtn {
		color: #fff;
	}
	.loginBtn2,
	.lastStep {
		margin-top: 40px;
	}
	.lastStep {
		width: 100%;
		height: 50px;
		line-height: 50px;
		text-align: center;
		border-radius: 4px;
		border: 1px solid #000;
		color: #333;
		font-size: 16px;
		font-weight: 600;
		background: #fff;
	}
	.loginBtn3 {
		margin-top: 15px;
	}
}
</style>
