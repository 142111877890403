<template>
	<div class="">
		<indexPc class="kuai_wz_pc" v-if="!isMobileDevice"></indexPc>
		<div
			v-else
			class="kuai_wz_mobile Main width_1200 margin_auto flex justify_content_sb"
			style="padding-bottom: 20px"
		>
			<div class="MainLeft" v-if="informationDetails.content != null">
				<div class="wz_detail_pc">
					<div class="wz_detail_pc_content">
						<div
							class="wz_detail_pc_content_title"
							:prohibitTran="informationDetails.prohibit_tran"
						>
							{{
								localLanguage == 2
									? informationDetails.title
										? informationDetails.title
										: informationDetails.title
									: informationDetails.title
							}}
						</div>
						<div class="wz_detail_pc_content_info flex align_items_c">
							<div
								v-if="
									informationDetails.author != null &&
									informationDetails.author.avatar_img != null
								"
							>
								<img
									:src="informationDetails.author.avatar_img"
									alt=""
									class="wz_detail_pc_content_img"
								/>
							</div>
							<div
								v-if="
									informationDetails.author != null &&
									informationDetails.author.nickname != null
								"
								>{{ informationDetails.author.nickname }}
							</div>
							<div>·</div>
							<div v-if="informationDetails.release_time != null">
								{{ informationDetails.release_time }}
							</div>
							<div v-if="informationDetails.source_type > 1">·</div>
							<div>{{
								informationDetails.source_type == 2 ||
								informationDetails.source_type == 10
									? "律动BlockBeats"
									: informationDetails.source_type == 3
									? "Odaily星球日报"
									: informationDetails.source_type == 4
									? "PANews"
									: informationDetails.source_type == 6
									? "Followin"
									: informationDetails.source_type == 7
									? "吴说区块链"
									: informationDetails.source_type == 11
									? "深潮TechFlow"
									: ""
							}}</div>
						</div>
						<div class="wz_detail_pc_content_main">
							<div class="wz_detail_pc_content_left">
								<div
									class="flex align_items_c justify_content_c fx pointer wz_detail_pc_content_zan"
									@click="collectionInformation()"
								>
									<img
										class="wz_detail_pc_content_left_zan"
										src="https://zy.metaera.media/assets/images/kxwz/kxwz_zan_2.png"
										alt=""
										v-if="this.informationDetails.is_collection == 0"
									/>
									<img
										class="wz_detail_pc_content_left_zan"
										src="https://zy.metaera.media/assets/images/kxwz/kxwz_zan1_2.png"
										alt=""
										v-if="this.informationDetails.is_collection == 1"
									/>
								</div>
								<div
									class="flex align_items_c justify_content_c fx pointer wz_detail_pc_content_line"
								>
								</div>
								<div
									class="flex align_items_c justify_content_c fx pointer wz_detail_pc_content_wechat"
									@click="copyText(informationDetails.id)"
									@mouseenter="showLayer"
									@mouseleave="hideLayer"
								>
									<img
										src="https://zy.metaera.media/assets/images/kxwz/kxwz_wechat.png"
										alt=""
									/>
								</div>

								<div v-if="isHover" class="wz_detail_pc_content_wechat_code">
									<div class="wz_detail_pc_content_wechat_code_img">
										<canvas ref="canvas"></canvas>
									</div>
									<div class="wz_detail_pc_content_wechat_code_title"
										>微信扫码</div
									>
								</div>
								<div
									class="flex align_items_c justify_content_c fx pointer wz_detail_pc_content_twitter"
									@click="twitterShare(informationDetails.id)"
								>
									<img
										src="https://zy.metaera.media/assets/images/active/36.png"
										alt=""
									/>
								</div>
								<div
									class="flex align_items_c justify_content_c fx pointer wz_detail_pc_content_copy"
									@click="copyText(informationDetails.id)"
								>
									<img
										src="https://zy.metaera.media/assets/images/home/686.png"
										alt=""
									/>
								</div>
							</div>
							<div class="kx_detail_pc_content_right">
								<div class="wz_detail_pc_content_txt ql-snow">
									<p
										class="ql-editor"
										:prohibitTran="informationDetails.prohibit_tran"
										v-html="
											localLanguage == 2
												? informationDetails.content
													? informationDetails.content
													: informationDetails.content
												: informationDetails.content
										"
									></p>
								</div>
								<div class="kxItem_img" v-if="informationDetails.pic != null">
									<img :src="informationDetails.pic" alt="" />
								</div>
								<div class="wz_detail_pc_content_tag flex">
									<div
										class="MainLeft_6_item pointer align_items_c"
										v-for="(item, index) in informationDetails.label_info"
										:key="index"
										@click="toLabel(item)"
									>
										{{ item.name }}
									</div>
								</div>
								<div
									class="wz_detail_pc_content_tip"
									v-if="informationDetails.id != null"
								>
									声明：本文由入驻MetaEra
									的作者撰写，观点仅代表作者本人，绝不代表MetaEra
									赞同其观点或证实其描述。
									提示：投资有风险，入市须谨慎。本资讯不作为投资理财建议。
								</div>
							</div>
						</div>
					</div>
					<div class="wz_detail_pc_content_bottom">
						<div class="wz_detail_pc_content_bottom_left">
							<div class="wz_detail_pc_content_bottom_left_title">
								主编推荐
							</div>
							<div class="wz_detail_pc_content_bottom_left_content">
								<div
									class=""
									v-for="(items, indexs) in recommendLists"
									:key="indexs"
								>
									<wzitem :item="items"></wzitem>
								</div>
							</div>
						</div>
						<div class="wz_detail_pc_content_bottom_right">
							<div
								class="wz_detail_pc_content_bottom_left_title flex justify_content_sb"
							>
								<div class="wz_detail_pc_content_bottom_left_title"
									>24小时热搜</div
								>
								<div
									class="flex align_items_c pointer"
									@click="$router.push({ path: '/search' })"
								>
									<div class="rsTitleLeft_point"></div>
									<div class="rsTitleLeft_point"></div>
									<div class="rsTitleLeft_point"></div>
								</div>
							</div>
							<div class="wz_detail_pc_content_bottom_right_content">
								<div class="" v-for="(items, indexs) in hotLists" :key="indexs">
									<wzitemsousuo :item="items"></wzitemsousuo>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="wz_detail_app">
					<div class="kxList">
						<div class="kxItem">
							<div
								class="kxItem_1"
								:prohibitTran="informationDetails.prohibit_tran"
								>{{
									localLanguage == 2
										? informationDetails.title
											? informationDetails.title
											: informationDetails.title
										: informationDetails.title
								}}</div
							>
							<div
								class="kxwzDate"
								v-if="informationDetails.release_time != null"
							>
								{{ informationDetails.release_time }}
								{{
									informationDetails.source_type == 2 ||
									informationDetails.source_type == 10
										? " 律动BlockBeats"
										: informationDetails.source_type == 3
										? " Odaily星球日报"
										: informationDetails.source_type == 4
										? " PANews"
										: informationDetails.source_type == 6
										? " Followin"
										: informationDetails.source_type == 7
										? " 吴说区块链"
										: informationDetails.source_type == 11
										? " 深潮TechFlow"
										: ""
								}}
							</div>
							<div class="kxItem_2 ql-snow">
								<p
									class="ql-editor"
									:prohibitTran="informationDetails.prohibit_tran"
									v-html="
										localLanguage == 2
											? informationDetails.content
												? informationDetails.content
												: informationDetails.content
											: informationDetails.content
									"
								></p>
							</div>
							<div
								class="kxItem_3 flex justify_content_sb align_items_c"
								v-if="informationDetails.id != null"
							>
								<div class="flex justify_content_sb align_items_c kxstepsBox">
									<div
										class="flex justify_content_sb align_items_c kxstepsBoxLeft"
									>
										<div
											class="flex align_items_c btnitem justify_content_c fx"
											@click="copyText(informationDetails.id)"
										>
											<img
												src="https://zy.metaera.media/assets/images/home/685.png"
												alt=""
											/>
											<div>链接分享</div>
										</div>
										<div
											class="flex align_items_c btnitem justify_content_c fx"
											@click="showPreView(informationDetails.url)"
										>
											<img
												src="https://zy.metaera.media/assets/images/home/686.png"
												alt=""
											/>
											<div>原文链接</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="MainLeft_tuijian">
						<div class="MainLeft_tuijian_title">24小时热文</div>
						<m_othresou></m_othresou>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	getInformationDetails,
	collectionInformation,
	cancelCollectionInformation,
} from "@/api/home";
import { homeHotList, getRecommendLists } from "@/api/home";
import start from "@/components/scare.vue";
import { myMixin } from "@/utils/mixin.js";
import { getShareSignaTure } from "@/api/user";
import clipboard from "clipboard";
import { getToken } from "@/utils/auth";
import wzitemsousuo from "./../../components/pc/wzitemsousuo.vue";
import wzitem from "./../../components/pc/wzitem.vue";
import QRCode from "qrcode";
import { newBus } from "../../components/pc/topHeader2.vue";
import ChineseConv from "chinese-s2t";
import indexPc from "./index_pc.vue";
import wx from "weixin-js-sdk";

export default {
	mixins: [myMixin],
	name: "",
	data() {
		return {
			informationDetails: {},
			text: "",
			id: "",
			isHover: false,
			hotLists: [],
			recommendLists: [],
			localLanguage: 1,
			sharetitle:
				this.$route.query.title == null ? "MetaEra" : this.$route.query.title,
			sharedescs:
				"MetaEra是Web3行业的品牌与增长专家，为客户提供创意型解决方案。MetaEra在全球各地区利用全方位优势资源，为您的品牌管理和业务增长提供定制化服务。",
			wxsharelink: location.href,
			shareimg: "https://zy.metaera.media/logo_share.png",
		};
	},
	components: {
		start,
		wzitemsousuo,
		wzitem,
		indexPc,
	},
	created() {
		this.id = this.$route.params.id;
		this.getInformationDetails(this.id);
		this.$router.beforeEach((to, from, next) => {
			if (to.name == "/kuaixun_wz" && this.id != to.query.id) {
				this.id = to.query.id;
				this.informationDetails = {};
				this.getInformationDetails(this.id);
			}
			next();
		});

		// 在 Vue.js 中监听路由变化
		this.$router.afterEach((to, from) => {
			// 更新页面的 <meta> 标签
			this.sharetitle = to.query.title;
			document
				.querySelector('meta[property="og:title"]')
				.setAttribute(
					"content",
					to.query.title == null ? "MetaEra" : to.query.title
				);
			document
				.querySelector('meta[property="og:description"]')
				.setAttribute(
					"content",
					to.query.title == null ? "MetaEra" : to.query.title
				);

			// 触发 Twitter Cards 更新
			if (typeof twttr !== "undefined") {
				twttr.widgets.load();
			}
		});
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	computed: {
		isMobileDevice() {
			return this.$store.state.app.isMobileDevice;
		},
	},
	metaInfo() {
		let descStr = "";
		if (this.informationDetails && this.informationDetails.content) {
			descStr = this.informationDetails.illustrate
				? this.informationDetails.illustrate
				: this.informationDetails.content
						.replace(/<[^>]*>/g, "")
						.slice(0, 2000);
		}

		return {
			title: this.informationDetails.title,
			meta: [
				{
					name: "keywords",
					content:
						"Web3.0,DeFi,MetaEra,blockchain,DePIN,MetaEra,区块链媒体,BTC,比特币,blockchain Media,以太坊,Web3快讯,香港ETF,币圈,Web3资讯,区块链服务,Bitcoin News,区块链是什么,Ethereum News,区块链应用,Crypto News,区块链学习,区块链钱包,区块链活动,GameFi,区块链专栏,meme,区块链游戏,区块链投资,web3钱包,区块链赚钱,公链,链游,区块链投研,USDT,区块链知识,区块链入门",
				},
				{ name: "description", content: descStr },
				{
					property: "og:description",
					content: descStr,
				},
				{
					property: "og:type",
					content: "article",
				},
				{
					property: "og:site_name",
					content: this.informationDetails.title,
				},
				{ property: "og:title", content: this.informationDetails.title },
				{
					property: "twitter:description",
					content: descStr,
				},
				{
					property: "og:image",
					content:
						this.informationDetails.pic != null &&
						this.informationDetails.pic.lenght > 0
							? this.informationDetails.pic
							: "https://zy.metaera.media/logo_share.png",
				},
				{
					property: "twitter:image",
					content:
						this.informationDetails.pic != null &&
						this.informationDetails.pic.lenght > 0
							? this.informationDetails.pic
							: "https://zy.metaera.media/logo_share.png",
				},
				{ property: "twitter:title", content: this.informationDetails.title },
			],
		};
	},
	methods: {
		toLabel(items) {
			this.$router.push({
				path: "/gw_dex",
				query: { id: items.id },
			});
		},
		updateMetaTags() {
			document.title = this.informationDetails.title;

			const metaKeywords = document.querySelector('meta[name="keywords"]');
			if (metaKeywords) {
				metaKeywords.content =
					"Web3.0,DeFi,MetaEra,blockchain,DePIN,MetaEra,区块链媒体,BTC,比特币,blockchain Media,以太坊,Web3快讯,香港ETF,币圈,Web3资讯,区块链服务,Bitcoin News,区块链是什么,Ethereum News,区块链应用,Crypto News,区块链学习,区块链钱包,区块链活动,GameFi,区块链专栏,meme,区块链游戏,区块链投资,web3钱包,区块链赚钱,公链,链游,区块链投研,USDT,区块链知识,区块链入门";
			} else {
				const newMetaKeywords = document.createElement("meta");
				newMetaKeywords.setAttribute("name", "keywords");
				newMetaKeywords.setAttribute(
					"content",
					"Web3.0,DeFi,MetaEra,blockchain,DePIN,MetaEra,区块链媒体,BTC,比特币,blockchain Media,以太坊,Web3快讯,香港ETF,币圈,Web3资讯,区块链服务,Bitcoin News,区块链是什么,Ethereum News,区块链应用,Crypto News,区块链学习,区块链钱包,区块链活动,GameFi,区块链专栏,meme,区块链游戏,区块链投资,web3钱包,区块链赚钱,公链,链游,区块链投研,USDT,区块链知识,区块链入门"
				);
				document.head.appendChild(newMetaKeywords);
			}

			const metaDescription = document.querySelector(
				'meta[name="description"]'
			);
			const descStr = this.informationDetails.illustrate
				? this.informationDetails.illustrate
				: this.informationDetails.content
						.replace(/<[^>]*>/g, "")
						.slice(0, 2000);
			if (metaDescription) {
				metaDescription.content = descStr;
			} else {
				const newMetaDescription = document.createElement("meta");
				newMetaDescription.setAttribute("name", "description");
				newMetaDescription.setAttribute("content", descStr);
				document.head.appendChild(newMetaDescription);
			}

			const ogDescription = document.querySelector(
				'meta[property="og:description"]'
			);
			if (ogDescription) {
				ogDescription.content = descStr;
			} else {
				const newOgDescription = document.createElement("meta");
				newOgDescription.setAttribute("property", "og:description");
				newOgDescription.setAttribute("content", descStr);
				document.head.appendChild(newOgDescription);
			}

			const ogType = document.querySelector('meta[property="og:type"]');
			if (ogType) {
				ogType.content = "article";
			} else {
				const newOgType = document.createElement("meta");
				newOgType.setAttribute("property", "og:type");
				newOgType.setAttribute("content", "article");
				document.head.appendChild(newOgType);
			}

			const ogSiteName = document.querySelector(
				'meta[property="og:site_name"]'
			);
			if (ogSiteName) {
				ogSiteName.content = this.informationDetails.title;
			} else {
				const newOgSiteName = document.createElement("meta");
				newOgSiteName.setAttribute("property", "og:site_name");
				newOgSiteName.setAttribute("content", "MetaEra");
				document.head.appendChild(newOgSiteName);
			}

			const ogTitle = document.querySelector('meta[property="og:title"]');
			if (ogTitle) {
				ogTitle.content = this.informationDetails.title;
			} else {
				const newOgTitle = document.createElement("meta");
				newOgTitle.setAttribute("property", "og:title");
				newOgTitle.setAttribute("content", this.informationDetails.title);
				document.head.appendChild(newOgTitle);
			}

			const ogImage = document.querySelector('meta[property="og:image"]');
			if (ogImage) {
				ogImage.content = this.informationDetails.pic
					? this.informationDetails.pic
					: "https://zy.metaera.media/logo_share.png";
			} else {
				const newOgImage = document.createElement("meta");
				newOgImage.setAttribute("property", "og:image");
				newOgImage.setAttribute(
					"content",
					this.informationDetails.pic
						? this.informationDetails.pic
						: "https://zy.metaera.media/logo_share.png"
				);
				document.head.appendChild(newOgImage);
			}

			const twitterDescription = document.querySelector(
				'meta[name="twitter:description"]'
			);
			if (twitterDescription) {
				twitterDescription.content = descStr;
			} else {
				const newTwitterDescription = document.createElement("meta");
				newTwitterDescription.setAttribute("name", "twitter:description");
				newTwitterDescription.setAttribute("content", descStr);
				document.head.appendChild(newTwitterDescription);
			}

			const twitterImage = document.querySelector('meta[name="twitter:image"]');
			if (twitterImage) {
				twitterImage.content = this.informationDetails.pic
					? this.informationDetails.pic
					: "https://zy.metaera.media/logo_share.png";
			} else {
				const newTwitterImage = document.createElement("meta");
				newTwitterImage.setAttribute("name", "twitter:image");
				newTwitterImage.setAttribute(
					"content",
					this.informationDetails.pic
						? this.informationDetails.pic
						: "https://zy.metaera.media/logo_share.png"
				);
				document.head.appendChild(newTwitterImage);
			}

			const twitterTitle = document.querySelector('meta[name="twitter:title"]');
			if (twitterTitle) {
				twitterTitle.content = this.informationDetails.title;
			} else {
				const newTwitterTitle = document.createElement("meta");
				newTwitterTitle.setAttribute("name", "twitter:title");
				newTwitterTitle.setAttribute("content", this.informationDetails.title);
				document.head.appendChild(newTwitterTitle);
			}

			const twitterCard = document.querySelector('meta[name="twitter:card"]');
			if (twitterCard) {
				twitterCard.content = "summary";
			} else {
				const newTwitterCard = document.createElement("meta");
				newTwitterCard.setAttribute("name", "twitter:card");
				newTwitterCard.setAttribute("content", "summary");
				document.head.appendChild(newTwitterCard);
			}
		},
		showLayer() {
			this.isHover = true;
			this.generateQRCode();
		},
		hideLayer() {
			this.isHover = false;
		},
		generateQRCode() {
			this.$nextTick(() => {
				const canvas = this.$refs.canvas;
				// const image = this.$refs.image;
				QRCode.toCanvas(
					canvas,
					"https://www.metaera.hk/kuaixun_wz?id=" + this.id,
					(error) => {
						if (error) console.error(error);
						// 将Canvas内容转换为base64编码的图像数据，并设置为img的src属性
						// image.src = canvas.toDataURL();
					}
				);
			});
		},
		twitterShare(id) {
			var sharetitle = ChineseConv.s2t(this.informationDetails.title);
			window.open(
				"http://twitter.com/share?url=" +
					encodeURIComponent("https://web.metaera.hk/article/" + this.id) +
					"&text=" +
					encodeURIComponent(sharetitle) +
					"&display=popup&ref=plugin&src=share_button",
				"twitter",
				"height=500, width=750, top=" +
					100 +
					", left=" +
					100 +
					", toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
			);
		},
		copyText(id) {
			let msg = "https://web.metaera.hk/article/" + id;
			clipboard.copy(msg);
			this.$message({ message: "复制成功", type: "success" });
		},
		showPreView(url) {
			window.open(url, "");
		},
		homeHotList() {
			homeHotList({})
				.then((res) => {
					if (res.code == 200) {
						this.hotLists = res.data.hotLists;
					} else {
					}
				})
				.catch((error) => {});
		},
		getRecommendLists() {
			getRecommendLists({ page: 1, size: 10 })
				.then((res) => {
					if (res.code == 200) {
						this.recommendLists = res.data.list;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		collectionInformation() {
			const hasToken = getToken();
			if (hasToken) {
				if (this.informationDetails.is_collection == 0) {
					collectionInformation({ information_id: this.$route.params.id })
						.then((res) => {
							if (res.code == 200) {
								this.iscollect = true;
								this.getInformationDetails(this.id);
							}
						})
						.catch((error) => {
							// this.$message.error(error.message);
						});
				} else {
					cancelCollectionInformation({ information_id: this.$route.params.id })
						.then((res) => {
							if (res.code == 200) {
								this.iscollect = false;
								this.getInformationDetails(this.id);
							}
						})
						.catch((error) => {
							// this.$message.error(error.message);
						});
				}
			} else {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			}
		},

		//微信分享方法
		WxSharMenu() {
			this.configWX(this.informationDetails.jsdata);
		},

		configWX(data) {
			this.appId = data.appId;
			this.timestamp = data.timesTamp;
			this.nonceStr = data.nonceStr;
			this.signature = data.signaTure;
			wx.config({
				//注意appId,timestamp,nonceStr,signature四个参数，就是把当前url作为参数请求接口，是接口返回生成的
				debug: false, //生产环境需要关闭debug模式
				appId: this.appId, //appId通过微信服务号后台查看
				timestamp: this.timestamp, //生成签名的时间戳
				nonceStr: this.nonceStr, //生成签名的随机字符串
				signature: this.signature, //签名
				jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], //需要调用的JS接口列表
			});
			//分享参数，标题，描述，链接，图片title,desc,link,imgUrl
			wx.ready(() => {
				wx.updateAppMessageShareData({
					title: this.sharetitle,
					desc: this.sharedescs,
					link: this.wxsharelink,
					imgUrl: this.shareimg,
					success: function () {
						// 设置成功
						//alert("分享朋友圈设置成功")
					},
				});
				wx.updateTimelineShareData({
					title: this.sharetitle,
					desc: this.sharedescs,
					link: this.wxsharelink,
					imgUrl: this.shareimg,
					success: function () {
						// 设置成功
						//alert(desc)
					},
				});
			});
		},

		getInformationDetails(id) {
			getInformationDetails({ id: id, url: this.wxsharelink }).then((res) => {
				if (res.code == 200) {
					this.informationDetails = res.data;
					this.updateMetaTags();
					this.informationDetails.content =
						"<p>" +
						this.informationDetails.content.replace(/\n/g, "</p><p>") +
						"</p>";
					this.getRecommendLists();
					this.homeHotList();
					this.$pvuv.getEvent(
						"page",
						this.informationDetails.id,
						this.informationDetails.title,
						this.informationDetails.author_id
					);

					this.sharetitle = this.informationDetails.title;
					this.sharedescs =
						this.informationDetails.illustrate == null ||
						this.informationDetails.illustrate == undefined ||
						this.informationDetails.illustrate.lenght == 0
							? this.informationDetails.title
							: this.informationDetails.illustrate;
					this.WxSharMenu();
				} else {
					this.$message.error("当前快讯不存在1，已返回到快讯列表");
					// this.goBack();
				}
			});
		},
	},

	goBack() {
		console.log("123123");

		if (window.history.length > 1) {
			this.$router.go(-1);
		} else {
			this.$router.push("/home_kuaixun");
		}
	},
};
</script>

<style scoped>
.kuai_wz_pc {
	display: flex;
}
.kuai_wz_mobile {
	display: none;
}
.Main {
	padding-top: 20px;
}

.MainLeft {
	width: 760px;
}

.kxwzDate {
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #9a9aa7;
}

.kxpj img {
	width: 15.7px;
	margin-left: 12px;
}

.kxItem_1 {
	font-size: 22px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-top: 20px;
}
.kxItem_2 {
	font-size: 18px;
	line-height: 36px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: #323241 !important;
	font-weight: 400;
}
.kxItem_2 p {
	font-size: 18px;
	margin-left: -14px;
	line-height: 36px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: #323241 !important;
	font-weight: 400;
}
.kxItem_2 p span,
.kxItem_2 p a {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #333333;
	margin-top: 18px;
}

.kxItem_3 {
	margin-top: 10px;
}

.btnitem {
	width: 95px;
	height: 23px;
	background: #f5f5f7;
	border-radius: 12px;
	margin-top: 7px;
	font-size: 12px;
	color: #a0a0a8;
	display: flex;
	margin-left: 10px;
}

.btnitem img {
	width: 14px;
	/* height: 18px; */
	margin-right: 6px;
}

.btnitemimg2 {
	height: 20px !important;
	width: auto !important;
}
.btnitem div {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a0a0a8;
}

.kxstepsBox {
	margin-top: 6px;
	margin-bottom: 2px;
	display: flex;
	justify-content: right;
}

.kxstepsBoxLeft {
	width: auto;
}

.kxItem_3 a {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.MainRight {
	width: 360px;
}

.MainRight_1 {
	width: 100%;
	height: 58px;
	background: rgba(255, 255, 255, 0);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #f7f7f7;
}

.MainRight_1 img {
	width: 28px;
	height: 33px;
	margin-left: 17px;
}

.MainRight_1 div {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #333333;
	margin-left: 12px;
}
.btnitemimg1 {
	height: 12px;
}
.kxItem_3 .btnitembottom {
	margin-left: 0px;
	width: 20vw;
}
.kxItem_3 .btnitembottom div {
	margin-left: 10px;
	font-size: 14px;
}
.MainLeft_b {
	margin-top: 20px;
}
.MainLeft_6_item {
	margin-left: 20px;
	padding: 0 21px;
	height: 35px;
	background: #f9f9f9;
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border-radius: 5px;
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #000000;
	line-height: 28px;
	align-items: center;
	display: flex;
}
</style>
<style scoped>
@media screen and (max-width: 900px) {
	.kuai_wz_pc {
		display: none;
	}
	.kuai_wz_mobile {
		display: block;
	}
	.MainRight {
		display: none;
	}
	.MainLeft {
		width: 90%;
		margin: 0 auto;
	}
	.kxItem_1 {
		font-size: 25px;
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 600;
		color: #000000;
		margin-top: 10px;
	}
	.kxItem_2 {
		margin-top: 6px;
	}
	.kxwzDate {
		font-size: 15px;
	}
	.kxItem_3 .btnitembottom {
		margin-left: 0px;
		width: 20vw;
	}
	.ql-editor {
		padding: 0 0;
	}
}
</style>
<style>
.MainLeft_tuijian {
	display: none;
}
.MainLeft_b {
	display: block;
}
.kxItem_img {
	display: none;
	width: 100%;
	height: auto;
}
.kxItem_img img {
	width: 100%;
	height: auto;
}
.ql-editor img {
}
@media screen and (max-width: 900px) {
	.MainLeft_b {
		display: none;
	}
	.MainLeft_tuijian {
		display: block;
		width: 90%;
		/* margin-left: 5%; */
		margin-top: 30px;
		padding: 16px;
		background: #ffffff;
		box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.1);
		border-radius: 20px 20px 20px 20px;
		opacity: 1;
		margin-bottom: 30px;
	}
	.MainLeft_tuijian_title {
		font-size: 16px;
		font-weight: 500;
	}

	.kxItem_2 p {
		font-size: 18px !important;
		margin-left: 0px !important;
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		color: #323241 !important;
		color: #333333;
		font-weight: 400;
	}
	.kxItem_2 p span,
	.kxItem_2 p a {
		font-size: 18px !important;
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;

		color: #333333;
		font-weight: 400;
		color: #333333;
		margin-top: 18px;
	}

	.btnitemimg1 {
		height: 16px !important;
		color: #333333;
		font-weight: 400;
	}
	.MainLeft_6_item {
		width: 100px;
		font-size: 14px;
		height: 24px;
		align-items: center;
		display: flex;
	}
}
</style>
