<template>
	<div class="mine_headerg">
		<div class="mine_left_header_pc flex align_items_c">
			<div
				class="mine_tab_header pointer"
				@click="
					$router.push({
						path: '/',
					})
				"
			>
				<img
					src="https://zy.metaera.media/assets/images/my/mine_back.png"
					alt=""
				/>
				<span>个人中心</span>
			</div>
			<div class="align_items_c justify_content_sb mine_tab_action">
				<div class="navList_first_pd align_items_c">
					<div
						class="mine_tab_actionItem flex pointer"
						v-for="(item, index) in navList"
						:key="index"
						@click="toPath(item, index)"
						:class="{ mine_tab_actionItem_active: currentPath == item.router }"
					>
						<span>{{ item.name }}</span>
					</div>
				</div>
			</div>
			<div class="mine_tab_action_line"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	props: {
		currentPath: {
			type: String,
			default: "/polkadot2024",
		},
	},
	created() {},
	data() {
		return {
			currentIndex: 0,
			isShowList: false,
			currentImage:
				"https://zy.metaera.media/assets/images/event/pd_header_btn.png", // 初始图片
			images: {
				a: "https://zy.metaera.media/assets/images/event/pd_header_btn.png", // 图片A
				b: "https://zy.metaera.media/assets/images/event/pd_header_btn1.png", // 图片B
			},
			navList: [
				{
					name: "用户信息",
					enable: true,
					router: "my",
				},
				{
					name: "我的文章",
					enable: true,
					router: "mywz",
				},
				{
					name: "我的收藏",
					enable: true,
					router: "collect",
				},
				{
					name: "我的关注",
					enable: true,
					router: "follow",
				},
				{
					name: "参与活动",
					enable: false,
					router: "/",
				},
				{
					name: "主办活动",
					enable: true,
					router: "ac_organ",
				},
			],
		};
	},
	methods: {
		toPath(item, index) {
			if (!item.enable) {
				return;
			}
			console.log(index);
			this.currentIndex = index;
			this.currentPath = item.router;
			this.$router.push({ path: item.router });
			this.isShowList = false;
		},
		showList() {
			this.isShowList = true;
		},
		hideList() {
			this.isShowList = false;
		},
		showUrl() {
			window.open("https://lu.ma/hszom0hw", "");
		},
		changeImage(imageKey) {
			this.currentImage = this.images[imageKey];
		},
	},
	watch: {
		"$route.name": function (n) {
			this.showHeader = n == "/";
			this.currentPath = n;
		},
	},
};
</script>

<style scoped>
.mine_left_header_pc {
	display: block;
	height: 100vh;
	top: 100px;
	left: 10%;
	width: 220px;
	margin: 0 auto;
	z-index: 100;
	position: fixed;
	background: rgba(0, 0, 0, 0);
}

.mine_tab_action {
	display: block;
	margin-top: 50px;
}
.mine_tab_actionItem {
	width: auto;
	margin-left: 15px;
	margin-right: 15px;
	margin-top: 30px;
	height: 36px;
	display: block;
	align-content: center;
	align-items: center;
	text-align: center;
	border-radius: 4px;
}

.mine_tab_actionItem_active {
}

.mine_tab_actionItem img {
	margin-left: 10px;
	width: 18px;
	height: 18px;
}

.mine_tab_actionItem span {
	margin-left: 10px;
	font-size: 18px;
	font-weight: bold;
	line-height: 18px;
	letter-spacing: 0em;
	color: #767676;
	opacity: 0.5;
}

.mine_tab_actionItem_active span {
	font-weight: 600;
	opacity: 1;
	color: #fff;
}

.mine_tab_action_line {
	margin-top: 40px;
	margin-left: 80px;
	width: 160px;
	height: 1px;
	opacity: 0.2;
	background: #d8d8d8;
}
</style>
<style scoped>
.mine_tab_header {
	display: flex;
	margin-top: 80px;
}

.mine_tab_header img {
	width: 30px;
	height: 30px;
}

.mine_tab_header span {
	margin-left: 30px;
	font-size: 40px;
	font-weight: normal;
	line-height: 40px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
</style>
