<template>
	<div class="mywzNews">
		<div class="mywzNewsItem">
			<div class="textPart flex">
				<div class="imgPart">
					<router-link
						target="_blank"
						:to="{ name: '/gw_detail', params: { id: item.id } }"
					>
						<img class="mywzNews_pic pointer" :src="item.pic" :key="item.pic" />
					</router-link>
					<div class="mainContent_status">
						<img
							src="https://zy.metaera.media/assets/images/my/mywz_examineing.png"
							v-if="item.examine_type == 2"
						/>
						<img
							src="https://zy.metaera.media/assets/images/my/mywz_nopass.png"
							v-if="item.examine_type == 3"
						/>
						<span v-if="item.examine_type == 1">已上线</span>
						<span v-if="item.examine_type == 2" style="color: #0056ff"
							>审核中</span
						>
						<span v-if="item.examine_type == 3" style="color: #e74b3a"
							>未通过</span
						>
						<span v-if="item.examine_type == 4" style="color: #999999"
							>草稿</span
						>
					</div>
				</div>

				<div class="mainContent">
					<router-link
						target="_blank"
						:to="{ name: '/gw_detail', params: { id: item.id } }"
					>
						<div class="mainContent_title pointer">{{ item.title }} </div>
					</router-link>
					<router-link
						target="_blank"
						:to="{ name: '/gw_detail', params: { id: item.id } }"
					>
						<div class="mainContent_illustrate pointer"
							>{{ item.illustrate }}
						</div>
					</router-link>
					<div class="mainContent_timePart flex justify_content_sb">
						<div
							class="mainContent_time"
							:class="{
								mainContent_time_none:
									item.examine_type == 3 || item.examine_type == 4,
							}"
						>
							{{ item.release_time }}
						</div>
						<div class="mainContent_edit flex">
							<div
								class="pointer"
								v-if="item.examine_type == 3 || item.examine_type == 4"
								@click="editItem()"
								>编辑</div
							>
							<div class="pointer" @click="delItem()">删除</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["item"],
	methods: {
		editItem() {
			this.$emit("editItem", this.item.id);
		},
		delItem() {
			this.$confirm("此操作将删除该条专栏, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$emit("delItem", this.item.id);
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					});
				});
		},
	},
};
</script>

<style scoped>
.mywzNewsItem {
	display: flex;
	justify-content: space-between;
	padding-bottom: 33px;
	padding-top: 33px;
}
.textPart {
	width: 100%;
}
.mywzNews_pic {
	width: 160px;
	height: 90px;
	object-fit: cover;
	border-radius: 18px;
}
.mainContent {
	margin-left: 40px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.mainContent_title {
	width: 100%;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
	text-transform: capitalize;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	/* position: absolute; */
	top: 0px;
}
.mainContent_illustrate {
	margin-top: 14px;
	font-size: 14px;
	font-weight: normal;
	line-height: 20px;
	text-transform: capitalize;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 描边颜色 */
	color: rgba(225, 227, 241, 0.7);
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.mainContent_title:hover {
	color: #0056ff;
}
.mainContent_timePart {
	bottom: 0px;
}
.mainContent_edit div {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #999999;
	width: 90px;
	display: flex;
	justify-content: right;
}
.mainContent_time {
	font-size: 14px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #8e8e8e;
}
.mainContent_time_none {
	width: 400px;
}
.mainContent_status {
	margin-top: 10px;
	margin-bottom: 6px;
	padding-bottom: 6px;
	align-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 20px;
	border-radius: 3px;
	/* 主色渐变 */
	background: linear-gradient(
		82deg,
		#9945ff 0%,
		#8752f3 24%,
		#5497d5 46%,
		#43b4ca 56%,
		#28e0b9 69%,
		#19fb9b 96%
	);
}
.mainContent_status img {
	width: 15px;
	height: 15px;
}
.mainContent_status span {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	margin-left: 8px;
	color: #ffffff;
}
</style>
