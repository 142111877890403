<template>
	<div
		class="Main pc_wraper padding_120 margin_auto"
		style="padding-bottom: 20px"
	>
		<div class="tools_menu" v-for="(item, index) in toolsList" :key="index">
			<div class="tools_menu_header flex" v-if="item.subList.length > 0">
				<span class="tools_menu_header_title">{{
					localLanguage == 2
						? item.cate.name_en
							? item.cate.name_en
							: item.cate.name
						: item.cate.name
				}}</span>
			</div>
			<div class="tools_menu_body" v-if="item.subList.length > 0">
				<div
					class="tools_item pointer"
					v-for="(subItem, subIndex) in item.subList"
					:key="subIndex"
					@click="gotoUrl(subItem.url)"
				>
					<div class="tools_menu_image">
						<img :src="subItem.pic" />
					</div>
					<div class="tools_menu_text">
						<span class="tools_menu_title">{{
							localLanguage == 2
								? subItem.title
									? subItem.title
									: subItem.title
								: subItem.title
						}}</span>
						<span class="tools_menu_subtitle">{{
							localLanguage == 2
								? subItem.illustrate_en
									? subItem.illustrate_en
									: subItem.illustrate
								: subItem.illustrate
						}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { toolsList } from "@/api/home";
import { newBus } from "../../components/pc/topHeader2.vue";
export default {
	name: "",
	data() {
		return {
			toolsList: [],
			localLanguage: 1,
		};
	},
	created() {
		this.getToolsList();
	},
	methods: {
		gotoUrl(url) {
			window.open(url);
		},
		getToolsList() {
			let res = JSON.parse(sessionStorage.getItem("toolsList1Data"));
			if (res != null && res.code == 200) {
				this.toolsList = res.data;
			}
			toolsList()
				.then((res) => {
					if (res.code == 200) {
						sessionStorage.setItem("toolsList1Data", JSON.stringify(res));
						this.toolsList = res.data;
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
};
</script>

<style scoped>
.pc_wraper {
	background: transparent;
}
.tools_menu {
	width: 100%;
}

.tools_menu_header {
	width: 100%;
	height: 55px;
	/* background: red; */
	text-align: center;
	display: flex;
	align-items: center;
}
.tools_menu_body {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	margin-bottom: 10px;
	margin-top: 10px;
}

.tools_item {
	height: 66px;
	background: #ffffff;
	border-radius: 10px 10px 10px 10px;
	opacity: 1;
	/* width: calc(20% - 20px); */
	width: 215px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
}
.tools_menu_text {
	margin-left: 10px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.tools_menu_image img {
	width: 42px;
	height: 42px;
	border-radius: 8px;
	margin-left: 12px;
	border: 1px solid #ededed;
}

.tools_menu_title {
	width: 130px;
	height: 20px;
	font-size: 14px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #000000;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.tools_menu_subtitle {
	width: 130px;
	height: 17px;
	font-size: 13px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #999999;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.tools_menu_line {
	width: 3px;
	height: 18px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 2px 2px 2px 2px;
	opacity: 0.85;
}
.tools_menu_header_title {
	font-size: 18px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #fff;
	/* margin-left: 10px; */
}

@media screen and (max-width: 900px) {
	.Main {
		margin-left: 0;
		margin-right: 0;
	}
	.tools_menu_header_title {
		color: #333;
	}
	.tools_item {
		height: 66px;
		background: #ffffff;
		border-radius: 10px 10px 10px 10px;
		opacity: 1;
		border: 1px solid rgba(0, 0, 0, 0.14);
		width: calc(50% - 15px); /* 20% width with 20px gap */
		box-sizing: border-box;
		display: flex;
		align-items: center;
	}
	.tools_menu_line {
		margin-left: 10px;
	}
	.tools_menu_body {
		margin-left: 10px;
	}
	.tools_menu_title {
		width: calc(50vw - 100px);
	}
	.tools_menu_subtitle {
		width: calc(50vw - 100px);
	}
}
</style>
