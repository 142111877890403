import Vue from "vue";
import Router from "vue-router";
// import Layout from "@/pages/layout/layout"
Vue.use(Router);

const scrollBehavior = (to, from, savedPosition) => {
	if (savedPosition && to.meta.keepAlive) {
		return savedPosition;
	}
	return { left: 0, top: 0 };
};
const router = new Router({
	mode: "history",
	routes: [
		{
			path: "/",
			component: () => import("@/pages/home/index.vue"),
			meta: {
				bgColor: "color_bg_home",
			},
		},
		{
			path: "/copyright",
			component: () => import("@/pages/copyright.vue"),
		},
		{
			path: "/home",
			component: () => import("@/pages/home/index.vue"),
			meta: {
				bgColor: "color_bg_home",
			},
		},
		{
			path: "/home_xiaobai",
			component: () => import("@/pages/home_xiaobai/index.vue"),
			// yes 暂无
		},
		{
			path: "/home_wz",
			component: () => import("@/pages/home_wz/index.vue"),
			// yes  接口 作者主页
		},
		{
			path: "/home_wz/:id",
			name: "/home_wz",
			component: () => import("@/pages/home_wz/index.vue"),
			// yes  接口 作者主页
		},
		{
			path: "/home_zt",
			component: () => import("@/pages/home_zt/index.vue"),
			// yes 接口 专题
		},
		{
			path: "/home_chanye",
			component: () => import("@/pages/home_chanye/index.vue"),
			// yes 接口 产业
		},
		{
			path: "/home_tools",
			component: () => import("@/pages/home_tools/index.vue"),
			// yes 接口  工具  无接口
		},
		{
			path: "/tool",
			component: () => import("@/pages/tool/index.vue"),
			// yes 接口  工具  无接口
		},
		{
			path: "/home_kuaixun",
			component: () => import("@/pages/home_kuaixun/index.vue"),
		},
		{
			path: "/home_active",
			component: () => import("@/pages/home_active/index.vue"),
			// yes  接口 活动
		},
		{
			path: "/hot_topics",
			name: "/hot_topics",
			component: () => import("@/pages/hotTopic/index.vue"),
			meta: {
				bgColor: "color_bg_home",
			},
			// 专题页面
		},
		{
			path: "/gw_dex",
			component: () => import("@/pages/gw_dex/index.vue"),
			// yes 接口 标签详情
		},
		{
			path: "/gw_dex/:id",
			name: "/gw_dex",
			component: () => import("@/pages/gw_dex/index.vue"),
			// yes 接口 标签详情
		},
		{
			path: "/home_eightclock",
			component: () => import("@/pages/home_eightclock/index.vue"),
			// yes 接口 新闻早班车
		},
		{
			path: "/gw_ruzhu",
			component: () => import("@/pages/gw_ruzhu/index.vue"),
			// yes  // 接口 个人主页
		},
		{
			path: "/gw_shijian",
			component: () => import("@/pages/gw_shijian/index.vue"),
			// 接口 今日大事件
		},
		{
			path: "/home_shequn",
			component: () => import("@/pages/home_shequn/index.vue"),
			// yes     // 接口 社群
		},
		{
			path: "/home_hz",
			component: () => import("@/pages/home_hz/index.vue"),
			// yes  接口 投融资
		},
		{
			path: "/kuaixun_wz",
			component: () => import("@/pages/kuaixun_wz/index.vue"),
			// yes 需确认  接口 投融资详情
		},
		{
			path: "/kuaixun_wz/:id",
			name: "/kuaixun_wz",
			component: () => import("@/pages/kuaixun_wz/index.vue"),
			// yes 需确认  接口 投融资详情
		},
		{
			path: "/home_jszk",
			component: () => import("@/pages/home_jszk/index.vue"),
			// yes 接口 专题详情
		},
		{
			path: "/home_jszk/:id",
			name: "/home_jszk",
			component: () => import("@/pages/home_jszk/index.vue"),
			// yes 接口 专题详情
		},
		{
			path: "/home_week",
			component: () => import("@/pages/home_week/index.vue"),
			// yes 接口 每周要闻
		},
		{
			path: "/hk",
			component: () => import("@/pages/hk/newindex.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: false,
			},
			// yes 接口 每周要闻
		},
		{
			path: "/usa",
			component: () => import("@/pages/usa/newindex.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: false,
			},

			// yes 接口 每周要闻
		},
		{
			path: "/atlas",
			component: () => import("@/pages/hk/atlas.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: false,
			},
			// yes 接口 每周要闻
		},
		{
			path: "/notice",
			component: () => import("@/pages/notice/index.vue"),
			// yes 接口 通知详情
		},
		{
			path: "/aboutus",
			component: () => import("@/pages/aboutus/index.vue"),
		},
		{
			path: "/en",
			component: () => import("@/pages/aboutus/en.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "/gw_search",
			component: () => import("@/pages/gw_search/index.vue"),
			// yes  接口
		},
		{
			path: "/gw_search/:key",
			name: "/gw_search",
			component: () => import("@/pages/gw_search/index.vue"),
			// yes  接口
		},
		{
			path: "/search",
			component: () => import("@/pages/search/index.vue"),
			// yes  接口
		},
		{
			path: "/search/:keyword",
			name: "/search",
			component: () => import("@/pages/search/index.vue"),
			// yes  接口
		},
		{
			path: "/gw_detail/:id",
			name: "/gw_detail",
			component: () => import("@/pages/kuaixun_wz/index.vue"),
			meta: {
				title: "",
				keywords: "",
				description: "",
			},
		},
		{
			path: "/gw_detail",
			component: () => import("@/pages/kuaixun_wz/index.vue"),
			meta: {
				title: "",
				keywords: "",
				description: "",
			},
			// yes 接口  文章详情
		},
		{
			path: "/wz_detail",
			component: () => import("@/pages/wz_detail/index.vue"),
			// yes  接口 活动详情
		},
		{
			path: "/wz_detail/:id",
			name: "/wz_detail",
			component: () => import("@/pages/wz_detail/index.vue"),
			// yes  接口 活动详情
		},
		{
			path: "/home_zl",
			component: () => import("@/pages/home_zl/index.vue"),
			//专栏  接口
		},
		{
			path: "/zl_list",
			component: () => import("@/pages/zl_list/index.vue"),
			//专栏列表  接口
		},
		{
			path: "/signUp",
			component: () => import("@/pages/signUp/index.vue"),
		},

		{
			path: "/my",
			component: () => import("@/pages/my/index.vue"),
		},
		{
			path: "/collect",
			component: () => import("@/pages/my/collect/collect.vue"),
		},
		{
			path: "/follow",
			component: () => import("@/pages/my/follow/follow.vue"),
		},
		{
			path: "/publish",
			component: () => import("@/pages/my/mywz/publish.vue"),
		},
		{
			path: "/mywz",
			component: () => import("@/pages/my/mywz/index.vue"),
		},
		{
			path: "/feed_back",
			component: () => import("@/pages/feed_back.vue"),
		},
		{
			path: "/fuwutiaokuan",
			component: () => import("@/pages/protocol/fuwutiaokuan.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "/mianzeshengming",
			component: () => import("@/pages/protocol/mianzeshengming.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "/download",
			component: () => import("@/pages/protocol/download.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
				hideDownload: true,
			},
		},
		{
			path: "/hk_annualceremony",
			name: "/hk_annualceremony",
			component: () => import("@/pages/activity/hk_annualceremony.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "/hk_annualceremony_result",
			component: () => import("@/pages/activity/hk_annualceremony_result.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "/polkadot2024",
			component: () => import("@/pages/event/polkadot2024.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "/polkadot2024_about",
			component: () => import("@/pages/event/about.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "/polkadot2024_speaker",
			component: () => import("@/pages/event/speaker.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "/polkadot2024_partner",
			component: () => import("@/pages/event/partner.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "/polkadot2024_agenda",
			component: () => import("@/pages/event/agenda.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "/activity",
			component: () => import("@/pages/activity/index.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: false,
			},
		},
		{
			path: "/events",
			component: () => import("@/pages/activity/index.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: false,
			},
		},
		{
			path: "/ac_detail/:id",
			name: "/ac_detail",
			component: () => import("@/pages/activity/ac_detail.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: false,
			},
		},
		{
			path: "/ac_detail",
			component: () => import("@/pages/activity/ac_detail.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: false,
			},
		},
		{
			path: "/ac_create",
			component: () => import("@/pages/activity/ac_create.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "/ac_create/:id",
			name: "/ac_create",
			component: () => import("@/pages/activity/ac_create.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "/ac_organ",
			component: () => import("@/pages/activity/ac_organ.vue"),
			meta: {
				// hideHeader: true,
				hideFooter: true,
				hideTool: false,
			},
		},
		{
			path: "/ac_mine",
			component: () => import("@/pages/activity/ac_mine.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: false,
			},
		},
		{
			path: "/ac_ticket",
			component: () => import("@/pages/activity/ac_detail.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: false,
			},
		},
		{
			path: "/third",
			component: () => import("@/pages/third/index.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: false,
			},
		},
		{
			path: "/CryptoFi",
			component: () => import("@/pages/hkforum/index.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "/DataEra",
			component: () => import("@/pages/dataera/btc_org.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
				bgColor: "color_bg_top0",
			},
		},
		{
			path: "/DataEra/:type",
			name: "/DataEra",
			component: () => import("@/pages/dataera/btc_org.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
				bgColor: "color_bg_top0",
			},
		},

		{
			path: "/forum_2025",
			component: () => import("@/pages/hkforum/forum_2025.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "/forum_2024",
			component: () => import("@/pages/hkforum/forum_2024.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "/forum_2023",
			component: () => import("@/pages/hkforum/forum_2023.vue"),
			meta: {
				hideHeader: true,
				hideFooter: true,
				hideTool: true,
			},
		},
		{
			path: "*",
			component: () => import("@/pages/noFound/index.vue"),
		},
	],
});
// 创建路由守卫
router.beforeEach((to, from, next) => {
	const hasQuery = Object.keys(to.query).length > 0; // 检查是否有 query 参数
	if (hasQuery) {
		console.log("有query参数");
		const queryToParams = { ...to.query }; // 将 query 参数转为 params
		router.replace({
			name: to.path, // 保留当前路由的 name
			params: queryToParams, // 将 query 转换为 params
		});
		console.log("有query参数", {
			name: to.path, // 保留当前路由的 name
			params: queryToParams, // 将 query 转换为 params
		});
	} else {
		next(); // 如果没有 query 参数，正常跳转
	}
});
//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};
export function resetRouter() {
	const newRouter = createRouter();
	router.matcher = newRouter.matcher; // reset router
}
export default router;
