import Vue from "vue";
import LoginDialog from "../pages/login/index_new.vue"; // 引入弹窗组件
let PromptConstructor = Vue.extend(LoginDialog);
let instance;
const PromptTip = function (options = {}) {
	instance = new PromptConstructor({
		data: options,
	});
	document.body.appendChild(instance.$mount().$el);
};
export default PromptTip;
