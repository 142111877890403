<template>
	<div class="headerg flex align_items_c">
		<div
			class="header_search align_items_c flex pointer"
			v-if="this.type == '1'"
		>
			<img
				src="https://zy.metaera.media/assets/images/tab/tab_search.png"
				alt=""
			/>
			<input
				type="text"
				@keydown.enter="handleEnterKey"
				v-model="keyword"
				placeholder="Search for the content you want to view…"
			/>
			<div
				class="searchBtn flex align_items_c justify_content_c"
				@click="
					$router.push({
						path: '/search',
					})
				"
			>
			</div>
		</div>
		<div
			class="header_tab_fun_language"
			v-if="this.type == '2'"
			:class="{ header_tab_fun_language_white: this.type == 'white' }"
			@mouseenter="showLanguageNew(true)"
		>
			<div class="header_tab_fun_language_item pointer" @click="showLanguage()">
				{{
					this.localLanguage == 0 ? "简" : this.localLanguage == 1 ? "繁" : "EN"
				}}
			</div>

			<div
				class="header_top_Language_list"
				@mouseenter="showLanguageNew(true)"
				@mouseleave="showLanguageNew(false)"
				:class="{ header_top_Language_list_white: this.type == 'white' }"
				v-if="showSel == 1"
			>
				<div
					class="header_top_Language_list_item pointer"
					@click="selLanguage(0)"
					:class="{ header_top_Language_list_item_white: this.type == 'white' }"
					>简体中文</div
				>
				<div
					class="header_top_Language_list_item pointer"
					@click="selLanguage(1)"
					:class="{ header_top_Language_list_item_white: this.type == 'white' }"
					>繁体中文</div
				>
				<div
					class="header_top_Language_list_item pointer"
					@click="selLanguage(2)"
					:class="{ header_top_Language_list_item_white: this.type == 'white' }"
					>English</div
				>
			</div>
		</div>
		<div class="js-func js-func__app header_phone_img pointer">
			<div class="js-func__item--app_small tab_phone_img"></div>
			<div class="js-func__download">
				<div class="qr">
					<img
						src="https://zy.metaera.media/assets/images/aboutus/download.png"
						alt="MetaEra中国版App下载"
					/>
					<span class="js-func__download_des">Android &amp; iPhone</span>
				</div>
			</div>
		</div>
		<img
			class="tab_noLogin pointer"
			v-if="token == 0 || token == undefined"
			:src="currentIcon"
			alt=""
			@click="login"
			@mouseover="hoverIcon"
			@mouseout="defaultIcon"
		/>
		<div
			class="js-func js-func__user js-userBox margin0 header_mine_img"
			v-else
		>
			<a title="" class="js-func__avatar js-func__avatar_small">
				<img
					class="tab_mine_img"
					v-if="!(token == 0 || token == undefined) && $store.state.user.avatar"
					:src="$store.state.user.avatar"
					alt=""
					@click="login"
				/>
				<img
					class="tab_mine_img"
					v-else
					src="https://zy.metaera.media/assets/images/tab/tab_mine.png"
					alt=""
					@click="login"
				/>
			</a>
			<a class="js-func__userMenu">
				<div class="tab_user_header flex">
					<div
						class="tab_user_header_img"
						@click="$router.push({ path: '/my' })"
					>
						<img
							class="tab_user_img"
							v-if="
								!(token == 0 || token == undefined) && $store.state.user.avatar
							"
							:src="$store.state.user.avatar"
							alt=""
							@click="login"
						/>
						<img
							class="tab_user_img"
							v-else
							src="https://zy.metaera.media/assets/images/tab/tab_mine.png"
							alt=""
							@click="login"
						/>
					</div>
					<div class="tab_user_header_left">
						<div class="tab_user_header_name maincolor">
							{{ $store.state.user.name }}
						</div>
						<div class="tab_user_header_phone">
							{{ $store.state.user.email }}
						</div>
					</div>
				</div>
				<div class="tab_user_publish" @click="gotoMy"> </div>
				<div
					class="tab_user_menu flex"
					style="margin-top: 10px"
					@click="$router.push({ path: '/my' })"
				>
					<div class="tab_user_menu_con"> 用户信息 </div>
				</div>

				<div
					class="tab_user_menu flex"
					@click="$router.push({ path: '/mywz' })"
				>
					<div class="tab_user_menu_con"> 我的文章 </div>
				</div>

				<!-- <div
					class="tab_user_menu flex"
					@click="$router.push({ path: '/ac_mine' })"
				>
					<div class="tab_user_menu_con">活动参与 </div>
				</div> -->

				<div class="tab_user_menu_line flex"> </div>
				<div
					class="tab_user_menu flex"
					@click="$router.push({ path: '/ac_organ' })"
				>
					<div class="tab_user_menu_con">活动主办 </div>
				</div>
				<div class="tab_user_menu_line flex"> </div>
				<div class="tab_user_logout flex" @click="logOut">退出登录</div>
			</a>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import { getToken, getUserMobile } from "@/utils/auth";
import { getMap } from "echarts";
import Vue from "vue";
export const newBus = new Vue();
export default {
	props: {
		type: {
			type: String,
			default: "1",
		},
		language: {
			type: String,
			default: "",
		},
	},
	name: "",
	created() {
		this.token = getToken();
		this.mobile = getUserMobile();
		this.$router.beforeEach((to, from, next) => {
			this.keyword = "";
			next();
		});
	},
	data() {
		return {
			token: 0,
			mobile: "",
			isShowList: false,
			localLanguage: 0,
			showSel: 1,
			keyword: "",
			defaultIconSrc:
				"https://zy.metaera.media/assets/images/tab/tab_mine_1.png",
			hoverIconSrc:
				"https://zy.metaera.media/assets/images/tab/tab_mine_hover_2.png",
			currentIcon: "https://zy.metaera.media/assets/images/tab/tab_mine_1.png",
		};
	},
	methods: {
		showLanguageNew(show) {
			this.showSel = show ? 1 : 0;
		},
		showLanguage() {
			this.showSel = this.showSel == 1 ? 0 : 1;
			console.log(this.showSel);
		},
		selHKLanguage() {
			const index = 1;
			this.showSel = 0;
			this.localLanguage = index;
			sessionStorage.setItem("localLanguageHK", "繁体中文");
			if (this.localLanguage == 0) {
				this.$s2t(document.body);
			} else {
				this.$e2s(document.body, 1);
			}
			// 通知发送
			newBus.$emit("global-notification", {
				localLanguage: this.localLanguage,
			});
		},
		selLanguage(index) {
			this.showSel = 0;
			console.log("selLanguage");
			console.log(index);

			if (index == 0) {
				sessionStorage.setItem("localLanguage", "简体中文");
				if (location.href.indexOf("/hk") != -1) {
					sessionStorage.setItem("localLanguageHK", "简体中文");
				} else {
					sessionStorage.setItem("localLanguageHK", "");
				}

				if (this.localLanguage == 1) {
					this.$t2s(document.body);
				} else {
					this.$e2s(document.body, 0);
				}
			} else if (index == 1) {
				sessionStorage.setItem("localLanguage", "繁体中文");
				if (location.href.indexOf("/hk") != -1) {
					sessionStorage.setItem("localLanguageHK", "繁体中文");
				} else {
					sessionStorage.setItem("localLanguageHK", "");
				}
				if (this.localLanguage == 0) {
					this.$s2t(document.body);
				} else {
					this.$e2s(document.body, 1);
				}
			} else {
				sessionStorage.setItem("localLanguage", "english");
				if (location.href.indexOf("/hk") != -1) {
					sessionStorage.setItem("localLanguageHK", "english");
				} else {
					sessionStorage.setItem("localLanguageHK", "");
				}
				this.$s2e(document.body);
			}
			this.localLanguage = index;
			// 通知发送
			newBus.$emit("global-notification", {
				localLanguage: this.localLanguage,
			});
		},
		hoverIcon() {
			this.currentIcon = this.hoverIconSrc;
		},
		defaultIcon() {
			this.currentIcon = this.defaultIconSrc;
		},
		gotoMy() {
			const hasToken = getToken();
			// console.log(hasToken);
			if (hasToken) {
				this.$router.push({ path: "/publish" });
			} else {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			}
		},
		handleEnterKey() {
			this.$router.push({
				path: "/search",
			});
		},
		login() {
			if (this.token == 0 || this.token == undefined) {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			} else {
				this.$router.push({ path: "/my" });
			}
		},

		logOut() {
			store.dispatch("user/logout");
		},
	},
	watch: {
		"$route.name": function (n) {
			this.showHeader = n == "/";
			this.currentPath = n;
		},
	},
	mounted() {
		this.showSel = 0;
		if (this.type == 2) {
			if (this.language == "tc") {
				this.localLanguage = 1;
			} else if (this.language == "en") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		} else {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		}
	},
};
</script>

<style scoped>
.headerg {
	background: #f7f8f9;
	height: 111px;
}

.header_search {
	position: absolute;
	left: 270px;
	right: 232px;
	top: 31px;
	height: 50px;
	opacity: 1;
	border-radius: 17px;
	box-shadow: 5px 15px 70px 0px rgba(54, 62, 147, 0.1);
}

.header_search img {
	width: 22px;
	height: 22px;
	margin-left: 22px;
}

.header_search input {
	margin-left: 10px;
	margin-right: 20px;
	width: 100%;
	height: 50px;
	border: none;
	background: #f7f8f9;
}

.tab_phone_img {
	width: 40px;
	height: 40px;
	border-radius: 10px;
}

.tab_mine_img,
.tab_noLogin {
	margin-top: 5px;
	width: 30px;
	height: 30px;
	border-radius: 10px;
}

.header_phone_img {
	position: absolute;
	right: 80px;
	top: 26px;
}
.header_mine_img {
	position: absolute;
	right: 40px;
	top: 21px;
}
.tab_noLogin {
	position: absolute;
	right: 40px;
	top: 36px;
}
.tab_user_header_img {
	margin-left: 7px;
	margin-top: 7px;
}
.tab_user_img {
	width: 40px !important;
	height: 40px !important;
	max-width: 40px !important;
	max-height: 40px !important;
	border-radius: 20px;
}
.tab_user_header_left {
	margin-left: 10px;
	margin-top: 7px;
	height: 40px;
}

.tab_user_header_phone {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;
	margin-top: 10px;
	/* 黑色主色（字段一级） */
	color: #767676;
}
.tab_user_header_name {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	letter-spacing: 0em;
	margin-top: 6px;
	/* 描述字段 */
	color: #656878;
}

.tab_user_publish {
	background-image: url("https://zy.metaera.media/assets/images/zl/zl_publish.png");
	background-size: cover;
	margin-top: 20px;
	width: 200px;
	height: 48px;
	margin-left: 7px;
}

.tab_user_menu {
	height: 38px;
}
.tab_user_menu_img {
	margin-left: 10px;
	margin-top: 10px;
}

.tab_user_menu_img img {
	width: 14px;
	height: 14px;
}

.tab_user_menu_con {
	margin-top: 18px;
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: center;
	/* 黑色主色（字段一级） */
	color: #ffffff;
}
.tab_user_menu_line {
	margin-left: 10px;
	width: 200px;
	height: 1px;
	opacity: 0.2;
	background: #d8d8d8;
}

.tab_user_logout {
	margin-left: 10px;
	margin-top: 14px;
	width: 200px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	letter-spacing: 0em;
	/* justify-content: center; */
	align-items: center;
	/* 主色（一级颜色） */
	color: #767676;
}
</style>
<style scoped>
.header_tab_fun_language {
	position: absolute;
	align-content: center;
	border-radius: 6px;
	right: 140px;
	top: 42px;
	height: 30px;
	margin-right: clamp(10px, calc(100vw * 20 / 1280), 20px);
	padding-left: 10px;
	padding-right: 10px;
	background: rgba(255, 255, 255, 0.2);
	background: rgba(0, 0, 0, 0.2);
	color: black;
}

.header_top_Language_list {
	position: absolute; /* 设置绝对定位 */
	top: 60px; /* 将浮层放置在父元素下方 */
	left: 0; /* 左对齐 */
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* 阴影效果 */
	z-index: 1000; /* 确保浮层在其他元素之上 */
	padding-top: 20px;
	display: flex;
	position: absolute;
	justify-content: center;
	flex-direction: column;
	bottom: 160px;
	width: 90px;
	height: 100px;
	border-radius: 10px;
	opacity: 1;
	background: #333333;
}

.header_top_Language_list_white {
	background: rgba(0, 0, 0, 0.7);
}
.header_top_Language_list_item {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	color: #aaaaaa;
	margin-bottom: 20px;
	margin-left: 10px;
	margin-right: 10px;
}

.header_top_Language_list_item_white {
	color: white;
}
.header_top_Language_list_item:hover {
	font-weight: 600;
	color: #ffffff;
}

.header_top_Language_list_sel {
	color: #ffffff;
}
</style>
