<template>
	<div
		class="ac_detail_side_content_item flex align_items_c"
		@click="showSideDetail(side.url)"
	>
		<div class="ac_detail_side_content_item_info">
			<div class="ac_detail_ticket_title">{{ side.title }}</div>
			<div class="ac_detail_ticket_date">
				<div class="ac_detail_ticket_info">
					<div class="ac_detail_ticket_info_item">
						<img
							class="per_cooperate_icon_pc"
							src="https://zy.metaera.media/assets/images/active/add_tu_w.svg"
						/>
						<img
							class="per_cooperate_icon_mobile"
							src="https://zy.metaera.media/assets/images/active/15_1.png"
						/>
						<span>{{ side.address }}</span>
					</div>
					<div class="ac_detail_ticket_info_item">
						<img
							class="per_cooperate_icon_pc"
							src="https://zy.metaera.media/assets/images/active/time_tu_w.svg"
						/>
						<img
							class="per_cooperate_icon_mobile"
							src="https://zy.metaera.media/assets/images/active/16.png"
						/>
						<span class="ac_detail_ticket_info_item_time">
							{{ formatDate(side.start_time) }} -
							{{ formatDate(side.end_time) }} {{ side.timeZone }}
						</span>
					</div>
					<div
						class="ac_detail_ticket_info_item"
						v-if="side.url != undefined && side.url.length > 0"
					>
						<img
							class="per_cooperate_icon_pc"
							src="https://zy.metaera.media/assets/images/active/url_tu_w.svg"
						/>
						<img
							class="per_cooperate_icon_mobile"
							src="https://zy.metaera.media/assets/images/event/链接 (4)@2x.png"
						/>
						<span
							class="ac_detail_ticket_info_item_url pointer"
							style="color: #0256ff"
							@click="showUrl(side.url)"
						>
							{{ side.url }}
						</span>
					</div>
				</div>
				<div class="ac_detail_ticket_info_item">
					<img
						class="per_cooperate_icon_pc"
						src="https://zy.metaera.media/assets/images/active/host_flag.png"
					/>
					<img
						class="per_cooperate_icon_mobile"
						src="https://zy.metaera.media/assets/images/home/ac_people.png"
					/>
					<div
						class="ac_calendar_aclist_item_people"
						v-if="side.sideevent == null || side.sideevent == ''"
					>
						<div class="ac_calendar_aclist_item_organizer">
							<span>Organizer：</span>
							<span>{{ side.host }}</span>
						</div>
					</div>
					<div class="ac_calendar_aclist_item_side" v-else>
						<div class="ac_calendar_aclist_item_side_left">
							# {{ side.sideevent_title }}</div
						>
						<div class="ac_calendar_aclist_item_side_right">SideEvent</div>
					</div>
				</div>
			</div>
			<div class="to_active_btn pointer"
				>前往活动<img
					src="https://zy.metaera.media/assets/images/active/jiantou.png"
			/></div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["side"],
	data() {
		return {
			name: "",
		};
	},
	methods: {
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString("zh-CN", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				hour12: false,
			});
		},
		getHostArray(item) {
			if (item.organizer && item.organizer.length > 10) {
				return JSON.parse(item.organizer)[0];
			} else {
				return { pic: "", name: "" };
			}
		},
		showSideDetail(url) {
			// 在新标签页中打开目标路由
			window.open(url, "_blank");
		},
	},
};
</script>
<style scoped>
.ac_detail_side_content_item {
	padding: calc(100vw * 18 / 1440) calc(100vw * 24 / 1440)
		calc(100vw * 30 / 1440);
	margin-bottom: calc(100vw * 50 / 1440);
	background: rgba(255, 246, 250, 0.1);
	border-radius: 8px;
}
.ac_detail_ticket_title {
	font-size: clamp(14px, calc(100vw * 30 / 1440), 30px);
	font-weight: 900;
	line-height: clamp(20px, calc(100vw * 50 / 1440), 50px);
	/* 黑色主色（字段一级） */
	color: #fff;

	/* overflow: hidden; */
	/* display: -webkit-box; */
	/* -webkit-line-clamp: 2; */
	/* text-overflow: ellipsis; */
	/* -webkit-box-orient: vertical; */
}
.ac_detail_side_content_item_pic {
	flex-shrink: 0;
	margin-right: calc(100vw * 32 / 1440);
	width: calc(100vw * 560 / 1440);
	height: calc(100vw * 312 / 1440);
}
.ac_detail_ticket_info_item {
	margin-top: calc(100vw * 15 / 1440);
	display: flex;
	/* text-align: center; */
	align-content: center;
	align-items: center;
}
.ac_detail_ticket_info_item > img {
	margin-right: clamp(5px, calc(100vw * 10 / 1440), 10px);
}
.ac_detail_ticket_info_item span {
	color: #fff;
	font-size: clamp(10px, calc(100vw * 20 / 1440), 20px);
	line-height: 1;
	width: 95%;
	word-break: break-all;
}
.ac_calendar_aclist_item_people,
.ac_calendar_aclist_item_side {
	display: inline-flex;
	justify-content: space-between;
	border-radius: 20px;
	opacity: 1;
	box-sizing: border-box;
	background: rgba(216, 216, 216, 0.1);
	padding: 4px 10px 8px;
}
.ac_calendar_aclist_item_people span,
.ac_calendar_aclist_item_side div {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 14 / 1440), 14px);
	line-height: 1;
	/* 纯白 */
	color: #ffffff;
}
.ac_calendar_aclist_item_side_left {
	margin-right: 4px;
}

.ac_calendar_aclist_item_organizer img {
	margin-right: 4px;
}
.ac_calendar_aclist_item_people img {
	margin-left: calc(100vw * 10 / 1440);
	width: calc(100vw * 18 / 1440);
	height: calc(100vw * 18 / 1440);
	border-radius: 50%;
	object-fit: cover;
}
.ac_detail_side_content_item_pic img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

.ac_detail_side_content_item .ac_detail_ticket_title {
	font-size: clamp(16px, calc(100vw * 30 / 1440), 30px);
	line-height: clamp(16px, calc(100vw * 50 / 1440), 50px);
}
.ac_detail_side_content_item .ac_detail_ticket_info_item span {
	font-size: clamp(10px, calc(100vw * 14 / 1440), 14px);
}
.to_active_btn {
	display: inline-block;
	width: auto;
	margin-top: calc(100vw * 40 / 1440);
	background: rgba(216, 216, 216, 0.1);
	border-radius: calc(100vw * 20 / 1440);
	padding: 6px 15px;
	color: #fff;
	font-size: clamp(14px, calc(100vw * 18 / 1440), 18px);
	line-height: 1;
	font-weight: bold;
}
.to_active_btn img {
	width: clamp(14px, calc(100vw * 18 / 1440), 18px);
	height: clamp(14px, calc(100vw * 18 / 1440), 18px);
	vertical-align: middle;
}
.per_cooperate_icon_pc {
	display: block;
}
.per_cooperate_icon_mobile {
	display: none;
}
</style>

<style scoped>
/*  移动端的样式 */
@media screen and (max-width: 900px) {
	.per_cooperate_icon_pc {
		display: none;
	}
	.per_cooperate_icon_mobile {
		display: block;
		width: 14px;
		height: auto;
	}
	.ac_detail_side_content_item {
		background: linear-gradient(
			63deg,
			#dbc5f4 7%,
			#d7e5f1 47%,
			#cdf4e9 71%,
			#b3ffdf 96%
		);
		padding: 11px 15px 16px;
	}
	.ac_detail_side_content_item .ac_detail_ticket_title {
		color: #333;
		font-size: 14px;
		line-height: 1.4;
	}
	.ac_detail_side_content_item span {
		color: #333;
	}
	.ac_detail_ticket_info_item_url {
		width: auto !important;
		background: linear-gradient(
			45deg,
			#9945ff,
			#8752f3,
			#5497d5,
			#43b4ca,
			#28e0b9,
			#19fb9b
		) !important
	;
		-webkit-background-clip: text !important;
		-webkit-text-fill-color: transparent !important;
	}
	.ac_detail_side_content_item .to_active_btn {
		background: #333;
	}
	.ac_calendar_aclist_item_people,
	.ac_calendar_aclist_item_side {
		padding-left: 0;
	}
}
</style>
