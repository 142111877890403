<template>
	<div>
		<div class="app_alert">
			<div class="header_app_logo" @click="download">
				<img src="https://zy.metaera.media/assets/images/appdownload.png" />
			</div>
		</div>

		<div
			style="
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: #000000ca;
				z-index: 9999;
			"
			@click="hideAlert"
			v-if="olead == true"
		>
			<img
				style="width: 100%"
				src="https://zy.metaera.media/assets/images/aboutus/download_alert1.png"
				alt=""
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	created() {},
	data() {
		return {
			token: 0,
			active: 0,
			olead: false,
		};
	},
	methods: {
		hideAlert() {
			this.olead = false;
		},
		download() {
			// 获取当前页面的路径部分
			const path = window.location.pathname;
			console.log("Path:", path);

			// 获取当前页面的查询字符串部分
			const queryString = window.location.search;
			console.log("Query String:", queryString);

			// 可选：解析查询字符串为对象
			const params = new URLSearchParams(queryString);
			params.forEach((value, key) => {
				console.log(`参数名: ${key}, 参数值: ${value}`);
			});

			let ua = navigator.userAgent.toLowerCase();
			//ios终端
			let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			if (ua.match(/MicroMessenger/i) == "micromessenger") {
				this.olead = true;
			} else {
				if (path == "/gw_detail" || path == "/kuaixun_wz") {
					var url = "metaeraapp://metaera.hk/wz?id=" + params.get("id");
					window.location.href = url; //打开调整的地址
				} else if (path == "/ac_detail") {
					var url = "metaeraapp://metaera.hk/event?id=" + params.get("id");
					window.location.href = url; //打开调整的地址
				} else {
					var url = "metaeraapp://metaera.hk/home";
					window.location.href = url; //打开调整的地址
				}
				var that = this;
				setTimeout(function () {
					that.$router.push({
						path: "/download",
					});
				}, 1000);
			}
		},
		gotoDownLoad() {
			this.$router.push({
				path: "/download",
			});
		},
	},
};
</script>

<style scoped>
.app_alert {
	align-items: center;
	text-align: center;
	align-content: center;
	width: 100%;
	height: 60px;
	background: rgba(0, 0, 0, 0);
	opacity: 1;
	z-index: 999;
	position: fixed;
	bottom: 20px;
	z-index: 999;
}
.header_app_logo img {
	width: auto;
	height: 30px;
}
</style>
