<template>
	<div v-if="version == 2" class="version2 flex direction_column">
		<div class="active">登录</div>
		<div class="js-login">
			<div class="js-login__form">
				<p class="js-login__phone flex align_items_c">
					<img
						src="https://zy.metaera.media/assets/images/my/email_tu.png"
						class="pointer"
					/>
					<input
						type="text"
						placeholder="请输入邮箱"
						v-model="loginForm.email"
					/>
				</p>
				<div class="margin_top_20 flex login_psd_box">
					<p class="js-login__psd flex-shrink">
						<img
							src="https://zy.metaera.media/assets/images/my/yzm_tu.svg"
							class="pointer"
						/>
						<input
							placeholder="请输入验证码"
							type="text"
							v-model="loginForm.emailCode"
						/>
					</p>
					<p class="login_psd_btn flex pointer">
						<span class="login_psd_text" @click="tosendSms" v-show="show"
							>获取验证码</span
						>
						<span class="login_psd_count" v-show="!show">{{ count }} s</span>
					</p>
				</div>
				<p class="js-login__info">
					<span
						class="js-login__changePhone pointer"
						@click="$parent.$parent.showStatus = 1"
					>
						密码登录</span
					>
					<span
						class="js-login__forget pointer"
						@click="$parent.$parent.showStatus = 4"
						>忘记密码？</span
					>
				</p>
				<button
					class="loginBtn flex align_items_c justify_content_c pointer"
					@click="handleLogin"
					:disabled="loginForm.email == '' || loginForm.emailCode == ''"
				>
					登 录
				</button>
				<div class="js-login_reigister">
					新用户？<i class="pointer" @click="$parent.$parent.showStatus = 2"
						>创建账号</i
					>
				</div>
			</div>
			<div class="js-thirdParty margin_top_20">
				<div class="js-thirdParty__head flex">
					<span class="line"></span>
					<span class="text">其他第三方登录</span>
					<span class="line"></span>
				</div>

				<div class="js-thirdParty__list">
					<div
						id="g-signin2"
						class="flex align_items_c justify_content_c login_third login_google pointer"
						@click="loginByGoogle()"
					>
						<img
							src="https://zy.metaera.media/assets/images/my/google_tu.png"
						/>
						<p class="letter-space">Google快速登录</p>
					</div>
				</div>
			</div>
			<div class="return_btn flex justify_content_c margin_top_20">
				<p @click="toHome" class="pointer"
					>返回首页<img
						src="https://zy.metaera.media/assets/images/my/arrow_tu.svg"
				/></p>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import { sendSms, sendEmailSms } from "@/api/home";
import login__areaCode from "./login__areaCode.vue";
import "./login_common.css";
export default {
	name: "",
	props: ["version"],
	components: { login__areaCode },
	data() {
		return {
			loginForm: {
				username: "",
				code: "",
				type: "2",
				phone_code: "86",
				email: "", // 2.0版本邮箱字段
				emailCode: "", // 邮箱验证码
			},
			passType: "password",
			localLanguage: 0,
			showtimezone: false,
			// 验证码
			show: true,
			timer: null,
			count: "",
		};
	},
	mounted() {},
	methods: {
		toHome() {
			this.$parent.$parent.dialogVisible = false;
		},
		getphone_code(val) {
			this.loginForm.phone_code = val;
			this.showtimezone = false;
		},
		tosendSms() {
			console.log(this.$parent.$parent.showStatus);
			if (this.$parent.$parent.showStatus == 3) {
				sendEmailSms({
					email: this.loginForm.email,
				})
					.then((res) => {
						if (res.code == 200) {
							this.show = false;
							const TIME_COUNT = 60;
							if (!this.timer) {
								this.count = TIME_COUNT;
								this.show = false;
								this.timer = setInterval(() => {
									if (this.count > 0 && this.count <= TIME_COUNT) {
										this.count--;
									} else {
										this.show = true;
										clearInterval(this.timer);
										this.timer = null;
									}
								}, 1000);
							}
						} else {
							this.$message.error(res.message);
						}
					})
					.catch((error) => {
						this.$message.error(error.message);
					});
			} else {
				sendSms({
					phone: this.loginForm.username,
					phone_code: this.loginForm.phone_code,
				})
					.then((res) => {
						if (res.code == 200) {
							this.show = false;
							const TIME_COUNT = 60;
							if (!this.timer) {
								this.count = TIME_COUNT;
								this.show = false;
								this.timer = setInterval(() => {
									if (this.count > 0 && this.count <= TIME_COUNT) {
										this.count--;
									} else {
										this.show = true;
										clearInterval(this.timer);
										this.timer = null;
									}
								}, 1000);
							}
						} else {
							this.$message.error(res.message);
						}
					})
					.catch((error) => {
						this.$message.error(error.message);
					});
			}
		},
		handleLogin() {
			console.log(this.$parent.$parent.showStatus);
			var loginInfo = {};
			if (this.$parent.$parent.showStatus == 3) {
				loginInfo = {
					email: this.loginForm.email,
					code: this.loginForm.emailCode,
					type: "3",
					username: "",
				};
				// 邮箱密码登录
			}
			store
				.dispatch("user/login", loginInfo)
				.then(() => {
					this.$parent.$parent.dialogVisible = false;
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
/* 登录注册 */
.loginTab {
	margin-top: 14px;
}

.loginTab div {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 40px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.loginTab div:nth-child(2) {
	margin-left: 47px;
}

.active {
	position: relative;
	font-weight: bold;
}

.loginMain {
	margin-top: 50px;
	width: 344px;
}

.login_form {
	width: 100%;
	height: 61px;
	border-bottom: 1px solid #e1e1e1;
}

.numberarea {
	font-size: 17px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #666666;
	width: 78px;
}

.login_form input {
	width: 60%;
	height: 90%;
	border: none;
	outline: none;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000;
}

.login_form img {
	width: 14px;
}

.login_bt {
	margin-top: 15px;
}

.login_bt div {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.loginBtn {
	width: 335px;
	height: 50px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	margin-top: 23px;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	border: none;
	outline: none;
}

.loginBtn[disabled] {
	background: #8cb3ff;
	color: #ffffff;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0px;
}

.otherLogin {
	margin-top: 37px;
}

.otherLogin .el-divider__text {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #999999;
}

.wxlogin {
	width: 40px;
	margin-top: 28px;
}

@import url("../../assets/log.css");
</style>
