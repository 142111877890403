var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kuaiXunWraper padding_120 kuai_pc el-step-pc"},[_c('div',{staticClass:"flex main-box justify_content_sb",staticStyle:{"padding-bottom":"20px"}},[_c('div',{staticClass:"MainLeft MainLeft_760 MainLeft_right_30"},[(_vm.newarticleList.length > 0 || !_vm.isNetRequestBool)?_c('div',{staticClass:"steps kxsteps"},[_vm._l((_vm.newarticleList),function(items,indexs){return _c('div',{key:indexs},[_c('div',{staticClass:"flex align_items_c date-box sticky-div"},[_c('div',{staticClass:"kxDate"},[_vm._v(_vm._s(items.release_time))]),(items.diff_time != null)?_c('div',{staticClass:"kxDate_co",staticStyle:{"margin-left":"10px"}},[_c('div',{staticClass:"kxDate_diff",staticStyle:{"font-size":"12px","color":"#fff"}},[_vm._v(_vm._s(items.diff_time))]),_c('div',{staticClass:"kxDate_week",staticStyle:{"font-size":"12px","color":"#fff","opacity":"0.5"}},[_vm._v(_vm._s(items.week_time))])]):_c('div',{staticClass:"kxDate_week",staticStyle:{"font-size":"18px","color":"#fff","margin-left":"10px"}},[_vm._v(_vm._s(items.week_time))])]),_c('el-steps',{staticStyle:{"padding-top":"40px"},attrs:{"direction":"vertical","active":1}},_vm._l((items.subList),function(item,index){return _c('el-step',{key:index,staticClass:"kx_pc_item"},[_c('template',{slot:"description"},[_c('div',{staticClass:"contentBox"},[_c('router-link',{attrs:{"target":"_blank","to":{ name: '/kuaixun_wz', params: { id: item.id } }}},[_c('div',{staticClass:"stepsVal ellipsis_multiple pointer hovercolor",style:({ color: item.isRecommend ? '#1545D6' : '' }),attrs:{"prohibitTran":item.prohibit_tran}},[_vm._v(" "+_vm._s(_vm.localLanguage == 2 ? item.title ? item.title : item.title : item.title)+" ")])]),_c('p',{staticClass:"contentStep",attrs:{"prohibitTran":item.prohibit_tran},domProps:{"innerHTML":_vm._s(
											_vm.localLanguage == 2
												? item.content
													? item.content
													: item.content
												: item.content
										)}}),_c('div',{staticClass:"kxstepsBox"},[(item.release_time != null)?_c('div',{staticClass:"stepsTime"},[(item.url)?_c('a',{attrs:{"target":"_blank","href":item.url}},[_vm._v("[原文链接]")]):_vm._e(),_vm._v(" "+_vm._s(item.release_time.substr(10, 6))+" "),_vm._v(" "+_vm._s(item.source_type == 2 || item.source_type == 10 ? " 律动BlockBeats" : item.source_type == 3 ? " Odaily星球日报" : item.source_type == 4 ? " PANews" : item.source_type == 6 ? " Followin" : item.source_type == 7 ? " 吴说区块链" : item.source_type == 11 ? " 深潮TechFlow" : "")+" ")]):_vm._e(),_c('div',{staticClass:"flex align_items_c kxstepsBoxLeft"},[_c('div',{staticClass:"author"},[_vm._v(_vm._s(item.author.nickname))]),_c('div',{staticClass:"flex align_items_c btnitem justify_content_c fx pointer",on:{"click":function($event){return _vm.twitterText(item.id, item.title)}}},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/kxwz/tui_w.svg","alt":""}})]),_c('div',{staticClass:"flex align_items_c btnitem justify_content_c fx pointer",on:{"click":function($event){return _vm.showPreView(item.id)}}},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/kxwz/link_w.svg","alt":""}})])])]),_c('div',{staticClass:"kx_line"})],1)])],2)}),1)],1)}),_c('scrollToBottom',{on:{"reachBottom":_vm.getDataListPage}})],2):_vm._e()]),_c('div',{staticClass:"MainRight MainRight_400"},[_c('div',{},[_c('module-part',{attrs:{"type":"4"}}),_c('module-part',{attrs:{"type":"5"}}),_c('download')],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }