import {
	login,
	googleLogin,
	logout,
	getInfo,
	getjurisdiction,
} from "@/api/user";
import {
	getToken,
	setToken,
	removeToken,
	setUserId,
	setUserMobile,
	removeUserMobile,
	removeUserId,
} from "@/utils/auth";
import { resetRouter } from "@/router";
import { MessageBox, Message } from "element-ui";
const getDefaultState = () => {
	return {
		token: getToken(),
		name: "",
		avatar: "",
		roles: [],
		levelList: [
			"养树新人",
			"养树达人",
			"养树导师",
			"养树专家",
			"合伙人",
			"分公司",
		],
		user: {},
	};
};

const state = getDefaultState();

const mutations = {
	RESET_STATE: (state) => {
		Object.assign(state, getDefaultState());
	},
	SET_TOKEN: (state, token) => {
		state.token = token;
	},
	SET_NAME: (state, truename) => {
		state.name = truename;
	},
	SET_AVATAR: (state, avatar_img) => {
		state.avatar = avatar_img;
	},
	SET_ROLES: (state, roles) => {
		state.roles = roles;
	},
	SET_User: (state, user) => {
		state.user = user;
	},
};

const actions = {
	// user login
	login({ commit }, userInfo) {
		const { username, password, type, code, phone_code, email, emailCode } =
			userInfo;
		return new Promise((resolve, reject) => {
			login({
				mobile: username.trim(),
				password: password,
				type: type,
				code: code,
				phone_code: phone_code,
				emailCode,
				email, // 2.0版本改版为邮箱登录
			})
				.then((response) => {
					if (response.code != 200) {
						Message({
							message: response.message,
							type: "error",
							duration: 3 * 1000,
						});
						return;
					}

					commit("SET_TOKEN", response.data.token);
					// console.log(response.data.token);
					setToken(response.data.token);
					location.reload();
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	googleLogin({ commit }, userInfo) {
		const { nickname, openid, avatar_img, email } = userInfo;
		return new Promise((resolve, reject) => {
			googleLogin(userInfo)
				.then((response) => {
					if (response.code != 200) {
						Message({
							message: response.message,
							type: "error",
							duration: 3 * 1000,
						});
						return;
					}

					commit("SET_TOKEN", response.data.token);
					setToken(response.data.token);
					location.reload();
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	// get user info
	getInfo({ commit, state }) {
		return new Promise((resolve, reject) => {
			getInfo(state.token)
				.then((response) => {
					if (response.code == 1005) {
						Message({
							message: response.message || "Error",
							type: "error",
							duration: 5 * 1000,
						});
						return;
					}
					const { data } = response;
					if (!data) {
						return reject("验证失败，请重新登录");
					}

					const { truename, avatar_img } = data;

					commit("SET_NAME", data.nickname);
					commit("SET_AVATAR", avatar_img);
					commit("SET_User", data);
					setUserId(data.id);
					setUserMobile(data.mobile);
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	// user logout
	logout({ commit, state }) {
		return new Promise((resolve, reject) => {
			logout(state.token)
				.then(() => {
					removeToken(); // must remove  token  first
					//  resetRouter()
					removeUserId();
					removeUserMobile();
					commit("RESET_STATE");
					// console.log(getToken());
					location.reload();
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	// remove token
	resetToken({ commit }) {
		return new Promise((resolve) => {
			removeToken(); // must remove  token  first
			removeUserId();
			removeUserMobile();
			commit("RESET_STATE");
			resolve();
		});
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
