<template>
	<div>
		<!-- 2.0的pc端 -->
		<acDetailPc class="ac-detail-pc" v-if="!isMobileDevice"></acDetailPc>
		<!-- 原来的移动端 -->
		<acDetailMobile class="ac-detail-mobile" v-else></acDetailMobile>
	</div>
</template>

<script>
import acDetailPc from "./ac_detail_pc.vue";
import acDetailMobile from "./ac_detail_mobile.vue";

export default {
	name: "",
	components: {
		acDetailPc,
		acDetailMobile,
	},
	data() {
		return {};
	},
	mounted() {},
	created() {},
	computed: {
		isMobileDevice() {
			return this.$store.state.app.isMobileDevice;
		},
	},
};
</script>

<style scoped>
.ac-detail-pc {
	display: block;
}
.ac-detail-mobile {
	display: none;
}
@media screen and (max-width: 900px) {
	.ac-detail-pc {
		display: none;
	}
	.ac-detail-mobile {
		display: block;
	}
}
</style>
