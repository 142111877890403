<template>
	<div v-if="show" class="loading-container">
		<div class="loading-mask"></div>
		<div class="loading-content">
			<div class="loading-animate"></div>
			<!-- <div class="loading-text">{{ text }}</div> -->
		</div>
	</div>
</template>
<script>
export default {
	name: "Loading",
	props: {
		show: Boolean,
	},
	data() {
		return {
			text: "loading...",
		};
	},
};
</script>
<style scoped>
.loading-container {
	position: relative;
	z-index: 9999;
	height: 500px;
	background-color: #fff;
}

.loading-mask {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
}

.loading-content {
	position: fixed;
	left: 50%;
	top: 45%;
	z-index: 300;
	transform: translate(-50%, -45%);
	text-align: center;
}

.loading-animate {
	display: inline-block;
	width: 35px;
	height: 35px;
	margin: 25px 0 10px;
	vertical-align: middle;
	animation: cricleLoading 1s steps(12, end) infinite;
	background: transparent
		url("https://zy.metaera.media/assets/images/home/loading.svg") no-repeat;
	background-size: 100%;
}

.loading-text {
	font-size: 14px;
	font-weight: 600;
	color: #808085;
}

@-webkit-keyframes cricleLoading {
	0% {
		transform: rotate3d(0, 0, 1, 0deg);
	}

	100% {
		transform: rotate3d(0, 0, 1, 360deg);
	}
}

@keyframes cricleLoading {
	0% {
		transform: rotate3d(0, 0, 1, 0deg);
	}

	100% {
		transform: rotate3d(0, 0, 1, 360deg);
	}
}
</style>
