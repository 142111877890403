<template>
	<div>
		<div class="dexBoxm">
			<div
				class="wzitem flex align_items_c pointer"
				v-for="(item, index) in hotLists"
				:key="index"
			>
				<router-link
					target="_blank"
					:to="{ name: '/gw_detail', params: { id: item.id } }"
					style="display: flex"
				>
					<div class="wzitem2Img">
						<img
							:src="item.pic"
							:key="item.pic"
							alt=""
							style="object-fit: cover"
						/>
					</div>
					<div class="wzitemL">
						<div style="color: #000000" class="wzitemLTitle hovercolor">
							{{
								localLanguage == 2
									? item.title
										? item.title
										: item.title
									: item.title
							}}</div
						>
						<div class="wzitemLTime flex align_items_c justify_content_sb">
							<div class="wzitemLTime_l">{{ item.author.nickname }}</div>
						</div>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { homeHotList } from "@/api/home";
import { newBus } from "../../components/pc/topHeader2.vue";

export default {
	name: "",
	data() {
		return {
			hotLists: [],
			localLanguage: 1,
		};
	},
	created() {
		this.getDataList();
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		getDataList() {
			homeHotList({})
				.then((res) => {
					if (res.code == 200) {
						this.hotLists = res.data.hotLists;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.MainRight_6 {
	margin-top: 19px;
}
.wzitemLTitle {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	line-height: 30px;
}

.dexBoxm {
	margin-top: 20px;
}

.wzitemLTime_l {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #999999;
}

.wzitem {
	height: 80px;
	margin-bottom: 10px;
}

.wzitemL {
	height: 100%;
}

.wzitem img {
	width: 130px;
	height: 80px;
}

.wzitem2Img {
	/* width: 240px; */
	width: 130px;
	height: 80px;
}

/* .wzitem2Img img {
	width: 100%;
	height: 100%;
} */
</style>
