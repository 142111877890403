<template>
	<div
		:class="{
			page_bg: !this.isMobileDevice,
			padding_120: !this.isMobileDevice,
		}"
	>
		<!--  pc端  -->
		<div class="top-swiper-box" v-if="!this.isMobileDevice">
			<!--    顶部轮播      -->
			<TopSwiper
				:bnnerLists="bnnerLists"
				:bottomLists="bottomLists"
				v-if="bnnerLists.length > 0"
			>
			</TopSwiper>
		</div>
		<div
			class="margin_auto flex pc_part justify_content_sb"
			v-if="!this.isMobileDevice"
			style="padding-bottom: 20px"
		>
			<div
				id="box"
				class="tab_flow_little"
				v-if="showLeftFlow"
				:class="{ fixed10: !isfixed }"
				style="position: fixed; bottom: 150px"
			>
				<div name="blurFadeInOut" class="tab_flow_of_information">
					<ul>
						<li
							v-for="(item, index) in categoryLists"
							:key="index"
							@click="toChangeFlow(item)"
							:class="{ active: activeName == item.id }"
							class="pointer"
						>
							{{ this.localLanguage == 2 ? item.name_en : item.name }}</li
						>
					</ul>
				</div>
			</div>
			<div class="MainLeft MainLeft_760">
				<!--  主编推荐        -->
				<!-- <EditorCommendPC :zbtjList="zbtjList"></EditorCommendPC> -->
				<!--    新增区域      -->
				<etfPlate
					:etf_btc="etf_btc"
					:etf_eth="etf_eth"
					:spot="spot"
					@etfChangeFilter="etfChangeFilter"
					@getSpot="getSpot"
					v-if="etf_btc.cumNetInflow"
				></etfPlate>
				<!--         -->
				<div class="tageBox tageBoxAll" id="tageBoxRef">
					<el-tabs v-model="activeName">
						<el-tab-pane
							:label="
								localLanguage == 2
									? item.name_en
										? item.name_en
										: item.name
									: item.name
							"
							:name="item.id + ''"
							v-for="(item, index) in categoryLists"
							:key="index"
						>
							<div
								v-if="articleList.length > 0"
								style="padding-bottom: 20px"
								class="wzitem_main"
							>
								<!-- <template v-for="items in articleList"  :key="items.id"> -->
								<wzitem
									v-for="(items, index) in articleList"
									:item="items"
									:key="index"
								></wzitem>
								<!-- </template> -->
							</div>
							<!-- <noData v-else></noData> -->
							<scrollToBottom @reachBottom="getDataListPage"></scrollToBottom>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
			<div class="MainRight MainRight_400">
				<module-part type="1"> </module-part>
				<hotTopics></hotTopics>
				<module-part type="4"> </module-part>
				<module-part type="5"> </module-part>
				<download></download>
			</div>
		</div>

		<!-- 移动端   -->

		<div
			class="Main width_1200 margin_auto flex justify_content_sb mobile_part"
			v-if="this.isMobileDevice"
		>
			<MobileInd
				:categoryLists="categoryLists"
				:bnnerLists="bnnerLists"
				:zbtjList="zbtjList"
				:popColumnLists="popularColumn"
				:hotLists="hotLists"
			>
			</MobileInd>
		</div>
	</div>
</template>

<script>
import {
	homeLinsts,
	getArticleList,
	getPopularColumn,
	getSpecial,
	getLabelDetails,
	getActivityList,
	spotData,
	etfData,
} from "@/api/home";
import axios from "axios";

import clipboard from "clipboard";
import MobileInd from "./mobile/index";
import TopSwiper from "./topSwiper";
import etfPlate from "./etfPlate";
import EditorCommend from "./editorCommend";
import EditorCommendPC from "./editorCommendPC";
import NewsPart from "./newsPart";
import modulePart from "@/components/modulePart.vue";
import hotTopics from "@/components/hotTopics.vue";
import HotArticle from "./hotArticle.vue";
import wzitem from "./../../components/pc/wzitem.vue";
import download from "./../../components/pc/download.vue";
import ChineseConv from "chinese-s2t";
import { newBus } from "../../components/pc/topHeader2.vue";
export default {
	name: "",
	components: {
		HotArticle,
		modulePart,
		MobileInd,
		TopSwiper,
		etfPlate,
		EditorCommend,
		EditorCommendPC,
		NewsPart,
		ChineseConv,
		wzitem,
		download,
		hotTopics,
	},
	created() {
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
		this.getPopularColumn();
		// 获取首页推荐数据
		this.getDataList();
		this.getSpot();
		this.etfChangeFilter({ type: 1 });
		this.etfChangeFilter({ type: 2 });
	},
	data() {
		return {
			tabActive: 0,
			activeName: 0,
			bnnerLists: [],
			bottomLists: [],
			hotLists: [],
			zbtjList: [],
			articleList: [],
			flashLists: [],
			selarticleList: [],
			// 精选快讯列表
			flashList: [],
			// 热搜列表
			hotSearchList: [],
			categoryLists: [],
			page: 1,
			size: 25,
			total: "",
			loading: false,
			popularColumn: [],
			selectedspecial: [], //精选
			activityList: [],
			isfixed: true,
			showLeftFlow: false,
			localLanguage: 1,
			etf_btc: {},
			etf_eth: {},
			spot: [],
		};
	},
	computed: {
		isMobileDevice() {
			return this.$store.state.app.isMobileDevice;
		},
	},
	mounted() {
		document.addEventListener("scroll", this.handleScroll);
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	watch: {
		activeName(newVal, oldVal) {
			// console.log(newVal);
			this.page = 1;
			this.articleList = [];
			if (newVal == 0) {
				this.getArticleList(
					{
						category_id: newVal,
						type: 1,
						type1: 7,
						page: this.page,
						size: this.size,
					},
					0
				);
			} else {
				this.getArticleList(
					{ category_id: newVal, type: 1, page: this.page, size: this.size },
					0
				);
			}
		},
	},
	destroyed() {
		document.removeEventListener("scroll", this.handleScroll);
	},
	metaInfo() {
		return {
			title: ChineseConv.s2t("MetaEra"),
			meta: [
				{
					name: "keywords",
					content:
						"Web3.0,DeFi,MetaEra,blockchain,DePIN,MetaEra,区块链媒体,BTC,比特币,blockchain Media,以太坊,Web3快讯,香港ETF,币圈,Web3资讯,区块链服务,Bitcoin News,区块链是什么,Ethereum News,区块链应用,Crypto News,区块链学习,区块链钱包,区块链活动,GameFi,区块链专栏,meme,区块链游戏,区块链投资,web3钱包,区块链赚钱,公链,链游,区块链投研,USDT,区块链知识,区块链入门",
				},
				{
					name: "description",
					content: ChineseConv.s2t(
						"MetaEra是Web3行业的品牌与增长专家，为客户提供创意型解决方案。MetaEra在全球各地区利用全方位优势资源，为您的品牌管理和业务增长提供定制化服务。"
					),
				},
				{
					property: "og:description",
					content: ChineseConv.s2t(
						"MetaEra是Web3行业的品牌与增长专家，为客户提供创意型解决方案。MetaEra在全球各地区利用全方位优势资源，为您的品牌管理和业务增长提供定制化服务。"
					),
				},
				{
					property: "og:type",
					content: "article",
				},
				{
					property: "og:site_name",
					content: "MetaEra",
				},
				{
					property: "og:image",
					content: "https://zy.metaera.media/logo_share.png",
				},
				{
					property: "og:title",
					content: ChineseConv.s2t("MetaEra"),
				},
				{
					property: "twitter:description",
					content: ChineseConv.s2t(
						"MetaEra是Web3行业的品牌与增长专家，为客户提供创意型解决方案。MetaEra在全球各地区利用全方位优势资源，为您的品牌管理和业务增长提供定制化服务。"
					),
				},
				{
					property: "twitter:image",
					content: "https://zy.metaera.media/logo_share.png",
				},
				{
					property: "twitter:title",
					content: ChineseConv.s2t("香港Web 3.0 资讯平台"),
				},
			],
		};
	},
	methods: {
		getSpot() {
			spotData({})
				.then((res) => {
					if (res.code == 200) {
						this.spot = res.data;
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		etfChangeFilter(data) {
			let type = data.type;
			const url =
				"https://open.sosovalue.xyz/openapi/v2/etf/currentEtfDataMetrics"; // 接口地址

			const headers = {
				"Content-Type": "application/json", // 设置请求头
				"client-id": "fa2802c5c2654585954aca9b90e7efbd",
				"client-secret": "75Y8hfBLesAr2qqcWZwazb_F_ID8q40vTXI1d-N2PMM",
			};
			const requestData = {
				type: type == 1 ? "us-btc-spot" : "us-eth-spot",
			};
			axios
				.post(url, requestData, { headers })
				.then((response) => {
					if (type == 1) {
						this.etf_btc = response.data.data;
					} else {
						this.etf_eth = response.data.data;
					}
				})
				.catch((error) => {
					console.error("Error:", error);
				});
		},
		handleScroll() {
			let scrollTop =
				document.documentElement.scrollTop || document.body.scrollTop; // 滚动条距离顶部的距离
			let windowHeight =
				document.documentElement.clientHeight || document.body.clientHeight; // 可视区的高度
			let scrollHeight =
				document.documentElement.scrollHeight || document.body.scrollHeight; //dom元素的高度，包含溢出不可见的内容
			let that = this;
			// 滚动条到底部的条件scrollTop + windowHeight === scrollHeight
			if (scrollHeight <= scrollTop + windowHeight - 300) {
				this.isfixed = false;
			} else {
				this.isfixed = true;
			}
			if (scrollTop > windowHeight - 300) {
				this.showLeftFlow = false;
			} else {
				this.showLeftFlow = false;
			}
		},
		toChangeFlow(item) {
			this.activeName = item.id + "";
			document.documentElement.scrollTop =
				document.getElementById("tageBoxRef").scrollTop;
		},
		getLabelDetails() {
			getLabelDetails({ id: 5 })
				.then((res) => {
					if (res.code == 200) {
						this.labelDetails = res.data.data.list.splice(0, 5);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getSpecialDataList() {
			getSpecial({})
				.then((res) => {
					if (res.code == 200) {
						this.selectedspecial = res.data.selected;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getPopularColumn() {
			let res = JSON.parse(sessionStorage.getItem("getPopularColumnData"));
			if (res != null && res.code == 200) {
				this.popularColumn = res.data.list.slice(0, 5);
			}
			getPopularColumn({ type: 5 })
				.then((res) => {
					sessionStorage.setItem("getPopularColumnData", JSON.stringify(res));
					if (res.code == 200) {
						this.popularColumn = res.data.list.slice(0, 5);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getDataListPage() {
			if (this.loading) {
				return;
			}
			if (this.page >= Math.ceil(this.total / this.size)) {
				return;
			}
			this.loading = true;
			this.page = this.page + 1;
			if (this.activeName == "0") {
				this.getArticleList(
					{
						category_id: this.activeName,
						type: 1,
						type1: 7,
						page: this.page,
						size: this.size,
					},
					0
				);
			} else {
				this.getArticleList(
					{
						category_id: this.activeName,
						type: 1,
						page: this.page,
						size: this.size,
					},
					0
				);
			}
		},
		getDataList() {
			this.analyzeHomeLinstsData();
			homeLinsts({})
				.then((res) => {
					sessionStorage.setItem("homeLinstsData", JSON.stringify(res));
					this.analyzeHomeLinstsData();
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		analyzeHomeLinstsData() {
			let res = JSON.parse(sessionStorage.getItem("homeLinstsData"));
			if (res != null && res.code == 200) {
				this.bnnerLists = res.data.bnnerLists;
				this.bottomLists = res.data.recommendLists.slice(0, 2);
				this.zbtjList = res.data.recommendLists.slice(0, 5);
				this.zbtjList.forEach((item) => {
					if (item.label_info != null && item.label_info.length > 2) {
						item.label_info = item.label_info.slice(0, 2);
					}
				});
				this.categoryLists = res.data.categoryLists
					? res.data.categoryLists
					: [];
				let allData = { id: "0", name: "全部", name_en: "ALL" };
				this.categoryLists.unshift(allData);
				this.recommendLists = res.data.recommendLists;
				this.topLists = res.data.topLists;
				this.hotLists = res.data.hotLists;
				this.flashLists = res.data.flashLists;

				this.getArticleList(
					{
						category_id: this.categoryLists[0].id,
						type: 1,
						type1: 7,
						page: this.page,
						size: this.size,
					},
					0
				);
			} else {
			}
		},
		getArticleList(obj, type) {
			if (type == 1) {
				let res = JSON.parse(sessionStorage.getItem("getArticleList1Data"));
				if (res != null && res.code == 200) {
					this.selarticleList = res.data.list.splice(0, 5);
				}
			}
			getArticleList(obj)
				.then((res) => {
					if (res.code == 200) {
						if (type == 0) {
							this.loading = false;
							if (obj.page == 1) {
								this.articleList = res.data.list;
								sessionStorage.setItem(
									"getArticleList00Data",
									JSON.stringify(res)
								);
							} else {
								this.articleList = this.articleList.concat(res.data.list);
							}
							this.total = res.data.total;
						} else {
							this.selarticleList = res.data.list.splice(0, 5);
							sessionStorage.setItem(
								"getArticleList1Data",
								JSON.stringify(res)
							);
						}
					} else {
					}
				})
				.catch((error) => {});
		},
		toba() {
			location.href = "https://bcbeian.ifcert.cn/index";
		},
	},
};
</script>

<style scoped>
.top-swiper-box {
	padding-top: clamp(20px, calc(100vw * 30 / 1440), 30px);
}
.page_bg {
	justify-content: space-between;
}
/* 没用到，可以删除  */
.scroll-box {
	position: absolute;
	top: 100px;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: auto;
	padding: 0 120px;
}
.pc_part {
	margin-top: clamp(20px, calc(100vw * 40 / 1440), 40px);
}

.wzitem_main {
	background: transparent;
}

.lh div {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #14a888;
}

.lk div {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #e44d65;
}

.MainRight_4 {
	padding-left: 0;
	padding-top: 8px;
}

.MainRight_4_img {
	width: 320px;
	border-radius: 8px;
}

.MainRight_5 {
	padding-bottom: 20px;
}

.MainRight_5_item {
	padding-top: 20px;
}

.MainRight_5_item img {
	width: 30px;
}

.MainRight_5_item_title {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.MainRight_5_item_main {
	margin-left: 15px;
	border-bottom: 1px solid #f5f5f5;
	width: 270px;
}

.MainRight_5_item_bot {
	padding-bottom: 10px;
	margin-top: 5px;
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.MainRight_5_item_btn {
	width: 66px;
	height: 25px;
	background: #ffffff;
	border-radius: 13px 13px 13px 13px;
	opacity: 1;
	border: 1px solid #0056ff;
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.fixed10 {
	position: fixed;
	bottom: 370px !important;
}
.wzImgBox {
	width: 210px;
	height: 130px;
}
.wzImgBox img {
	width: 100%;
}
.js-FeaturedColumn_DataItem {
	width: 750px;
}
.mobile_part {
	display: none;
}
.tageBoxAll {
	margin-top: clamp(20px, calc(100vw * 50 / 1440), 50px);
}
@media screen and (max-width: 900px) {
	.Main {
		margin-top: 0px;
	}
	.pc_part {
		display: none;
	}
	.mobile_part {
		display: block;
	}
	.Main {
		padding-top: 0px;
	}
	.MainRight {
		display: none;
	}
	.MainLeft {
		width: 100%;
	}
	.tageBox {
		margin-top: 10px;
		padding-left: 16px;
		padding-right: 16px;
	}
	.wzImgBox {
		width: 105px;
		height: 65px;
	}
	.js-FeaturedColumn_DataItem {
		width: 100% !important;
	}
	.js-FeaturedColumn_list {
		width: 100% !important;
		padding: 0px 0px 0px !important;
	}
	.js-FeaturedColumn_item {
		max-width: 20% !important;
		margin-right: 0px !important;
	}
	.wzitemLTitle div {
		margin-right: 14px;
	}
}
</style>
<style>
.wzitemLTime_l {
	font-size: 12;
}
.steps .el-step__icon {
	width: 9px;
	height: 9px;
}
.steps .el-step__icon.is-text {
	/* background: #6974c8; */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	/* border: 4px solid; */
}
.steps .el-step.is-vertical .el-step__line {
	left: 4px;
	width: 0px;
	border: 1px dashed #ccd1de;
	background-color: transparent;
}
.el-step__head.is-wait {
	color: #000000;
	border-color: #dedee1;
}
.el-step__head.is-finish {
	color: #000000;
	border-color: #dedee1;
}
@media screen and (max-width: 900px) {
	.el-tabs__header {
		margin: 0;
	}
}
</style>
<style>
.app {
	background: #f7f8f9;
}
@media screen and (max-width: 900px) {
	.app {
		background: white;
	}
}
</style>
