<template>
	<div class="short_bottom_pc flex align_item_c">
		<div class="pointer link_btn">
			<router-link target="_blank" :to="{ path: '/home_tools' }">
				工具导航
			</router-link>
		</div>
		<div class="pointer link_btn">
			<router-link target="_blank" :to="{ path: '/copyright' }">
				版权声明
			</router-link>
		</div>
		<div class="pointer link_btn">
			<router-link target="_blank" :to="{ path: '/aboutus' }">
				关于MetaEra
			</router-link>
		</div>
	</div>
</template>
<script>
export default {
	name: "shortBottom",
	data() {
		return {};
	},
	methods: {},
};
</script>
<style scoped>
.short_bottom_pc {
	margin-top: clamp(10px, calc(100vw * 30 / 1440), 30px);
	width: 100%;
	box-sizing: border-box;
	padding: clamp(5px, calc(100vw * 15 / 1440), 15px)
		clamp(5px, calc(100vw * 25 / 1440), 25px);
	justify-content: space-between;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 4px;
}
.link_btn a {
	color: #fff;
	font-size: clamp(12px, calc(100vw * 18 / 1440), 18px);
	line-height: 1;
	font-weight: bold;
}
</style>
