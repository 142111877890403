<template>
	<div>
		<div
			class="author_app padding_120 margin_auto flex justify_content_sb dexBoxm"
			style="padding-bottom: 20px"
		>
			<div class="MainLeft MainLeft_760">
				<div class="author_app_header eventWraper_content author_app_header_pc">
					<div class="author_app_header_img">
						<img :src="userInfo.avatar_img" :key="userInfo.avatar_img" alt="" />
					</div>
					<div class="author_app_header_name"> {{ userInfo.nickname }}</div>
					<div class="author_app_header_follow">
						<div
							class="gzBtn gzbtn2 flex align_items_c justify_content_c pointer"
							v-if="userInfo.is_follow == 0"
							@click="followAuthorFun($route.params.id, 1)"
						>
							+关注
						</div>
						<div
							class="gzBtn gzbtn2 gzbtn3 flex align_items_c justify_content_c pointer"
							v-if="userInfo.is_follow == 1"
							@click="followAuthorFun($route.params.id, 0)"
						>
							已关注
						</div>
					</div>
					<div class="author_app_header_count">
						<div class="author_app_header_count_sub">
							<div class="author_app_header_count_sub_num">{{
								userInfo.follow_num
							}}</div>
							<div class="author_app_header_count_sub_name">关注</div>
						</div>
						<div class="author_app_header_count_sub">
							<div class="author_app_header_count_sub_num">{{
								userInfo.fans_num
							}}</div>
							<div class="author_app_header_count_sub_name">被关注</div>
						</div>
						<div class="author_app_header_count_sub">
							<div class="author_app_header_count_sub_num">{{
								userInfo.visits_num
							}}</div>
							<div class="author_app_header_count_sub_name">浏览数</div>
						</div>
					</div>
				</div>
				<div class="dexHeader flex align_items_c">
					<div class="headerActice"> 文章 </div>
				</div>
				<div class="jxlist">
					<div
						class="eightclock_pc align_items_c pointer"
						v-for="(item, index) in UserInformation"
						:key="index"
					>
						<wzitem class="zh_item" :item="item"></wzitem>
					</div>
				</div>
			</div>
			<div class="MainRight MainRight_400">
				<div style="margin-top: 70px"></div>
				<div class="author_app_header eventWraper_content">
					<div class="author_app_header_img">
						<img :src="userInfo.avatar_img" :key="userInfo.avatar_img" alt="" />
					</div>
					<div class="author_app_header_name author_pc_header_name">
						{{ userInfo.nickname }}</div
					>
					<div class="author_app_header_follow">
						<div
							class="gzBtn gzbtn2 flex align_items_c justify_content_c pointer"
							v-if="userInfo.is_follow == 0"
							@click="followAuthorFun($route.params.id, 1)"
						>
							+关注
						</div>
						<div
							class="gzBtn gzbtn2 gzbtn3 flex align_items_c justify_content_c pointer"
							v-if="userInfo.is_follow == 1"
							@click="followAuthorFun($route.params.id, 0)"
						>
							已关注
						</div>
					</div>
					<div class="author_app_header_count">
						<div class="author_app_header_count_sub">
							<div
								class="author_app_header_count_sub_num author_pc_header_count_sub_num"
								>{{ userInfo.follow_num }}
							</div>
							<div class="author_app_header_count_sub_name">关注</div>
						</div>
						<div class="author_app_header_count_sub">
							<div
								class="author_app_header_count_sub_num author_pc_header_count_sub_num"
								>{{ userInfo.fans_num }}</div
							>
							<div class="author_app_header_count_sub_name">被关注</div>
						</div>
						<div class="author_app_header_count_sub">
							<div
								class="author_app_header_count_sub_num author_pc_header_count_sub_num"
								>{{ userInfo.visits_num }}</div
							>
							<div class="author_app_header_count_sub_name">浏览数</div>
						</div>
					</div>
				</div>
				<download></download>
			</div>
		</div>
	</div>
</template>

<script>
import { toUserInfo, getUserInformation } from "@/api/home";
import wzitem from "./../../components/pc/wzitem.vue";
import download from "./../../components/pc/download.vue";
import { myMixin } from "@/utils/mixin.js";
export default {
	mixins: [myMixin],
	name: "",
	components: { wzitem, download },

	data() {
		return {
			userInfo: {},
			UserInformation: [],
		};
	},
	created() {
		this.gettoUserInfo(this.$route.params.id);
		this.getUserInformation(this.$route.params.id);
	},
	methods: {
		async gettoUserInfo(id) {
			// this.$loading.show()
			await toUserInfo({ id: id })
				.then((res) => {
					if (res.code == 200) {
						this.userInfo = res.data;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
			this.$loading.hide();
		},
		async getUserInformation(id) {
			this.$loading.show();
			await getUserInformation({ id: id })
				.then((res) => {
					if (res.code == 200) {
						this.UserInformation = res.data.list;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
			this.$loading.hide();
		},
	},
};
</script>

<style scoped>
.Main {
	padding-top: 10px;
}

.MainLeft {
}
.MainRight {
	margin-left: 30px;
	display: block;
}

.dexBox {
	width: 100%;
	height: 120px;
	background-image: url("https://zy.metaera.media/assets/images/dex/983.png");
	background-size: 100% 100%;
}

.dexHeader {
	margin-top: 10px;
	height: 60px;
	margin-left: 20px;
}
.jxlist {
	border-radius: 20px;
	padding-bottom: 20px;
	padding-left: 20px;
}
.headerActice {
	position: relative;
	font-size: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #fff;
}
.MainRight_1 {
	width: 100%;
	background: #ffffff;
	border-radius: 18px 18px 18px 18px;
	opacity: 1;
	height: 500px;
	overflow-y: auto;
}
.MainRight_2 {
	padding-left: 0;
	margin-top: 20px;
	padding-bottom: 40px !important;
}
.eightclock_pc {
	display: contents;
}

.timeOut {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #9b9b9b;
}
.kxsteps {
	padding-left: 16px;
	margin-top: 20px;
}
.author_app_header_pc {
	display: none;
	margin-left: 0;
}
@media screen and (max-width: 900px) {
	.author_app_header_pc {
		display: block;
		width: 90%;
		/* margin-left: 2%; */
		height: 280px;
		opacity: 1;
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.Main {
		padding-top: 10px;
	}
	.MainLeft {
		width: 100%;
		background-color: #fff;
		border-radius: 20px;
	}
	.MainRight {
		display: none;
	}
	.dexBox {
		width: 100%;
		height: 100px;
		background-image: url("https://zy.metaera.media/assets/images/dex/983.png");
		background-size: 100% 100%;
	}
	.pagination-container {
		margin-left: -20px;
	}
}
</style>
<style>
.dexBoxm .wzitem {
	padding-bottom: 20px;
	border-bottom: 1px solid #efefef;
}
.dexBoxm .wzitem:nth-last-child(1) {
	border-bottom: none;
}
.kxsteps .el-step__main {
	/* height: 99px; */
}
.kxsteps .el-step__description {
	padding-right: 0% !important;
}
.kxsteps .btnitem:nth-last-child(1) {
	margin-left: 10px;
}
.author_app_header {
	width: 250px;
	height: 280px;
	opacity: 1;
	border-radius: 20px !important;
	margin-bottom: 20px;
}
.author_app_header_img {
	/* margin-left: 100px;
	 */

	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 26px;
}
.author_app_header_img img {
	width: 80px;
	height: 80px;
	border-radius: 12px;
	opacity: 1;
}
.author_app_header_name {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;

	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: 600;
	line-height: 18px;
	letter-spacing: 0em;

	color: #000000;
}

.author_pc_header_name {
	color: white;
}

.author_app_header_count {
	display: flex;
	margin-top: 20px;
}

.author_app_header_count_sub {
	width: 33%;
}
.author_app_header_count_sub_num {
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.author_pc_header_count_sub_num {
	color: white;
}
.author_app_header_count_sub_name {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	letter-spacing: 0em;

	/* 字段四级 */
	color: #d8d8d8;
}

.author_app_header_follow {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}
.gzBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	width: 80px;
	height: 30px;
	border-radius: 10px;
	color: white;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
}
</style>

<style>
.zh_item .reItem_Left_full {
	width: 75%;
}
.zh_item .wzitem_sub_Left_title,
.zh_item .wzitem_sub_Left_author {
	color: white;
}

@media screen and (max-width: 900px) {
	.zh_item .wzitem_sub_img {
		width: 120px !important;
		height: auto !important;
	}
	.zh_item .reItem_Left_full {
		width: 60%;
	}
	.zh_item .wzitem_sub_Left_title,
	.zh_item .wzitem_sub_Left_author {
		color: black;
	}
}
</style>
