<template>
	<div class="eventWraper">
		<div
			class="rsTitle rsTitleBg flex align_items_c justify_content_sb"
			v-if="
				EventDetails.length > 0 ||
				recommendLists.length > 0 ||
				labelDetails.length > 0 ||
				kxunTuiJianDetails.length > 0
			"
		>
			<div class="rsTitleLeft flex align_items_c">
				<div class="leftText pointer hovercolor" style="font-weight: bold">{{
					title
				}}</div>
				<div
					class="flex align_items_c pointer justify_content_sb"
					@click="toMore"
				>
					<div class="rsTitleLeft_point"></div>
					<div class="rsTitleLeft_point"></div>
					<div class="rsTitleLeft_point"></div>
				</div>
			</div>
		</div>
		<div
			class="MainRight_2 eventWraper_content"
			v-if="
				EventDetails.length > 0 ||
				recommendLists.length > 0 ||
				labelDetails.length > 0 ||
				kxunTuiJianDetails.length > 0
			"
		>
			<div v-if="type == 'event'">
				<div
					class="MainRight_2_item flex align_items_s moduleBox"
					v-for="(item, eventIndex) in EventDetails"
					:key="eventIndex"
					style="padding-bottom: 20px"
				>
					<div class="ric"></div>
					<div class="MainRight_2_item_r">
						<router-link
							target="_blank"
							:to="{ name: '/kuaixun_wz', params: { id: item.id } }"
						>
							<div class="MainRight_2_item_r_title pointer hoverTitle"
								>{{
									localLanguage == 2
										? item.title
											? item.title
											: item.title
										: item.title
								}}
							</div>
						</router-link>
						<div class="flex align_items_c MainRight_2_item_r_time">
							<img
								src="https://zy.metaera.media/assets/images/home/2.png"
								alt=""
							/>
							<div>{{ item.release_time }}</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="type == 'recommend'" style="padding-bottom: 20px">
				<div
					class="MainRight_2_item flex align_items_s moduleBox"
					v-for="(item, recommendIndex) in recommendLists"
					:key="recommendIndex"
				>
					<div
						class="reItem_Left"
						:class="{
							reItem_Left_full: !(item.pic != null && item.pic.length > 0),
						}"
					>
						<div class="reItem_Left_title pointer hovercolor">{{
							localLanguage == 2
								? item.title
									? item.title
									: item.title
								: item.title
						}}</div>
						<div class="flex align_items_c">
							<div class="reItem_Left_line"></div>
							<div class="reItem_Left_time">{{ item.release_time }}</div>
						</div>
					</div>
					<div
						class="reItem_Right"
						style="display:(item.pic != null && item.pic.length > 0):contents:none ;"
					>
						<img class="rsItemImg" :src="item.pic" :key="item.pic" alt="" />
					</div>
				</div>
			</div>
			<div v-if="type == 'getLabelDetails'">
				<div
					class="MainRight_2_item flex align_items_s"
					v-for="(item, labelIndex) in labelDetails"
					:key="labelIndex"
				>
					<div class="ric"></div>
					<div class="MainRight_2_item_r">
						<router-link
							target="_blank"
							:to="{ name: '/gw_detail', params: { id: item.id } }"
						>
							<div class="MainRight_2_item_r_title pointer hovercolor"
								>{{
									localLanguage == 2
										? item.title
											? item.title
											: item.title
										: item.title
								}}
							</div>
						</router-link>
						<div class="flex align_items_c MainRight_2_item_r_time">
							<img
								src="https://zy.metaera.media/assets/images/home/2.png"
								alt=""
							/>
							<div>{{ item.release_time }}</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="type == 'kxtuijian'">
				<div
					class="MainRight_2_item flex align_items_s"
					v-for="(item, kxunIndex) in kxunTuiJianDetails"
					:key="kxunIndex"
				>
					<div class="ric"></div>
					<div class="MainRight_2_item_r">
						<router-link
							target="_blank"
							:to="{ name: '/gw_detail', params: { id: item.id } }"
						>
							<div class="MainRight_2_item_r_title pointer hovercolor">{{
								localLanguage == 2
									? item.title
										? item.title
										: item.title
									: item.title
							}}</div>
						</router-link>
						<div class="flex align_items_c MainRight_2_item_r_time">
							<img
								src="https://zy.metaera.media/assets/images/home/2.png"
								alt=""
							/>
							<div>{{ item.release_time }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	getEventDetails,
	getRecommendLists,
	getLabelDetails,
	relatedRecommen,
} from "@/api/home";
import { newBus } from "@/components/pc/topHeader2.vue";
export default {
	props: ["type", "title", "id"],
	name: "",
	data() {
		return {
			allData: [],
			EventDetails: [],
			recommendLists: [],
			labelDetails: [],
			kxunTuiJianDetails: [],
			localLanguage: 1,
		};
	},
	created() {
		if (this.type == "event") {
			this.getEventDetails();
		} else if (this.type == "recommend") {
			this.getRecommendLists();
		} else if (this.type == "getLabelDetails") {
			this.getLabelDetails();
		} else if (this.type == "kxtuijian") {
			this.getKxTuijianDetails();
		}
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		toMore() {
			if (this.type == "event") {
				this.$router.push({ path: "/gw_shijian" });
			} else if (this.type == "getLabelDetails") {
				this.$router.push({
					name: "/gw_dex",
					params: { id: 5 },
				});
			}
		},
		getKxTuijianDetails() {
			relatedRecommen({ id: this.id })
				.then((res) => {
					if (res.code == 200) {
						this.kxunTuiJianDetails = res.data;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getEventDetails() {
			getEventDetails({})
				.then((res) => {
					if (res.code == 200) {
						this.EventDetails = res.data.list.splice(0, 5);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getRecommendLists() {
			let res = JSON.parse(sessionStorage.getItem("getRecommendListsData"));
			if (res != null && res.code == 200) {
				this.allData = res.data.list;
				var tmpData = [];
				tmpData = tmpData.concat(this.allData);
				this.recommendLists = tmpData.splice(0, 5);
			}
			getRecommendLists({ page: 1, size: 10 })
				.then((res) => {
					sessionStorage.setItem("getRecommendListsData", JSON.stringify(res));
					if (res.code == 200) {
						this.allData = res.data.list;
						var tmpData = [];
						tmpData = tmpData.concat(this.allData);
						this.recommendLists = tmpData.splice(0, 5);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getLabelDetails() {
			getLabelDetails({ id: 4 })
				.then((res) => {
					if (res.code == 200) {
						this.labelDetails = res.data.data.list.splice(0, 5);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		toNewPage(toPage) {
			// 要跳转到的页码
			if (this.type == "recommend") {
				var tmpData = [];
				tmpData = tmpData.concat(this.allData);
				if (toPage == 2) {
					this.recommendLists = tmpData.splice(5, 5);
				} else {
					this.recommendLists = tmpData.splice(0, 5);
				}
			} else {
			}
		},
	},
};
</script>

<style scoped>
.MainRight_2 {
	padding: 0 20px 0 22px;
}
.moduleBox:hover .hoverTitle {
	color: #0056ff !important;
}
.moduleBox:hover .ric {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}
.reItem_Left {
	width: 148px;
}
.reItem_Left_full {
	width: 100%;
}
.reItem_Left_title {
	width: 100%;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 600 !important;
	line-height: 1.5;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.reItem_Left_time {
	width: 175px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 13px;
	font-weight: normal;
	line-height: 13px;
	letter-spacing: 0em;
	/* margin-top: 13px; */
	color: #b2b2b2;
}
.reItem_Right {
	margin-left: 15px;
}
.rsItemImg {
	width: 97px;
	height: 60px;
	border-radius: 8px;
	object-fit: cover;
}
</style>
