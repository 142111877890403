<template>
	<div class="eventWraper" v-if="allData.length > 0">
		<div class="rsTitle rsTitleBg flex align_items_c justify_content_sb">
			<div class="rsTitleLeft flex align_items_c">
				<div style="font-weight: bold">{{
					title == null || title.length == 0 ? "24小时热文" : title
				}}</div>
			</div>
		</div>
		<div class="rsList eventWraper_content">
			<div
				class="rsItem flex pointer"
				v-for="(item, index) in allData"
				:key="index"
			>
				<router-link
					target="_blank"
					:to="{ name: '/gw_detail', params: { id: item.id } }"
				>
					<div
						class="reItem_Left"
						:class="{
							reItem_Left_full: !(item.pic != null && item.pic.length > 0),
						}"
					>
						<div class="reItem_Left_title hovercolor">{{
							localLanguage == 2
								? item.title
									? item.title
									: item.title
								: item.title
						}}</div>

						<div class="flex align_items_c time_line_top">
							<div class="reItem_Left_line"></div>
							<div class="reItem_Left_time">{{ item.release_time }}</div>
						</div>
					</div>
					<div
						class="reItem_Right"
						v-if="item.pic != null && item.pic.length > 0"
					>
						<img class="rsItemImg" :src="item.pic" :key="item.pic" alt="" />
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { newBus } from "../../components/pc/topHeader2.vue";
export default {
	props: ["hotLists", "title"],
	data() {
		return {
			allData: [],
			localLanguage: 1,
		};
	},
	watch: {
		hotLists: {
			handler(val) {
				this.toNewPage(1);
			},
			deep: true,
		},
		title: {
			handler(val) {
				this.title = val;
			},
			deep: true,
		},
	},
	mounted() {
		this.toNewPage(1);

		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	created() {
		this.toNewPage(1);
	},
	methods: {
		toNewPage(toPage) {
			var tmpData = [];
			tmpData = tmpData.concat(this.hotLists);
			this.allData = tmpData;
		},
	},
};
</script>

<style scoped>
.rsItem2Bor {
	border-bottom: 1px solid #f5f5f5;
	padding-right: 20px;
	width: 100%;
	height: 80px;
}

.rsItem2Bor:nth-last-child(1) {
	border-bottom: none;
}
.rsItem {
	height: 70px;
}
.reItem_Left {
	width: 148px;
}
.reItem_Left_full {
	width: 100%;
}
.reItem_Left_title {
	/* width: 100%; */
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 600 !important;
	line-height: 1.5;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.reItem_Left_time {
	width: 175px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 13px;
	font-weight: normal;
	line-height: 13px;
	letter-spacing: 0em;
	/* margin-top: 13px; */
	color: #b2b2b2;
}
.reItem_Right {
	margin-left: 15px;
}
.rsItemImg {
	width: 97px;
	height: 60px;
	border-radius: 8px;
	object-fit: cover;
}
</style>
