<template>
	<div class="hk_eventWraper">
		<div class="hk_people_top">
			<div class="rsTitleLeft flex align_items_c justify_content_sb">
				<div class="hk_title">
					<img
						src="https://zy.metaera.media/assets/images/hk/hk_people_1.png"
					/>
				</div>
			</div>
			<div class="hk_flash_bottom">
				<div class="hk_flash_title_bl1"> </div>
				<div class="hk_flash_title_bl2"> </div>
			</div>
		</div>
		<div class="hk_people_list pointer">
			<el-carousel :interval="3000" arrow="never">
				<el-carousel-item v-for="(item, index) in selarticleList" :key="index">
					<div class="hk_people_wrapper" @click="showBannerDetail(item.url)">
						<img :src="item.pic" class="hk_people_image" />
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>
</template>

<script>
import clipboard from "clipboard";
import { newBus } from "../../components/pc/topHeader2.vue";
export default {
	data() {
		return {
			currentShow: 0,
			localLanguage: 1,
			selarticleList: [
				{
					pic: "https://zy.metaera.media/241022/1729596076241022920.jpg",
					url: "https://www.metaera.hk/gw_detail?id=191875",
				},
				{
					pic: "https://zy.metaera.media/241104/1730720938241104466.jpg",
					url: "https://www.metaera.hk/gw_detail?id=193493",
				},
				{
					pic: "https://zy.metaera.media/241031/1730373325241031813.jpg",
					url: "https://www.metaera.hk/gw_detail?id=193058",
				},
				{
					pic: "https://zy.metaera.media/241029/1730201759241029682.jpg",
					url: "https://www.metaera.hk/gw_detail?id=192756",
				},
				{
					pic: "https://zy.metaera.media/241028/1730116792241028423.jpg",
					url: "https://www.metaera.hk/gw_detail?id=192600",
				},
			],
		};
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		showBannerDetail(url) {
			window.open(url, "");
		},
		changePart(ind) {
			this.currentShow = ind;
		},
	},
};
</script>

<!-- hk_people_list -->
<style scoped>
.hk_people_list {
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 30px;
	padding-bottom: 10px;
	margin-top: 50px;
	opacity: 1;
	box-sizing: border-box;
	border-radius: 10px;
	opacity: 1;
	background: #121212;
	box-sizing: border-box;
	border: 4px solid #0e0e0e;
	box-shadow: inset 0px 0px 23px 0px rgba(0, 0, 0, 0.6);
}

.hk_people_wrapper {
	position: relative;
	overflow: hidden;
	border-radius: 20px;
	width: 100%;
	aspect-ratio: 341 / 134;
}

.hk_people_image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover; /* 确保图片在容器中完整显示 */
	display: block;
	border-radius: 20px;
}
</style>

<style>
.hk_people_list .el-carousel__button {
	width: 6px;
	height: 6px;
	border-radius: 10px;
	opacity: 1;
	opacity: 0.5;
	background: #ffffff;
}

.hk_people_list .is-active .el-carousel__button {
	width: 20px;
	opacity: 1;
	background: linear-gradient(
		52deg,
		#9945ff 35%,
		#8752f3 47%,
		#5497d5 59%,
		#43b4ca 65%,
		#28e0b9 71%,
		#19fb9b 86%
	);
}
.hk_people_list .el-carousel__container {
	height: clamp(180px, calc(100vw * 200 / 1440), 260px) !important;
}

@media screen and (max-width: 900px) {
	.hk_people_list .el-carousel__container {
		height: clamp(140px, calc(100vw * 180 / 414), 320px) !important;
	}
}
</style>
