var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
		page_bg: !this.isMobileDevice,
		padding_120: !this.isMobileDevice,
	}},[(!this.isMobileDevice)?_c('div',{staticClass:"top-swiper-box"},[(_vm.bnnerLists.length > 0)?_c('TopSwiper',{attrs:{"bnnerLists":_vm.bnnerLists,"bottomLists":_vm.bottomLists}}):_vm._e()],1):_vm._e(),(!this.isMobileDevice)?_c('div',{staticClass:"margin_auto flex pc_part justify_content_sb",staticStyle:{"padding-bottom":"20px"}},[(_vm.showLeftFlow)?_c('div',{staticClass:"tab_flow_little",class:{ fixed10: !_vm.isfixed },staticStyle:{"position":"fixed","bottom":"150px"},attrs:{"id":"box"}},[_c('div',{staticClass:"tab_flow_of_information",attrs:{"name":"blurFadeInOut"}},[_c('ul',_vm._l((_vm.categoryLists),function(item,index){return _c('li',{key:index,staticClass:"pointer",class:{ active: _vm.activeName == item.id },on:{"click":function($event){return _vm.toChangeFlow(item)}}},[_vm._v(" "+_vm._s(this.localLanguage == 2 ? item.name_en : item.name))])}),0)])]):_vm._e(),_c('div',{staticClass:"MainLeft MainLeft_760"},[(_vm.etf_btc.cumNetInflow)?_c('etfPlate',{attrs:{"etf_btc":_vm.etf_btc,"etf_eth":_vm.etf_eth,"spot":_vm.spot},on:{"etfChangeFilter":_vm.etfChangeFilter,"getSpot":_vm.getSpot}}):_vm._e(),_c('div',{staticClass:"tageBox tageBoxAll",attrs:{"id":"tageBoxRef"}},[_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.categoryLists),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"label":_vm.localLanguage == 2
								? item.name_en
									? item.name_en
									: item.name
								: item.name,"name":item.id + ''}},[(_vm.articleList.length > 0)?_c('div',{staticClass:"wzitem_main",staticStyle:{"padding-bottom":"20px"}},_vm._l((_vm.articleList),function(items,index){return _c('wzitem',{key:index,attrs:{"item":items}})}),1):_vm._e(),_c('scrollToBottom',{on:{"reachBottom":_vm.getDataListPage}})],1)}),1)],1)],1),_c('div',{staticClass:"MainRight MainRight_400"},[_c('module-part',{attrs:{"type":"1"}}),_c('hotTopics'),_c('module-part',{attrs:{"type":"4"}}),_c('module-part',{attrs:{"type":"5"}}),_c('download')],1)]):_vm._e(),(this.isMobileDevice)?_c('div',{staticClass:"Main width_1200 margin_auto flex justify_content_sb mobile_part"},[_c('MobileInd',{attrs:{"categoryLists":_vm.categoryLists,"bnnerLists":_vm.bnnerLists,"zbtjList":_vm.zbtjList,"popColumnLists":_vm.popularColumn,"hotLists":_vm.hotLists}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }